<button
  class="text-center"
  [ngClass]="{ 'cursor-wait	': isLoading }"
  (click)="onClick.emit($event)"
  [disabled]="isLoading">
  <div
    class="rounded-full bg-white shadow-xl h-14 w-14 border-grey-100 border flex items-center justify-center mx-auto">
    <img [src]="iconSrc" [alt]="iconAlt" *ngIf="!isLoading; else loading" @fadeIn />
    <ng-template #loading>
      <spinner [diameter]="28" data-test-id="loading-indicator"></spinner>
    </ng-template>
  </div>
  <ng-content></ng-content>
</button>
