import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { BeyToastService } from '../../modules/shared/services/bey-toast.service';
import { suspendUser } from '../user/user.actions';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NINMultipleIncorrectAttemptsDialogContentComponent } from '../../modules/auth/components/nin-multiple-incorrect-attempts-dialog-content/nin-multiple-incorrect-attempts-dialog-content.component';
import { toggleHeadlessStepperStep } from '../misc/misc.actions';
import { LoginMultipleIncorrectAttemptsDialogContentComponent } from 'src/app/modules/auth/components/login-multiple-incorrect-attempts-dialog-content/login-multiple-incorrect-attempts-dialog-content.component';
import { event } from '@fullstory/browser';
import { initRecaptcha } from '../../../assets/utility';
import { IntercomService } from '../../modules/core/services/intercom.service';
import {
  empty,
  lockAccount,
  loginFailure,
  loginStart,
  loginSuccess,
  resetUserPinFailure,
  resetUserPinStart,
  resetUserPinSuccess,
  sendVerificationCodeFailure,
  sendVerificationCodeStart,
  sendVerificationCodeSuccess,
  signupFailure,
  signupStart,
  signupSuccess,
  unlockAccount,
  verifyVerificationCodeFailure,
  verifyVerificationCodeStart,
  verifyVerificationCodeSuccess,
} from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private authService: AuthService,
    private actions$: Actions,
    private router: Router,
    private toast: BeyToastService,
    private dialog: MatDialog,
    private toastService: BeyToastService,
    private intercomUtil: IntercomService
  ) {}

  accountUnlocked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(unlockAccount),
        tap(() => this.dialog.closeAll())
      ),
    { dispatch: false }
  );

  signupEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signupStart),
      switchMap(({ payload }) =>
        from(this.intercomUtil.getVisitorId()).pipe(
          switchMap((fullstory_id: string) =>
            this.authService.registerUser({ ...payload, fullstory_id }).pipe(
              map((res) => {
                this.router.navigate(['/phone-verification'], {
                  state: { phone: payload.phone, pin: payload.pin },
                });

                return signupSuccess({
                  payload: {
                    ...res,
                  },
                });
              }),
              catchError((error) => {
                console.error(error);

                if (error?.error['phone']) {
                  this.toast.open(
                    'This phone number already exists',
                    'error',
                    'Login',
                    () => this.router.navigate(['/login']),
                    false,
                    true,
                    true
                  );
                } else if (error?.error['email']) {
                  this.toast.open(error?.error['email'][0], 'error');
                } else if (error?.error['recaptcha']) {
                  this.toast.open(
                    'Error validating Recaptcha please reload the page.',
                    'error',
                    'Reload Page',
                    () => window.location.reload(),
                    null,
                    true
                  );
                } else {
                  this.toast.open('Something went wrong, Please try again.', 'error');
                }
                return observableOf(signupFailure(error));
              })
            )
          ),
          catchError((e) => {
            console.error(e);
            return observableOf(signupFailure(e));
          })
        )
      )
    )
  );

  loginEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginStart),
      switchMap(({ payload }) => {
        return from(initRecaptcha('user_login')).pipe(
          switchMap((recaptcha) => {
            return this.authService.loginUser({ ...payload, recaptcha }).pipe(
              map((data: any) => {
                if (!payload.known) {
                  // push URL search params from login to phone verification
                  // check if we have a redirect URL passed as a URL search param
                  const queryParams = this.getParamsFromCurrentURL();
                  const redirect = queryParams.get('redirect');
                  const route = !payload.known && !payload.redirectPath ? '/phone-verification' : '';
                  const config = {
                    state: { phone: payload.phone, loginResponse: data },
                  };

                  // push over the redirect URL param from login to phone verification and eventually use it on successful login
                  if (redirect) {
                    config['queryParams'] = { redirect };
                  }

                  this.router.navigate([route], config);
                }

                if (payload.known) {
                  this.toast.open('Logged in successfully', 'blue');
                }

                if (payload.known || payload.redirectPath) {
                  return loginSuccess({
                    payload: {
                      tokens: { access: data['access'], refresh: data['refresh'] },
                      currentUser: data['user'],
                      phone: payload.phone,
                      redirectPath: payload?.redirectPath,
                    },
                  });
                }

                return empty();
              }),
              catchError((error) => {
                console.error(error);

                if (error?.error?.is_suspended) {
                  return observableOf(suspendUser());
                } else if (error?.error?.is_locked) {
                  this.dialog.open(LoginMultipleIncorrectAttemptsDialogContentComponent, { disableClose: true });
                  return observableOf(lockAccount());
                } else if (error?.error['recaptcha']) {
                  this.toast.open(
                    'Error validating Recaptcha please reload the page.',
                    'error',
                    'Reload Page',
                    () => window.location.reload(),
                    null,
                    true
                  );
                  return observableOf(loginFailure(error));
                } else {
                  this.toast.open(
                    error?.error?.detail ? error.error.detail : 'Something went wrong, Please try again.',
                    'error'
                  );
                  return observableOf(loginFailure(error));
                }
              })
            );
          }),
          catchError((e) => {
            console.error(e);
            this.toastService.open(e.message || e, 'error');
            return observableOf(loginFailure(e));
          })
        );
      })
    )
  );

  sendVerificationCodeEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendVerificationCodeStart),
      switchMap(({ payload }) =>
        this.authService.sendVerificationCode(payload).pipe(
          map(() => {
            // conditionally redirect to PIN reset page
            if (payload.resetPin) {
              this.router.navigate(['/security-verification'], {
                state: { phone: payload.phone, pin: payload.pin },
              });
            }

            return sendVerificationCodeSuccess();
          }),
          catchError((error) => {
            console.error(error);
            this.toast.open('The phone number doesn’t exist. Please try again', 'error');
            return observableOf(sendVerificationCodeFailure(error));
          })
        )
      )
    )
  );

  verifyVerificationCodeEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyVerificationCodeStart),
      switchMap(({ payload }) =>
        this.authService.verifyVerificationCode(payload).pipe(
          mergeMap((response) => {
            const actions = [verifyVerificationCodeSuccess()];
            let messageBasedOnRoute = {
              '/': 'Logged in successfully',
              '/registration/kyc': 'Your account has been successfully created and you were automatically logged in',
            };

            if (payload.route === '/registration/kyc') {
              actions.push(
                loginStart({
                  payload: {
                    phone: payload.extras.phone,
                    pin: payload.extras.pin,
                    redirectPath: payload.route,
                    recaptcha: payload.recaptcha,
                  },
                })
              );
            } else {
              this.router.navigate([payload.route], {
                state: { ...payload.extras, otp_token: response.otp_token },
              });
            }

            if (messageBasedOnRoute[payload.route]) {
              this.toast.open(messageBasedOnRoute[payload.route]);
            }

            // If the payload has user then it means we're logged in already (Case of phone verification)
            if (payload.user) {
              actions.push(
                loginSuccess({
                  payload: {
                    phone: payload.extras.phone,
                    currentUser: payload.user['user'],
                    tokens: { access: payload.user['access'], refresh: payload.user['refresh'] },
                  },
                })
              );
            }

            return actions;
          }),
          catchError((error) => {
            console.error(error);
            this.toast.open('The code you entered is incorrect. Please try again', 'error');
            payload.resetFunction();
            return observableOf(verifyVerificationCodeFailure(error));
          })
        )
      )
    )
  );

  restUserPinEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetUserPinStart),
      switchMap(({ payload }) =>
        this.authService.resetUserPin(payload).pipe(
          mergeMap(() => {
            const actions = [toggleHeadlessStepperStep({ currentStep: 0 }), resetUserPinSuccess()];

            this.toast.open('PIN successfully updated', 'blue');
            this.router.navigate(['/login']);

            return actions;
          }),
          catchError((error) => {
            console.error(error);

            const action = observableOf(resetUserPinFailure(error));
            // Since the error object doesn't catch info about HTTP 429 we just assume if you're online, and you get a network then it's the WAF
            if (error.status === 0 && navigator.onLine) {
              this.dialog.open(NINMultipleIncorrectAttemptsDialogContentComponent, {
                disableClose: true,
              });

              return action;
            }

            const msg =
              error.status === 404
                ? "We couldn't verify your request. \n Please check your inputs try again!"
                : error.error?.detail;

            this.toast.open(msg, 'error');

            return action;
          })
        )
      )
    )
  );

  loginSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccess),
        tap(({ payload }) => {
          // check if we have a redirect URL passed as a URL search param
          const queryParams = this.getParamsFromCurrentURL();
          const redirect = queryParams.get('redirect');

          let route = payload.redirectPath || '/';

          // Redirect scenario such as redirect to billing page from email or SMS
          if (redirect) {
            route = redirect;
          }

          this.router.navigate([route]);
        })
      ),
    { dispatch: false }
  );

  signUpSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signupSuccess),
        tap(({ payload }) => {
          const { email, phone } = payload;
          // send an FS sign up KYC submit event here
          event('USER_SIGNUP', {
            email,
            phone,
          });
        })
      ),
    { dispatch: false }
  );

  private getParamsFromCurrentURL() {
    const currentRoute = this.router.routerState.snapshot.url;
    return new URLSearchParams(currentRoute.split('?')[1]);
  }
}
