import { createAction, props } from '@ngrx/store';
import PaymentTypes from './payments.types';
import * as paymentsInterfaces from './payments.interfaces';

export const updatePaymentInfo = createAction(
  PaymentTypes.UPDATE_PAYMENT_INFO,
  props<{ payload: paymentsInterfaces.PaymentInfo }>()
);
export const updateBankPaymentInfo = createAction(
  PaymentTypes.UPDATE_BANK_PAYMENTS_INFO,
  props<{ payload: paymentsInterfaces.BankPaymentInfo }>()
);
export const resetBankPaymentInfo = createAction(PaymentTypes.RESET_BANK_PAYMENTS_INFO);
export const setLiquidateUser = createAction(
  PaymentTypes.SET_LIQUIDATE_USER,
  props<{ payload: paymentsInterfaces.LiquidateUser }>()
);
export const clearPaymentInfo = createAction(PaymentTypes.CLEAR_PAYMENT_INFO);
export const getRecentPayments = createAction(PaymentTypes.GET_RECENT_PAYMENTS);
export const setRecentPayments = createAction(
  PaymentTypes.SET_RECENT_PAYMENTS,
  props<{ payload: paymentsInterfaces.RecentPaymentResponse }>()
);
export const resetNewPayment = createAction(
  PaymentTypes.RESET_NEW_PAYMENT,
  props<{ payload: { paymentId: number } }>()
);

//------------CREATE PAYMENT------------
export const createPaymentStart = createAction(
  PaymentTypes.CREATE_PAYMENT_START,
  props<{ payload: paymentsInterfaces.CreatePaymentRequestBody }>()
);
export const createPaymentSuccess = createAction(PaymentTypes.CREATE_PAYMENT_SUCCESS, props<any>());
export const createPaymentFailure = createAction(
  PaymentTypes.CREATE_PAYMENT_FAILURE,
  props<paymentsInterfaces.ErrorType>()
);
//------------GET MORE USER PAYMENTS------------
export const getMoreUserPaymentsStart = createAction(
  PaymentTypes.GET_MORE_USER_PAYMENTS_START,
  props<{ payload: { params: string } }>()
);
export const getMoreUserPaymentsSuccess = createAction(
  PaymentTypes.GET_MORE_USER_PAYMENTS_SUCCESS,
  props<{ payload: { payments: paymentsInterfaces.RecentPaymentItem[]; next: string } }>()
);
export const getMoreUserPaymentsFailure = createAction(
  PaymentTypes.GET_MORE_USER_PAYMENTS_FAILURE,
  props<paymentsInterfaces.ErrorType>()
);
//------------GET PAYMENTS FEES------------
export const getPaymentsFeesStart = createAction(PaymentTypes.GET_PAYMENTS_FEES_START);
export const getPaymentsFeesSuccess = createAction(
  PaymentTypes.GET_PAYMENTS_FEES_SUCCESS,
  props<{ payload: paymentsInterfaces.PaymentsFees }>()
);
export const getPaymentsFeesFailure = createAction(
  PaymentTypes.GET_PAYMENTS_FEES_FAILURE,
  props<paymentsInterfaces.ErrorType>()
);
//------------GET BANK PAYMENTS FEES------------
export const getBankPaymentsFeesStart = createAction(PaymentTypes.GET_BANK_PAYMENTS_FEES_START);
export const getBankPaymentsFeesSuccess = createAction(
  PaymentTypes.GET_BANK_PAYMENTS_FEES_SUCCESS,
  props<{ payload: paymentsInterfaces.BankPaymentsFees }>()
);
export const getBankPaymentsFeesFailure = createAction(
  PaymentTypes.GET_BANK_PAYMENTS_FEES_FAILURE,
  props<paymentsInterfaces.ErrorType>()
);
//------------MISC------------
export const getBankListStart = createAction(PaymentTypes.GET_BANK_LIST_START);
export const getBankListSuccess = createAction(PaymentTypes.GET_BANK_LIST_SUCCESS, props<{ payload: any }>());
export const getBankListFail = createAction(PaymentTypes.GET_BANK_LIST_FAIL, props<paymentsInterfaces.ErrorType>());
