import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { BeyonicLinkService } from '../../../ngrx/beyonic-link/beyonic-link.service';
import { BeyToastService } from '../services/bey-toast.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BeyonicLinkOpenResolver implements Resolve<any> {
  constructor(private beyonicLinkService: BeyonicLinkService, private router: Router, private toast: BeyToastService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const linkId = route.params?.['link_id'];

    /*****
     * Load Recaptcha library then generate the
     * token and then return the info back
     */
    return this.beyonicLinkService.getBeyonicLinkInfo(linkId, true).pipe(
      catchError((err) => {
        console.error(err);

        if (err.status === 422) {
          this.router.navigate([`/closed/${route.paramMap.get('short_name')}`]);

          return throwError(err);
        }

        this.toast.open(err?.error?.detail || err?.error?.recaptcha[0] || err, 'error');
        this.router.navigate(['/error']);

        return EMPTY;
      })
    );
  }
}
