<div class="flex flex-col">
  <div class="flex items-center px-[18px] pt-1.5 lg:px-0">
    <div *ngIf="!(routeData$ | async)?.['hideBackButton'] || !!customBackButtonAction">
      <a class="cursor-pointer" getElementId getElementIdDescription="back_arrow" (click)="onBackClick()">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.44023 10.05L16.2402 10.05"
            stroke="#4B4B4B"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M9.45996 4L3.75996 10.05L9.45996 16"
            stroke="#4B4B4B"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </a>
    </div>
    <div
      class="mx-auto text-grey-800 font-bold text-lg leading-[21px] lg:text-[32px] lg:text-black lg:font-semibold lg:ml-10"
      [ngClass]="{ 'lg:!mx-auto': !!customTitle }">
      <span class="-ml-10" *ngIf="(routeData$ | async)?.['isPhoneBookPage']; else other">
        {{ headerTitle$ | async }}
      </span>
      <ng-template #other>
        <span *ngIf="customTitle; else routeDataTitle">{{ customTitle }}</span>

        <ng-template #routeDataTitle>
          <span>{{ (routeData$ | async)?.['title'] }}</span>
        </ng-template>
      </ng-template>
    </div>
    <div *ngIf="!(routeData$ | async)?.['hideBackButton'] || !!customBackButtonAction" class="w-5 h-5">&nbsp;</div>
  </div>
  <span
    *ngIf="!!(routeData$ | async)?.['subTitle']"
    class="text-grey-500 text-base mt-3 text-center lg:text-left lg:text-2xl lg:mt-3 lg:text-black"
    >{{ (routeData$ | async)?.['subTitle'] }}</span
  >
</div>
