import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { PaymentsService } from './payments.service';
import * as paymentsActions from './payments.actions';
import { empty } from '../auth/auth.actions';
import TYPES from './payments.types';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EMPTY, switchMap, of as observableOf } from 'rxjs';
import { BeyToastService } from 'src/app/modules/shared/services/bey-toast.service';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PostWithdrawDialogContentComponent } from 'src/app/modules/liquidation/components/post-withdraw-dialog-content/post-withdraw-dialog-content.component';
import { PostCreatePaymentDialogContentComponent } from 'src/app/modules/payments/components/post-create-payment-dialog-content/post-create-payment-dialog-content.component';
import * as paymentActions from './payments.actions';
import { PostBankPaymentDialogContentComponent } from '../../modules/payments/components/post-bank-payment-dialog-content/post-bank-payment-dialog-content.component';
import { BankItem, CreatePaymentRequestBody } from './payments.interfaces';
import { setWalletLimitsShownAlert } from '../wallets/wallets.actions';

@Injectable()
export class PaymentsEffects {
  constructor(
    private paymentsService: PaymentsService,
    private actions$: Actions,
    private toast: BeyToastService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  loadRecentPaymentsEffect$$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TYPES.GET_RECENT_PAYMENTS),
      mergeMap(() =>
        this.paymentsService.getRecentPayments().pipe(
          map((payload) => ({ type: TYPES.SET_RECENT_PAYMENTS, payload })),
          catchError(() => {
            this.toast.open(
              'Something went wrong, please refresh the page',
              'error',
              'Refresh',
              () => location.reload(),
              false,
              true
            );
            return EMPTY;
          })
        )
      )
    );
  });

  createPaymentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentsActions.createPaymentStart),
      switchMap(({ payload }) => {
        // removing id before sending
        const newPayload: CreatePaymentRequestBody = {
          ...payload,
        };
        delete newPayload?.['id'];

        return this.paymentsService.createPayment(newPayload).pipe(
          mergeMap(() => {
            this.dialog.closeAll();
            if (payload.type === 'withdraw') {
              this.router.navigate(['/']);
              this.dialog.open(PostWithdrawDialogContentComponent, {
                data: { amount: payload.amount, success: true },
              });
            } else if (payload.type === 'mobile-money') {
              this.router.navigate(['/']);
              this.dialog.open(PostCreatePaymentDialogContentComponent, {
                data: { payload, success: true },
              });
            } else if (payload.type === 'bank') {
              this.router.navigate(['/']);
              this.dialog.open(PostBankPaymentDialogContentComponent, { width: '90vw', maxWidth: 'unset' });
            }
            return [
              paymentsActions.createPaymentSuccess(payload),
              payload.type !== 'withdraw' ? setWalletLimitsShownAlert({ payload: null }) : empty(),
            ];
          }),
          catchError((error) => {
            this.dialog.closeAll();
            if (payload.withdrawal) {
              this.dialog.open(PostWithdrawDialogContentComponent, {
                data: { amount: payload.amount },
              });
            } else {
              this.dialog.open(PostCreatePaymentDialogContentComponent, { data: { payload, success: false } });
            }
            return observableOf(paymentsActions.createPaymentFailure(error));
          })
        );
      })
    )
  );

  getMoreUserPaymentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentsActions.getMoreUserPaymentsStart),
      switchMap(({ payload: { params } }) =>
        this.paymentsService.getMoreUserPayments(params).pipe(
          map(({ results, next }) =>
            paymentsActions.getMoreUserPaymentsSuccess({ payload: { payments: results, next } })
          ),
          catchError((error) => observableOf(paymentsActions.getMoreUserPaymentsFailure(error)))
        )
      )
    )
  );

  getBankListEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.getBankListStart),
      mergeMap(() =>
        this.paymentsService.getBanks().pipe(
          map((payload) => {
            const bankList: Array<BankItem> = Object.keys(payload).map((id) => ({
              id: parseInt(id),
              name: payload[id].name,
            }));

            return paymentActions.getBankListSuccess({ payload: bankList });
          }),
          catchError((e) => {
            this.toast.open('Something went wrong', 'error');
            return observableOf(paymentActions.getBankListFail(e));
          })
        )
      )
    )
  );

  getPaymentsFeesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentsActions.getPaymentsFeesStart),
      switchMap(() =>
        this.paymentsService.getPaymentsFees().pipe(
          map((payload) => paymentsActions.getPaymentsFeesSuccess({ payload })),
          catchError((error) => observableOf(paymentsActions.getPaymentsFeesFailure(error)))
        )
      )
    )
  );

  getBankPaymentsFeesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentsActions.getBankPaymentsFeesStart),
      switchMap(() =>
        this.paymentsService.getBankPaymentsFees().pipe(
          map((payload) => paymentsActions.getBankPaymentsFeesSuccess({ payload })),
          catchError((error) => observableOf(paymentsActions.getBankPaymentsFeesFailure(error)))
        )
      )
    )
  );
}
