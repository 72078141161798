import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { EMPTY, Observable, of, switchMap } from 'rxjs';
import { BeyToastService } from '../services/bey-toast.service';
import { catchError, take, tap } from 'rxjs/operators';
import { BeyonicLinkService } from '../../../ngrx/beyonic-link/beyonic-link.service';
import { Store } from '@ngrx/store';
import { BeyonicLink } from '../../../ngrx/beyonic-link/beyonic-link.interfaces';
import { selectUserBeyonicLinks } from '../../../ngrx/beyonic-link/beyonic-link.selectors';
import { setSelectedBeyonicLink as setSelectedBeyonicLinkAction } from 'src/app/ngrx/beyonic-link/beyonic-link.actions';

@Injectable({
  providedIn: 'root',
})
export class BeyonicLinkResolver implements Resolve<any> {
  userBeyonicLinks$: Observable<Array<BeyonicLink>>;

  constructor(
    private beyonicLinkService: BeyonicLinkService,
    private router: Router,
    private toast: BeyToastService,
    private store: Store
  ) {
    this.userBeyonicLinks$ = store.select(selectUserBeyonicLinks);
  }

  setSelectedBeyonicLink(beyonicLink: BeyonicLink): void {
    this.store.dispatch(setSelectedBeyonicLinkAction({ payload: { beyonicLink: beyonicLink } }));
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const linkId = route.params?.['id'] || route.params?.['link_id'];

    let fetchFunction = this.beyonicLinkService.getBeyonicLinkInfo(linkId).pipe(
      tap((link) => this.setSelectedBeyonicLink(link)),
      catchError((err) => {
        this.toast.open(err.error.detail, 'error');
        this.router.navigate(['/error']);

        return EMPTY;
      })
    );

    return this.store.select(selectUserBeyonicLinks).pipe(
      take(1),
      switchMap((data) => {
        // getting the beyonic link from store won't include the 'statistics' object, we comment it for now

        // if (!!data?.length) {
        //   // filter and return link data from store
        //   const linkData: BeyonicLink = data.find((v) => v.id === parseInt(linkId));
        //   if (linkData) {
        //     this.setSelectedBeyonicLink(linkData);
        //     return of(linkData);
        //   } else {
        //     // data not available fetch it from service
        //     return fetchFunction;
        //   }
        // }
        return fetchFunction;
      })
    );
  }
}
