import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  SignupRequestBody,
  LoginRequestBody,
  SendVerificationCodeRequestBody,
  VerifyVerificationCodeRequestBody,
  ResetUserPinRequestBody,
  VerifyVerificationCodeResponseBody,
  SignupResponseBody,
} from './auth.interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  registerUser(requestBody: SignupRequestBody) {
    return this.http.post<SignupResponseBody>(environment.API_URL + 'users/register/', requestBody);
  }

  loginUser({ phone, pin, recaptcha }: LoginRequestBody) {
    return this.http.post<any>(environment.API_URL + 'users/login/', {
      phone,
      password: pin,
      recaptcha,
      login_type: 'web',
    });
  }

  sendVerificationCode({ phone }: SendVerificationCodeRequestBody) {
    return this.http.get<any>(environment.API_URL + `users/verify/${phone}/`);
  }

  verifyVerificationCode({ extras }: VerifyVerificationCodeRequestBody) {
    const { otp, phone } = extras;

    return this.http.post<VerifyVerificationCodeResponseBody>(environment.API_URL + `users/verify/${phone}/`, {
      otp,
    });
  }

  resetUserPin({ requestBody, phone }: { requestBody: ResetUserPinRequestBody; phone: string }) {
    return this.http.post<any>(environment.API_URL + `users/reset-pin-with-id/${phone}/`, requestBody);
  }

  refreshToken(refreshToken: string) {
    return this.http.post<{ access: string }>(environment.API_URL + 'users/refresh/', {
      refresh: refreshToken,
    });
  }
}
