<div class="ease-in-out duration-500 text-grey-400 focus-within:text-bey-black">
  <label
    *ngIf="label"
    class="block text-grey-600 lg:leading-6 text-sm lg:text-base font-bold mb-1"
    [ngClass]="classList?.['label']"
    >{{ label }}</label
  >

  <div
    getElementId
    getElementIdDescription="country-selector-dropdown-component"
    class="w-full flex border rounded px-2 focus-within:border-grey-500 transition ease-in-out duration-500 relative"
    [ngClass]="{
      'bg-gray-100': readonly,
      'border-[#C4C4C4]': !displayError,
      'border-bey-red': displayError
    }">
    <div class="fixed h-full w-full top-0 inset-0" (click)="toggleFocus(true)" *ngIf="focused"></div>
    <div class="flex justify-center items-center mr-2.5">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        *ngIf="!value?.isoCode; else selectedCountry">
        <path
          d="M6.31162 10.2399C8.79911 10.2399 10.8156 8.22342 10.8156 5.73593C10.8156 3.24844 8.79911 1.23193 6.31162 1.23193C3.82413 1.23193 1.80762 3.24844 1.80762 5.73593C1.80762 8.22342 3.82413 10.2399 6.31162 10.2399Z"
          stroke="#80807D"
          stroke-width="1.2" />
        <path d="M9.80762 9.51221L14.0076 14.0162" stroke="#80807D" stroke-width="1.2" stroke-linecap="round" />
      </svg>

      <ng-template #selectedCountry>
        <img
          width="24"
          height="24"
          class="flag"
          [src]="'https://flagcdn.com/' + value?.isoCode?.toLowerCase() + '.svg'"
          [alt]="value?.label"
          loading="lazy" />
      </ng-template>
    </div>
    <input
      type="text"
      name="password"
      autocomplete="off"
      class="w-full focus:outline-none text-grey-800 text-sm py-2 cursor-pointer"
      [readOnly]="true"
      [ngClass]="{
        'bg-gray-100': readonly
      }"
      [value]="inputFieldValue"
      getElementId
      getElementIdDescription="country-selector-input-field"
      (input)="updateInputVal($event)"
      (focusin)="toggleFocus()"
      (keydown)="jumpToList($event)"
      #codeInput />
    <div class="relative pr-2">
      <button
        class="absolute inset-0"
        type="button"
        (click)="clear()"
        *ngIf="inputFieldValue && !(readonly || readonlyNoFeedback)"
        @fadeIn
        @fadeOut>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 opacity-70" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd" />
        </svg>
      </button>
      <button
        class="absolute inset-0"
        type="button"
        (click)="toggleFocus(focused)"
        *ngIf="!inputFieldValue && !(readonly || readonlyNoFeedback)"
        @fadeIn
        @fadeOut>
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L5 5L9 1" stroke="#4B4B4B" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    </div>
    <div
      class="absolute top-8 left-0 bg-white w-[236px] transition-all ease-in-out duration-300 rounded-md menu"
      [ngClass]="focused && !(readonly || readonlyNoFeedback) ? 'opacity-1 z-100' : '-z-1 opacity-0 '"
      getElementId
      getElementIdDescription="country-selector-dropdown-menu">
      <ul class="unstyled">
        <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="viewport">
          <li
            class="flex py-1.5 items-center transition ease-in-out duration-500 hover:bg-blue-100 cursor-pointer min-h-12 focus:outline-1"
            *cdkVirtualFor="
              let country of options | countryFilterPipe : inputFieldValue;
              let i = index;
              let isLast = last
            "
            (click)="selectCountry(country)"
            (keydown)="handleKey($event, country)"
            #countryOption
            [attr.tabIndex]="i"
            [ngClass]="{ 'bg-[#CCE4F1]': value?.value === country.value, 'border-b': !isLast }"
            getElementId
            [getElementIdDescription]="'country-option ' + country.isoCode">
            <img
              width="24"
              height="24"
              class="flag"
              [src]="
                country.isoCode
                  ? 'https://flagcdn.com/' + country.isoCode?.toLowerCase() + '.svg'
                  : '/assets/images/white-flag.png'
              "
              [alt]="country.label"
              loading="lazy" />
            <div class="flex items-center text-grey-600 text-sm leading-[14px] pr-2">
              <span class="font-bold mr-4"> {{ country.value }} </span> {{ country.label }}
            </div>
          </li>
        </cdk-virtual-scroll-viewport>
        <li class="pb-[15px]"></li>
      </ul>
    </div>
  </div>
  <bey-field-error-message
    *ngIf="!hideValidationFeedback && displayError"
    [displayError]="displayError"
    [errorMessage]="errorMessage">
  </bey-field-error-message>
  <div *ngIf="!displayError"><ng-content></ng-content></div>
</div>
