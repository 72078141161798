import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { mergeMap, of as observableOf, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { WalletsService } from './wallets.service';
import * as walletsActions from './wallets.actions';
import { Router } from '@angular/router';
import { BeyToastService } from '../../modules/shared/services/bey-toast.service';
import {
  getBillingAttemptDetailsStart,
  getBillingAttemptDetailsSuccess,
  paySubNowFailure,
  paySubNowStart,
  paySubNowSuccess,
} from './wallets.actions';
import { TypedAction } from '@ngrx/store/src/models';

@Injectable()
export class WalletsEffects {
  constructor(
    private walletsService: WalletsService,
    private actions$: Actions,
    private router: Router,
    private toast: BeyToastService
  ) {}

  getUserWalletEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(walletsActions.getUserWalletStart),
      switchMap(({ payload: { business_id } }) =>
        this.walletsService.getUserWallet(business_id).pipe(
          map(({ results }) => walletsActions.getUserWalletSuccess({ payload: results?.[0] })),
          catchError((error) => observableOf(walletsActions.getUserWalletFailure(error)))
        )
      )
    )
  );

  getUserBalanceEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(walletsActions.getUserBalanceStart),
      switchMap(({ payload }) =>
        this.walletsService.getUserBalance(payload.balanceId).pipe(
          map((data) => walletsActions.getUserBalanceSuccess({ payload: data.results })),
          catchError((error) => observableOf(walletsActions.getUserBalanceFailure(error)))
        )
      )
    )
  );

  getUserBillingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(walletsActions.getUserBillingsStart),
      switchMap(({ payload: { business_id } }) =>
        this.walletsService.getUserBillings(business_id).pipe(
          map((data) => walletsActions.getUserBillingsSuccess({ payload: data })),
          catchError((error) => {
            this.toast.open(
              'Something went wrong, please refresh the page',
              'error',
              'Refresh',
              () => location.reload(),
              false,
              true
            );
            return observableOf(walletsActions.getUserBillingsFailure(error));
          })
        )
      )
    )
  );

  getMoreUserCollectionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(walletsActions.getMoreUserBillingsStart),
      switchMap(({ payload: { params } }) =>
        this.walletsService.getMoreUserBillings(params).pipe(
          map(({ results, next }) => walletsActions.getMoreUserBillingsSuccess({ payload: { results, next } })),
          catchError((error) => observableOf(walletsActions.getMoreUserBillingsFailure(error)))
        )
      )
    )
  );

  getSubscriptionPriceEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(walletsActions.getSubscriptionPriceStart),
      switchMap(() =>
        this.walletsService.getSubscriptionPrice().pipe(
          map((payload) => walletsActions.getSubscriptionPriceSuccess({ payload })),
          catchError((error) => observableOf(walletsActions.getSubscriptionPriceFailure(error)))
        )
      )
    )
  );

  paySubNowEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paySubNowStart),
      switchMap(({ business_id }) =>
        this.walletsService.paySubscriptionNow(business_id).pipe(
          mergeMap((i) => {
            // Dispatch an action that will get the billing attempts details using
            return [paySubNowSuccess({ result: i }), getBillingAttemptDetailsStart({ id: i.id, delay: 4000 })];
          }),
          catchError((e) => observableOf(paySubNowFailure(e)))
        )
      )
    )
  );

  getBillingAttemptDetailsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBillingAttemptDetailsStart),
      switchMap(({ id, delay = 0 }) =>
        timer(delay).pipe(
          switchMap(() =>
            this.walletsService.getBillingAttemptInfo(id).pipe(
              mergeMap((itm) => {
                const actions: Array<TypedAction<any>> = [getBillingAttemptDetailsSuccess({ result: itm })];

                if (itm.failure_reason) {
                  actions.push(paySubNowFailure({ error: new Error(itm.failure_reason) }));
                }

                return actions;
              }),
              catchError((e) => observableOf(paySubNowFailure({ error: e })))
            )
          )
        )
      )
    )
  );
}
