<div
  *ngIf="newTransactions && !(getUserPaymentsIsLoading$ | async) && !(getUserCollectionsIsLoading$ | async)"
  class="hidden bg-blue-50 py-1 px-10 rounded-full text-base font-normal text-grey-900 max-w-fit mx-auto mb-2 lg:block">
  {{ newTransactions }} new
</div>
<div class="flex flex-col rounded-lg py-6 px-4 mb-7 mx-[-12px] bg-white lg:mx-0">
  <div class="flex items-center justify-between mb-6">
    <span class="text-black text-base font-normal">Recent transactions</span>
    <a
      *ngIf="!showAllTransactions && !!(combined | async)?.length"
      getElementId
      getElementIdDescription="see_all"
      class="text-blue-400 font-medium text-base cursor-pointer"
      (click)="router.navigate(['/transactions'])">
      See all
    </a>
  </div>

  <div *ngIf="(getUserCollectionsIsLoading$ | async) || (getUserPaymentsIsLoading$ | async); else notLoading">
    <div class="flex gap-y-2 flex-col">
      <bey-recent-transactions-item-skeleton
        *ngFor="let i of [].constructor(6)"></bey-recent-transactions-item-skeleton>
    </div>
  </div>

  <ng-template #notLoading>
    <div *ngIf="!!(combined | async)?.length; else noTransactions">
      <table class="w-full">
        <tbody>
          <tr
            class="h-full before:bg-red-100 border-b-2 border-white cursor-pointer"
            *ngFor="let transaction of combined | async; let lastItem = last; let index = index"
            home-transaction-item
            (click)="transactionItmClick(transaction)"
            [isAnimation]="isAnimating"
            [transaction]="transaction"
            [ngClass]="{
              'last-item': lastItem,
              'new-transaction-item': transaction.newTransaction,
              'lg:bg-white': !transaction.newTransaction
            }"></tr>
        </tbody>
      </table>

      <bey-recent-transactions-item-skeleton
        *ngIf="
          (getMoreUserCollectionsIsLoading$ | async) || (getMoreUserPaymentsIsLoading$ | async)
        "></bey-recent-transactions-item-skeleton>
      <div
        *ngIf="!((getMoreUserCollectionsIsLoading$ | async) || (getMoreUserPaymentsIsLoading$ | async))"
        class="w-full"
        observeVisibility
        (visible)="onBottomSeen()">
        &nbsp;
      </div>
      <div
        *ngIf="this.showAllTransactions && combinedArrayLength >= 250"
        class="w-full flex items-center justify-center lg:py-9">
        <span class="text-base text-grey-600"
          >Need to see older records? Please check your previous month’s statement. Statements are automatically sent to
          your email each month.</span
        >
      </div>
    </div>
    <ng-template #noTransactions>
      <div class="w-full mx-auto flex flex-col items-center mt-10">
        <img src="assets/images/no-transactions.png" alt="no transactions" class="mb-3" />
        <span class="text-lg text-grey-800 font-medium mb-2.5 text-center">No transactions yet</span>
        <span class="text-base font-normal text-grey-500 text-center"
          >After your first transaction, you will be able to <br />
          view it here</span
        >
      </div>
    </ng-template>
  </ng-template>
</div>
