import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { parsePhoneNumber } from 'libphonenumber-js/min';
import { Observable, Subscription } from 'rxjs';
import { CreateCollectionRequestBody, CollectionsFees } from 'src/app/ngrx/collections/collections.interfaces';
import { selectCollectionsFees } from 'src/app/ngrx/collections/collections.selectors';
import { BankPaymentsFees, CreatePaymentRequestBody, PaymentsFees } from 'src/app/ngrx/payments/payments.interfaces';
import { selectBankPaymentsFees, selectPaymentsFees } from 'src/app/ngrx/payments/payments.selector';
import { updateCollectionInfo } from 'src/app/ngrx/collections/collections.actions';
import { updatePaymentInfo } from 'src/app/ngrx/payments/payments.actions';
import { selectUserKybState } from 'src/app/ngrx/user/user.selectors';
import { setActiveContact } from 'src/app/ngrx/contacts/contacts.actions';
import { Router } from '@angular/router';
import { selectFXRate } from 'src/app/ngrx/misc/misc.selectors';
import { userKyb } from 'src/app/ngrx/user/user.interfaces';
import { CROSS_BORDER_OPERATING_COUNTRIES } from 'src/assets/const';
import { Transaction } from 'src/app/ngrx/wallets/wallets.interfaces';
import { ApplyRoundingRulesPipeParameters } from 'src/assets/interfaces';

@Injectable({
  providedIn: 'root',
})
export class TransactionsUtilityService implements OnDestroy {
  collectionsFees: CollectionsFees;
  paymentsFees: PaymentsFees;
  bankPaymentsFees: BankPaymentsFees;
  userKybState$: Observable<userKyb>;
  subscriptions = new Subscription();

  constructor(private store$: Store, private router: Router) {
    this.subscriptions.add(store$.select(selectCollectionsFees)?.subscribe((fees) => (this.collectionsFees = fees)));
    this.subscriptions.add(store$.select(selectPaymentsFees)?.subscribe((fees) => (this.paymentsFees = fees)));
    this.subscriptions.add(store$.select(selectBankPaymentsFees)?.subscribe((fees) => (this.bankPaymentsFees = fees)));

    this.userKybState$ = store$.select(selectUserKybState);
  }

  getStatusAndColor(status: string): { status: string; color: string } {
    let successfulStatuses = ['successful', 'credited', 'delivered'];
    let failedStatuses = ['fee_reversal_failed', 'failed', 'reversal_failed'];
    if (successfulStatuses.includes(status)) {
      return { status: 'Completed', color: 'green' };
    } else if (failedStatuses.includes(status)) {
      return { status: 'Failed', color: 'red' };
    }

    return { status: 'Pending', color: 'orange' };
  }

  calculateFixedOrPercentageAmount(
    amountAndType: { amount: number; type: 'fixed' | 'percentage' | 'fixed_tier' },
    transactionAmount: number
  ): number {
    switch (amountAndType?.type) {
      case 'fixed':
        return amountAndType?.amount;
      case 'percentage':
        return transactionAmount * (amountAndType?.amount / 100);
      case 'fixed_tier':
        let feeAmount = 0;
        Object.keys(amountAndType?.amount).map((key) => {
          if (
            transactionAmount <= amountAndType?.amount[key]['max'] &&
            transactionAmount >= amountAndType?.amount[key]['min']
          ) {
            feeAmount = parseInt(key);
          }
        });
        return feeAmount;
    }
  }

  calculateFee(
    transaction:
      | CreatePaymentRequestBody
      | CreateCollectionRequestBody
      | { type: string; phone?: string; bank_id?: number; amount: number }
  ): number {
    let paymentsTypes: string[] = ['mobile-money', 'withdraw', 'mobile_money'];
    let { type } = transaction;
    let fee: number = 0;

    if (type != 'bank') {
      if (paymentsTypes.includes(type)) {
        // transaction type is payment
        let payment_fees =
          this.paymentsFees[Object.keys(this.paymentsFees).find((reg) => new RegExp(reg).test(transaction.phone))]
            ?.payment_fees;

        if (payment_fees) {
          fee = this.calculateFixedOrPercentageAmount(payment_fees, transaction?.amount);
        }
      } else {
        // transaction type is collection
        const triggeredKey = Object.keys(this.collectionsFees).find((key) => {
          const expression = new RegExp(key);
          if (expression.test(transaction.phone)) {
            return key;
          }
          return null;
        });

        if (triggeredKey) {
          fee = this.calculateFixedOrPercentageAmount(
            this.collectionsFees[triggeredKey].collection_fees,
            transaction?.amount
          );
        }
      }
    } else {
      fee = this.calculateFixedOrPercentageAmount(
        this.bankPaymentsFees[`${transaction?.['bank_id']}`],
        transaction?.amount
      );
    }

    return fee;
  }

  resendTransaction(transaction) {
    const {
      amount,
      contact: { first_name = '', last_name = '', phone = '', name_check_score = null, name_on_network = null } = {},
      description,
    } = transaction;

    const parsedPhone = parsePhoneNumber(phone);
    const payload = {
      amount: parseFloat(amount),
      code: `+${parsedPhone.countryCallingCode}` || null,
      phone_number: parsedPhone?.nationalNumber || null,
      description,
      first_name,
      last_name,
      phone,
      name_check_score,
      name_on_network,
    };

    if (transaction.type === 'collections') {
      if (transaction.top_up) {
        // compare if the phone on the transaction matches user phone number else means different number
        let url = '/top-up';
        this.router.navigate([url], { state: { transaction: transaction } });
      } else {
        // normal collection transaction
        this.store$.dispatch(
          updateCollectionInfo({
            payload: {
              ...payload,
              type: transaction?.related_transaction?.xb_fx_details?.is_xb_collection ? 'international' : 'local',
            },
          })
        );
        if (
          transaction.request_type === 'direct' ||
          transaction.request_type === 'static' ||
          transaction.request_type === 'beyonic_link'
        ) {
          this.router.navigate(['collections/create-collection']);
        } else {
          this.router.navigate(['collections-link']);
        }
      }
    } else if (transaction?.type === 'payments') {
      if (transaction?.withdrawal) {
        this.router.navigate(['withdraw'], { state: { transaction: transaction } });
      } else if (transaction.payment_type === 'mobile_money') {
        // mobile money payment
        this.store$.dispatch(updatePaymentInfo({ payload }));
        this.router.navigate(['payments/create']);
      } else {
        // bank payment
        const { bank_id, bank_account_number } = transaction;
        this.store$.dispatch(setActiveContact({ payload: transaction.contact }));
        this.router.navigate(['/bank-payments'], {
          state: { oldTransactionFinancialDetails: { bank_account_number, bank_id, description, amount } },
        });
      }
    }
  }

  calculateFx(value: number, renderForMerchant: boolean) {
    let fxRate: number;
    this.subscriptions.add(this.store$.select(selectFXRate).subscribe((rate) => (fxRate = rate)));

    // todo remove this because it's useless
    // We're converting from the payer currency to the beneficiary currency here
    if (!renderForMerchant) {
      return Math.ceil(value ? (fxRate ? value * fxRate : value) : 0);
    }

    return Math.ceil(value ? (fxRate ? value / fxRate : value) : 0);
  }

  // for bey-profile-completion and profile components
  onReUploadRejectedDocsButtonClick() {
    let userKybData: userKyb;
    this.subscriptions.add(this.userKybState$.subscribe((data) => (userKybData = data)));

    let targetedRoute: string = '';

    if (userKybData?.['businessDocs']?.length) {
      if (userKybData?.['kybFileRejected']) {
        targetedRoute = '/business-documents/rejected';
      } else {
        targetedRoute = '/business-documents';
      }
    } else {
      targetedRoute = '/business-documents/upload';
    }

    this.router.navigate([targetedRoute]);
  }

  /******
   *   //todo add description of the function and it's return
   * @param transaction
   */
  retrieveApplyRoundingRulesPipeParameters(transaction: Transaction): ApplyRoundingRulesPipeParameters {
    let country = CROSS_BORDER_OPERATING_COUNTRIES.find((country) => country.currency === transaction.amount_currency);

    if (!country) {
      // this is a fallback to help with transactions that don't have enough info like payments
      country = CROSS_BORDER_OPERATING_COUNTRIES.find((country) => country.label === transaction.business.country);
    }

    return {
      countryName: country.label,
      transactionType: transaction?.type === 'collections' || transaction.is_xb_request ? 'collection' : 'payment',
      isXB: !!transaction?.related_transaction?.xb_fx_details,
      countryCurrency: country.currency,
    };
  }

  retrieveCurrencyPipeDigitalInfo(country: string): string {
    const countriesDigitalInfo = Object.freeze({
      Uganda: '1.0-0',
      Kenya: '1.0-0',
      DRC: '1.0-0',
      Tanzania: '1.0-0',
    });

    return countriesDigitalInfo[country];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
