<nav class="hidden lg:flex h-full w-full flex-col justify-between bg-grey-100 w-[230px]">
  <div class="overflow-y-auto">
    <img src="/assets/images/logo/full-color.svg" alt="Beyonic logo" class="p-4" />

    <div class="mx-6 my-4 text-xs font-normal text-black uppercase">Your Account</div>
    <a
      class="home"
      routerLinkActive="active"
      getElementId
      getElementIdDescription="home"
      routerLink="/"
      [routerLinkActiveOptions]="{ exact: true }">
      <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.9998 3.75L4.0498 11.25V24.195C4.0821 25.3338 5.01059 26.2424 6.1498 26.25H11.4298V15.645H18.1198V26.25H23.8048C24.3763 26.25 24.9242 26.022 25.3269 25.6165C25.7296 25.2109 25.9538 24.6615 25.9499 24.09V11.19L14.9998 3.75Z"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
      <span>Home</span>
    </a>
    <details class="group cursor-pointer">
      <summary class="list-none flex items-center gap-5 w-full py-4 px-6 border-b">
        <svg width="24" height="24" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M29.4099 14.3308H4.5899L11.8829 8.19385"
            stroke="#2F2F2E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M4.5899 20.8418H29.4099L22.1169 26.9788"
            stroke="#2F2F2E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        <span>Transfer</span>
        <svg
          class="group-open:hidden"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M6 9L12 15L18 9" stroke="#242729" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <svg
          class="hidden group-open:block"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M18 15L12 9L6 15" stroke="#242729" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </summary>
      <a
        class="home"
        routerLinkActive="active"
        getElementId
        getElementIdDescription="send-money"
        routerLink="/payments"
        [routerLinkActiveOptions]="{ exact: true }">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000000" viewBox="0 0 256 256">
          <path
            d="M200,64V168a8,8,0,0,1-16,0V83.31L69.66,197.66a8,8,0,0,1-11.32-11.32L172.69,72H88a8,8,0,0,1,0-16H192A8,8,0,0,1,200,64Z"></path>
        </svg>
        <span>Send Money</span>
      </a>
      <a
        routerLinkActive="active"
        getElementId
        getElementIdDescription="bulk-payments"
        routerLink="/payments/bulk"
        [routerLinkActiveOptions]="{ exact: true }">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2 12.0001L11.6422 16.8212C11.7734 16.8868 11.839 16.9196 11.9078 16.9325C11.9687 16.9439 12.0313 16.9439 12.0922 16.9325C12.161 16.9196 12.2266 16.8868 12.3578 16.8212L22 12.0001M2 17.0001L11.6422 21.8212C11.7734 21.8868 11.839 21.9196 11.9078 21.9325C11.9687 21.9439 12.0313 21.9439 12.0922 21.9325C12.161 21.9196 12.2266 21.8868 12.3578 21.8212L22 17.0001M2 7.00006L11.6422 2.17895C11.7734 2.11336 11.839 2.08056 11.9078 2.06766C11.9687 2.05622 12.0313 2.05622 12.0922 2.06766C12.161 2.08056 12.2266 2.11336 12.3578 2.17895L22 7.00006L12.3578 11.8212C12.2266 11.8868 12.161 11.9196 12.0922 11.9325C12.0313 11.9439 11.9687 11.9439 11.9078 11.9325C11.839 11.9196 11.7734 11.8868 11.6422 11.8212L2 7.00006Z"
            stroke="#242729"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        <span>Bulk Payments</span>
      </a>
    </details>

    <a routerLinkActive="active" getElementId getElementIdDescription="transactions" routerLink="/transactions">
      <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.3 11.2649V26.4449" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.7002 16.6648V26.4448" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M15 3.16479V26.4448" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <span>History</span>
    </a>
    <a routerLink="/links" routerLinkActive="active" getElementId getElementIdDescription="links">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 13C10.4295 13.5741 10.9774 14.0491 11.6066 14.3929C12.2357 14.7367 12.9315 14.9411 13.6467 14.9923C14.3618 15.0435 15.0796 14.9403 15.7513 14.6897C16.4231 14.4392 17.0331 14.047 17.54 13.54L20.54 10.54C21.4508 9.59695 21.9548 8.33394 21.9434 7.02296C21.932 5.71198 21.4061 4.45791 20.4791 3.53087C19.5521 2.60383 18.298 2.07799 16.987 2.0666C15.676 2.0552 14.413 2.55918 13.47 3.46997L11.75 5.17997M14 11C13.5705 10.4258 13.0226 9.95078 12.3935 9.60703C11.7643 9.26327 11.0685 9.05885 10.3534 9.00763C9.63821 8.95641 8.92041 9.0596 8.24866 9.31018C7.5769 9.56077 6.96689 9.9529 6.46 10.46L3.46 13.46C2.54921 14.403 2.04524 15.666 2.05663 16.977C2.06802 18.288 2.59387 19.542 3.52091 20.4691C4.44795 21.3961 5.70201 21.9219 7.013 21.9333C8.32398 21.9447 9.58699 21.4408 10.53 20.53L12.24 18.82"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>

      <span>Links</span>
    </a>
    <a routerLinkActive="active" getElementId getElementIdDescription="profile" routerLink="/profile">
      <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.6199 25.128C20.5331 25.128 26.1374 19.9058 26.1374 13.464C26.1374 7.02214 20.5331 1.79999 13.6199 1.79999C6.70671 1.79999 1.10245 7.02214 1.10245 13.464C1.10245 19.9058 6.70671 25.128 13.6199 25.128Z"
          stroke="#2F2F2E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          d="M13.6199 13.144C15.4786 13.144 16.9854 11.74 16.9854 10.008C16.9854 8.27604 15.4786 6.87201 13.6199 6.87201C11.7612 6.87201 10.2545 8.27604 10.2545 10.008C10.2545 11.74 11.7612 13.144 13.6199 13.144Z"
          stroke="#2F2F2E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          d="M6.64859 22.696V20.104C6.79441 18.471 8.32959 17.255 10.0827 17.384H17.1227C18.8728 17.264 20.4021 18.4753 20.5569 20.104V22.696"
          stroke="#2F2F2E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>

      <span>Profile</span>
    </a>
    <a routerLink="/billing" routerLinkActive="active" getElementId getElementIdDescription="billing">
      <svg width="28" height="28" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M28.458 8.5H5.54202C4.35903 8.5 3.40002 9.45901 3.40002 10.642V23.358C3.40002 24.541 4.35903 25.5 5.54202 25.5H28.458C29.641 25.5 30.6 24.541 30.6 23.358V10.642C30.6 9.45901 29.641 8.5 28.458 8.5Z"
          stroke="#2F2F2E"
          stroke-width="2.4"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          d="M3.40002 16.1504H30.6"
          stroke="#2F2F2E"
          stroke-width="2.4"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>

      <span>Billing</span>
    </a>
    <div class="mx-6 mt-4 text-xs font-normal text-black">SUPPORT</div>
    <a target="_blank" href="https://help.beyonic.com/en/" getElementId getElementIdDescription="faq">
      <svg width="28" height="28" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.9999 29.3934C23.8444 29.3934 29.3929 23.8449 29.3929 17.0004C29.3929 10.156 23.8444 4.60742 16.9999 4.60742C10.1555 4.60742 4.60693 10.156 4.60693 17.0004C4.60693 23.8449 10.1555 29.3934 16.9999 29.3934Z"
          stroke="#2F2F2E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          d="M13.7019 12.4435C14.3251 10.6905 16.1193 9.64019 17.9529 9.95489C19.7866 10.2696 21.1278 11.858 21.1309 13.7185C21.1309 16.2685 17.3059 17.5435 17.3059 17.5435"
          stroke="#2F2F2E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          d="M16.7789 25.6187C17.7177 25.6187 18.4789 24.8576 18.4789 23.9187C18.4789 22.9799 17.7177 22.2188 16.7789 22.2188C15.84 22.2188 15.0789 22.9799 15.0789 23.9187C15.0789 24.8576 15.84 25.6187 16.7789 25.6187Z"
          fill="#2F2F2E" />
        <path
          d="M16.7791 17.5439V19.4479"
          stroke="#2F2F2E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>

      <span>FAQ</span>
    </a>
  </div>
  <div
    routerLink="/profile"
    class="cursor-pointer w-full px-4 py-5 flex items-center bg-[#EDEDEB] max-w-[250px] min-w-[200px]">
    <span
      class="bg-blue-600 rounded-full p-0.5 cursor-default text-white text-base w-[42px] h-[42px] flex items-center justify-center mr-4">
      {{ (currentUser$ | async)?.first_name + ' ' + (currentUser$ | async)?.last_name | nameInitials }}
    </span>
    <span class="text-[#454545] text-normal text-lg capitalize"
      >{{ (currentUser$ | async)?.first_name }} {{ (currentUser$ | async)?.last_name }}</span
    >
  </div>
</nav>
