import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProfileInterface } from 'src/app/ngrx/user/user.interfaces';
import { selectUserKycDetails } from '../../../../ngrx/KYC/kyc.selectors';
import { NameInitialsPipe } from '../../../shared/pipes/name-initials.pipe';
import { AsyncPipe } from '@angular/common';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { GetElementIdDirective } from '../../../shared/directives/get-element-id.directive';

@Component({
  selector: 'aside[root-sidebar]',
  templateUrl: './root-sidebar.component.html',
  styleUrls: ['./root-sidebar.component.scss'],
  standalone: true,
  imports: [RouterLinkActive, RouterLink, AsyncPipe, NameInitialsPipe, GetElementIdDirective],
})
export class RootSidebarComponent implements OnInit {
  currentUser$: Observable<ProfileInterface>;

  constructor(private store$: Store) {}

  ngOnInit(): void {
    this.currentUser$ = this.store$.select(selectUserKycDetails);
  }
}
