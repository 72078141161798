export const authTypes = {
  SIGNUP_START: 'SIGNUP_START',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP_FAILURE',
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  SEND_VERIFICATION_CODE_START: 'SEND_VERIFICATION_CODE_START',
  SEND_VERIFICATION_CODE_SUCCESS: 'SEND_VERIFICATION_CODE_SUCCESS',
  SEND_VERIFICATION_CODE_FAILURE: 'SEND_VERIFICATION_CODE_FAILURE',
  VERIFY_VERIFICATION_CODE_START: 'VERIFY_VERIFICATION_CODE_START',
  VERIFY_VERIFICATION_CODE_SUCCESS: 'VERIFY_VERIFICATION_CODE_SUCCESS',
  VERIFY_VERIFICATION_CODE_FAILURE: 'VERIFY_VERIFICATION_CODE_FAILURE',
  RESET_USER_PIN_START: 'RESET_USER_PIN_START',
  RESET_USER_PIN_SUCCESS: 'RESET_USER_PIN_SUCCESS',
  RESET_USER_PIN_FAILURE: 'RESET_USER_PIN_FAILURE',
  SET_LOGIN_IS_LOADING_TO_FALSE: 'SET_LOGIN_IS_LOADING_TO_FALSE',
  LOG_OUT: 'LOG_OUT',
  UPDATE_TOKEN: 'UPDATE_TOKEN',
  LOCK_ACCOUNT: 'LOCK_ACCOUNT',
  UNLOCK_ACCOUNT: 'UNLOCK_ACCOUNT',
  SET_CURRENT_ROUTE: 'SET_CURRENT_ROUTE',
  EMPTY: 'EMPTY',
};
