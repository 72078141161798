import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of as observableOf, catchError, switchMap, mergeMap, map } from 'rxjs';
import { BulkPaymentsService } from './bulk-payments.service';
import {
  createBulkPaymentStart,
  createBulkPaymentSuccess,
  createBulkPaymentFailure,
  addRecipientsToBulkPaymentFailure,
  addRecipientsToBulkPaymentStart,
  addRecipientsToBulkPaymentSuccess,
  fetchToBeProcessedBulkPaymentFailure,
  fetchToBeProcessedBulkPaymentStart,
  fetchToBeProcessedBulkPaymentSuccess,
  setToBeProcessedBulkPayment,
  processBulkPaymentStart,
  processBulkPaymentSuccess,
  processBulkPaymentFailure,
  fetchToBeProcessedBulkPaymentRecipientsSuccess,
  fetchToBeProcessedBulkPaymentRecipientsStart,
  fetchToBeProcessedBulkPaymentRecipientsFailure,
  getUserBulkPaymentsStart,
  getUserBulkPaymentsSuccess,
  getUserBulkPaymentsFailure,
  getPaymentsForBulkPaymentStart,
  getPaymentsForBulkPaymentSuccess,
  getPaymentsForBulkPaymentFailure,
  getMoreUserBulkPaymentsStart,
  getMoreUserBulkPaymentsSuccess,
  getMoreUserBulkPaymentsFailure,
  updatePaymentForBulkPaymentStart,
  updatePaymentForBulkPaymentSuccess,
  updatePaymentForBulkPaymentFailure,
  deletePaymentForBulkPaymentStart,
  deletePaymentForBulkPaymentSuccess,
  deletePaymentForBulkPaymentFailure,
} from './bulk-payments.actions';
import { toggleHeadlessStepperStep } from '../misc/misc.actions';
import { Router } from '@angular/router';
import { ContactsService } from '../contacts/contacts.service';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { BeyToastService } from 'src/app/modules/shared/services/bey-toast.service';
import { ProcessBulkPaymentSuccessDialogContentComponent } from 'src/app/modules/bulk-payments/components/process-bulk-payment-success-dialog-content/process-bulk-payment-success-dialog-content.component';

@Injectable()
export class BulkPaymentsEffects {
  constructor(
    private bulkPaymentsService: BulkPaymentsService,
    private contactsService: ContactsService,
    private actions$: Actions,
    private router: Router,
    private dialog: MatLegacyDialog,
    private toast: BeyToastService
  ) {}

  createBulkPaymentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createBulkPaymentStart),
      switchMap(({ payload }) =>
        this.bulkPaymentsService.createBulkPayment(payload).pipe(
          mergeMap((payload) => {
            this.router.navigate(['/payments/bulk/create/' + payload.id]);
            return [
              createBulkPaymentSuccess(),
              setToBeProcessedBulkPayment({ payload }),
              toggleHeadlessStepperStep({ currentStep: 1 }),
            ];
          }),
          catchError((e) => {
            console.error(e);
            return observableOf(createBulkPaymentFailure({ error: e }));
          })
        )
      )
    )
  );

  addRecipientsToBulkPaymentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addRecipientsToBulkPaymentStart),
      switchMap(({ payload: { contacts, id, payments } }) =>
        this.contactsService.createBulkContact(contacts).pipe(
          switchMap(() =>
            this.bulkPaymentsService.addRecipientsToBulkPayment(id, payments).pipe(
              mergeMap(() => [
                addRecipientsToBulkPaymentSuccess(),
                fetchToBeProcessedBulkPaymentStart({ payload: { id } }),
                fetchToBeProcessedBulkPaymentRecipientsStart({ payload: { id } }),
              ]),
              catchError((e) => {
                console.error(e);
                this.dialog.closeAll();
                this.toast.open('Something went wrong, please try again');
                return observableOf(addRecipientsToBulkPaymentFailure({ error: e }));
              })
            )
          )
        )
      )
    )
  );

  processBulkPaymentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processBulkPaymentStart),
      switchMap(({ payload: { id } }) =>
        this.bulkPaymentsService.processBulkPayment(id).pipe(
          mergeMap(() => {
            this.router.navigate(['/payments/bulk/' + id]);
            this.dialog.open(ProcessBulkPaymentSuccessDialogContentComponent, { width: '90vw', maxWidth: '380px' });

            return [
              processBulkPaymentSuccess(),
              toggleHeadlessStepperStep({ currentStep: 0 }),
              setToBeProcessedBulkPayment({ payload: null }),
            ];
          }),
          catchError((e) => {
            console.error(e);
            return observableOf(processBulkPaymentFailure({ error: e }));
          })
        )
      )
    )
  );
  getUserBulkPayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserBulkPaymentsStart),
      switchMap(({ payload }) =>
        this.bulkPaymentsService.getBulkPayments(payload && payload.filters).pipe(
          switchMap((response) =>
            this.bulkPaymentsService
              .getUserBulkPaymentsSummary()
              .pipe(map((summary) => getUserBulkPaymentsSuccess({ payload: { ...response, summary } })))
          ),

          catchError((e) => {
            return observableOf(getUserBulkPaymentsFailure({ error: e }));
          })
        )
      )
    )
  );

  fetchToBeProcessedBulkPaymentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchToBeProcessedBulkPaymentStart),
      switchMap(({ payload: { id } }) =>
        this.bulkPaymentsService.getBulkPayment(id).pipe(
          mergeMap((payload) => {
            this.dialog.closeAll();
            return [
              fetchToBeProcessedBulkPaymentSuccess({ payload }),
              toggleHeadlessStepperStep({ currentStep: payload.total_count > 0 ? 2 : 1 }),
            ];
          }),
          catchError((e) => {
            console.error(e);
            return observableOf(fetchToBeProcessedBulkPaymentFailure({ error: e?.message }));
          })
        )
      )
    )
  );
  getMoreUserBulkPaymentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMoreUserBulkPaymentsStart),
      switchMap(({ payload: { params } }) =>
        this.bulkPaymentsService.getMoreUserBulkPayments(params).pipe(
          map((response) => getMoreUserBulkPaymentsSuccess({ payload: response })),
          catchError((e) => {
            console.error(e);

            return observableOf(getMoreUserBulkPaymentsFailure({ error: e.error }));
          })
        )
      )
    )
  );

  fetchToBeProcessedBulkPaymentRecipientsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchToBeProcessedBulkPaymentRecipientsStart),
      switchMap(({ payload: { id } }) =>
        this.bulkPaymentsService.getRecipientsAssociatedToBulkPayment(id).pipe(
          map(({ results: payments }) => fetchToBeProcessedBulkPaymentRecipientsSuccess({ payload: payments })),
          catchError((e) => {
            console.error(e);
            return observableOf(fetchToBeProcessedBulkPaymentRecipientsFailure({ error: e?.message }));
          })
        )
      )
    )
  );
  getPaymentsForBulkPaymentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaymentsForBulkPaymentStart),
      switchMap(({ payload: { id } }) =>
        this.bulkPaymentsService.getPaymentsForBulkPayment(id).pipe(
          map((response) => getPaymentsForBulkPaymentSuccess({ payload: response })),
          catchError((e) => {
            console.error(e);

            return observableOf(getPaymentsForBulkPaymentFailure({ error: e.error }));
          })
        )
      )
    )
  );

  updatePaymentForBulkPaymentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePaymentForBulkPaymentStart),
      switchMap(({ payload: { paymentId, bulkPaymentId, payment } }) =>
        this.bulkPaymentsService.updatePaymentForBulkPayment(paymentId, bulkPaymentId, payment).pipe(
          switchMap((response) => {
            this.dialog.closeAll();
            return [
              updatePaymentForBulkPaymentSuccess({ payload: response }),
              fetchToBeProcessedBulkPaymentStart({ payload: { id: bulkPaymentId } }),
              fetchToBeProcessedBulkPaymentRecipientsStart({ payload: { id: bulkPaymentId } }),
            ];
          }),
          catchError((e) => {
            console.error(e);

            return observableOf(updatePaymentForBulkPaymentFailure({ error: e.error }));
          })
        )
      )
    )
  );

  deletePaymentForBulkPaymentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePaymentForBulkPaymentStart),
      switchMap(({ payload: { paymentId, bulkPaymentId } }) =>
        this.bulkPaymentsService.deletePaymentForBulkPayment(paymentId, bulkPaymentId).pipe(
          switchMap(() => {
            this.dialog.closeAll();
            return [
              deletePaymentForBulkPaymentSuccess(),
              fetchToBeProcessedBulkPaymentStart({ payload: { id: bulkPaymentId } }),
              fetchToBeProcessedBulkPaymentRecipientsStart({ payload: { id: bulkPaymentId } }),
            ];
          }),
          catchError((e) => {
            console.error(e);

            return observableOf(deletePaymentForBulkPaymentFailure({ error: e.error }));
          })
        )
      )
    )
  );
}
