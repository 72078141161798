import { BeyCountrySelectorOptionInterface } from '../interfaces/bey-country-selector.interface';
import { BeyonicCountry } from '../interfaces';

export const ENV_NAMES = {
  STAGING: 'STAGING',
  PROD: 'PROD',
  DEV: 'DEV',
};

// LIST OF COUNTRIES WHERE WE OPERATE
export const OPERATING_COUNTRIES: Array<BeyCountrySelectorOptionInterface> = [
  {
    label: 'Uganda',
    value: '+256',
    isoCode: 'ug',
    currency: 'UGX',
  },
];

// List of countries where Beyonic operate (NON-SELECT MENU ARRAY)
export const BEYONIC_COUNTRIES: Array<BeyonicCountry> = [
  {
    name: 'Uganda',
    isoCode: 'UG',
    currency: 'UGX',
    countryCode: '+256',
  },
  {
    name: 'Congo (the Democratic Republic of the)',
    isoCode: 'CD',
    currency: 'CDF',
    countryCode: '+243',
  },
  {
    name: 'Kenya',
    isoCode: 'KE',
    currency: 'KES',
    countryCode: '+254',
  },
  {
    name: 'Tanzania',
    isoCode: 'TZ',
    currency: 'TZS',
    countryCode: '+255',
  },
  {
    name: 'Rwanda',
    isoCode: 'RW',
    currency: 'RWF',
    countryCode: '+250',
  },
  {
    name: 'Ghana',
    isoCode: 'GH',
    currency: 'GHS',
    countryCode: '+233',
  },
];

export const CONST_FUNCTION_CAROUSEL_1_SEEN_KEY = '_bey-function-carousel-1-seen';
export const CONST_FUNCTION_CAROUSEL_2_SEEN_KEY = '_bey-function-carousel-2-seen';
export const CONST_REFERRAL_CODE = '_bey-referral-code';

export const COLLECTION_LINK_STATUSES = {
  NEW: 'new',
  PROCESSING: 'processing',
  FAILED: 'failed',
  SUCCESSFUL: 'successful',
};
// prettier-ignore
export const SPECIAL_CHARACTERS_REGEX_VALIDATION = '^((?![^A-Za-z0-9_\\s-]).)*$';
// prettier-ignore
export const SPECIAL_CHARACTERS_BUSINESS_NAME_REGEX_VALIDATION = "^((?![^A-Za-z0-9 '-.]).)*$";
// prettier-ignore
export const SPECIAL_CHARACTERS_REGEX_VALIDATION_FOR_DESCRIPTION_FIELD = '^((?![^A-Za-z0-9 _@.+-]).)*$';

export const COUNT_FORMATS = [
  {
    // 0 - 999
    letter: '',
    limit: 1e3,
  },
  {
    // 1,000 - 999,999
    letter: 'K',
    limit: 1e6,
  },
  {
    // 1,000,000 - 999,999,999
    letter: 'M',
    limit: 1e9,
  },
  {
    // 1,000,000,000 - 999,999,999,999
    letter: 'B',
    limit: 1e12,
  },
  {
    // 1,000,000,000,000 - 999,999,999,999,999
    letter: 'T',
    limit: 1e15,
  },
];

export enum TRANSACTION_TYPES {
  OUTGOING,
  INCOMING,
}

export const CROSS_BORDER_OPERATING_COUNTRIES: Array<BeyCountrySelectorOptionInterface> = [
  {
    label: 'Uganda',
    isoCode: 'UG',
    currency: 'UGX',
    countryCode: '+256',
    value: '+256',
    supportedTelcos: [
      { name: 'MTN', expression: /^\+?256(31|39|76|77|78)[0-9]{7}/ },
      { name: 'Airtel', expression: /^\+?256(20|70|74|75)[0-9]{7}/ },
    ],
  },
  {
    label: 'Congo (the Democratic Republic of the)',
    isoCode: 'CD',
    currency: 'CDF',
    countryCode: '+243',
    value: '+243',
    supportedTelcos: [
      { name: 'Orange', expression: /^\+243(80|84|85|89)[0-9]{7}/ },
      { name: 'Airtel', expression: /^\+243(97|98|99)[0-9]{7}/ },
    ],
  },
  {
    label: 'Kenya',
    isoCode: 'KE',
    currency: 'KES',
    countryCode: '+254',
    value: '+254',
    supportedTelcos: [
      {
        name: 'Safaricom',
        expression:
          /^\+?254((7((0|1|2|4|9)\d{7}|(57|58|59|68|69)\d{6}))|(((?:(0|1))1|(0-5))\d{8})|(11((0|1|2|3|5|4)\d{6})))$/,
      },
    ],
  },
  {
    label: 'Tanzania',
    isoCode: 'TZ',
    currency: 'TZS',
    countryCode: '+255',
    value: '+255',
    supportedTelcos: [
      { name: 'Vodacom', expression: /^\+?255(74|75|76)[0-9]{7}/ },
      { name: 'Airtel', expression: /^\+?255(68|69|78)[0-9]{7}/ },
    ],
  },
  {
    label: 'Rwanda',
    isoCode: 'RW',
    currency: 'RWF',
    countryCode: '+250',
    value: '+250',
    supportedTelcos: [{ name: 'MTN', expression: /^\+?250(78|79)[0-9]{7}/ }],
  },
  // {
  //   label: 'Cameroon',
  //   isoCode: 'cm',
  //   currency: 'XAF',
  //   countryCode: '+237',
  //   value: '+237',
  // },
  // {
  //   label: 'Ghana',
  //   isoCode: 'gh',
  //   currency: 'GHS',
  //   countryCode: '+233',
  //   value: '+233',
  // },
];

export const MERCHANT_COUNTRIES = [
  {
    label: 'Uganda',
    value: '+256',
    isoCode: 'ug',
  },
];

export const ACCEPTED_DOC_FILE_TYPES = ['application/pdf', 'image/png', 'image/jpeg'];
export const ACCEPTED_IMAGE_FILE_TYPES = ['image/png', 'image/jpeg'];

export const RENDER_DRAWER_CONTENT_DIRECTIVE_CONTENTS_NAMES_LIST = {
  SHARE: 'SHARE',
  CONTINUE_UNVERIFIED: 'CONTINUE_UNVERIFIED',
  UPLOAD_BUSINESS_DOCUMENTS: 'UPLOAD_BUSINESS_DOCUMENTS',
  UPDATE_BEYONIC_LINK: 'UPDATE_BEYONIC_LINK',
};

export const AMOUNT_ROUNDING_RULES = {
  // todo convert this to use currencies instead of names
  Uganda: {
    collection: {
      true: 'down',
      false: 'down',
    },
    payment: {
      true: 'up',
      false: 'down',
    },
    fee: {
      true: 'up',
      false: 'up',
    },
  },
  'Congo (the Democratic Republic of the)': {
    collection: {
      true: 'down',
      false: 'down',
    },
    payment: {
      true: 'up',
      false: 'down',
    },
    fee: {
      true: 'up',
      false: 'up',
    },
  },
  Rwanda: {
    collection: {
      true: 'down',
      false: 'down',
    },
    payment: {
      true: 'up',
      false: 'down',
    },
    fee: {
      true: 'up',
      false: 'up',
    },
  },
  Tanzania: {
    collection: {
      true: 'down',
      false: 'down',
    },
    payment: {
      true: 'up',
      false: 'down',
    },
    fee: {
      true: 'up',
      false: 'up',
    },
  },
  Kenya: {
    collection: {
      true: 'down',
      false: 'down',
    },
    payment: {
      true: 'down',
      false: 'down',
    },
    fee: {
      true: 'down',
      false: 'down',
    },
  },
};

// Delay in milliseconds (4seconds)
export const INTERCOM_DELAY = 4000;

export const CURRENT_HIGHLIGHTED_FEATURE = {
  key: 'bulk_payment',
  expiry_date: '2024-09-15', // YYYY-MM-DD
  route: '/payments/bulk',
  showOnLoginForNewUsers: true,
};
