import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BeyonicLinkService } from './beyonic-link.service';
import { Router } from '@angular/router';
import {
  createBeyonicLinkStart,
  getUserBeyonicLinksStart,
  getMoreUserBeyonicLinksStart,
  createBeyonicLinkSuccess,
  getUserBeyonicLinksSuccess,
  getMoreUserBeyonicLinksSuccess,
  createBeyonicLinkFailure,
  getUserBeyonicLinksFailure,
  getMoreUserBeyonicLinksFailure,
  processBeyonicLinkStart,
  processBeyonicLinkSuccessful,
  processBeyonicLinkFailure,
  updateBeyonicLinkStart,
  updateBeyonicLinkSuccess,
  updateBeyonicLinkFailure,
  getBeyonicLinkCollectionsFailure,
  getBeyonicLinkCollectionsStart,
  getBeyonicLinkCollectionsSuccess,
  getMoreBeyonicLinkCollectionsFailure,
  getMoreBeyonicLinkCollectionsStart,
  getMoreBeyonicLinkCollectionsSuccess,
} from './beyonic-link.actions';
import { BeyToastService } from 'src/app/modules/shared/services/bey-toast.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProcessBeyonicLinkRequestBody } from './beyonic-link.interfaces';

@Injectable()
export class BeyonicLinkEffects {
  constructor(
    private beyonicLinkService: BeyonicLinkService,
    private actions$: Actions,
    private dialog: MatDialog,
    private router: Router,
    private beyToastService: BeyToastService
  ) {}

  updateBeyonicLinkEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBeyonicLinkStart),
      switchMap(({ payload }) => {
        const { data, type } = payload;

        // Only update the state of the link (activate or deactivate)
        if (type === 'state') {
          return this.beyonicLinkService
            .updateBeyonicLink(data)
            .pipe(map((linkInfo) => updateBeyonicLinkSuccess({ payload: { data: linkInfo, type } })));
        } else {
          // Do a full update to the link info
          return this.beyonicLinkService.updateBeyonicLink(data).pipe(
            switchMap((linkInfo) => {
              // upload image if exist on the data supplied by the update
              if (data.photo_browse_file_name) {
                return this.beyonicLinkService.updateBeyonicLinkPhoto(linkInfo.id, data.photo_browse_file_name).pipe(
                  map(() => {
                    return updateBeyonicLinkSuccess({ payload: { data: linkInfo, type: 'full' } });
                  }),
                  catchError((e) => {
                    console.error(e);

                    return observableOf(updateBeyonicLinkFailure({ error: e.error }));
                  })
                );
              }

              // this will remove the image | special type that can be triggered by click remove photo on edit page
              if (type === 'photo') {
                return this.beyonicLinkService.removeBeyonicLinkPhoto(linkInfo.id).pipe(
                  map(() => {
                    return updateBeyonicLinkSuccess({ payload: { data: linkInfo, type: 'full' } });
                  }),
                  catchError((e) => {
                    console.error(e);

                    return observableOf(updateBeyonicLinkFailure({ error: e.error }));
                  })
                );
              }

              return observableOf(updateBeyonicLinkSuccess({ payload: { data: linkInfo, type: 'full' } }));
            })
          );
        }
      }),
      catchError((e) => {
        console.error(e);

        this.beyToastService.open('Something went wrong, please try again', 'error');
        return observableOf(updateBeyonicLinkFailure({ error: e.error }));
      })
    )
  );

  createBeyonicLinkEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createBeyonicLinkStart),
      switchMap(({ payload }) => {
        return this.beyonicLinkService.createBeyonicLink(payload).pipe(
          map((linkInfo) => {
            this.beyToastService.open('Link created successfully 🎉', 'blue');
            this.router.navigate([`links/${linkInfo.id}`]);
            return createBeyonicLinkSuccess(linkInfo);
          }),
          catchError((e) => {
            console.error(e);

            this.beyToastService.open(`${e.error[Object.keys(e.error)[0]]} (${[Object.keys(e.error)[0]]})`, 'error');
            return observableOf(createBeyonicLinkFailure({ error: e.error }));
          })
        );
      })
    )
  );

  getUserBeyonicLinksEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserBeyonicLinksStart),
      switchMap(({ payload }) =>
        this.beyonicLinkService.getUserBeyonicLinks(payload && payload['filters']).pipe(
          switchMap((response) =>
            this.beyonicLinkService
              .getUserBeyonicLinksSummary()
              .pipe(map((summary) => getUserBeyonicLinksSuccess({ payload: { ...response, summary } })))
          ),
          catchError((e) => {
            console.error(e);

            return observableOf(getUserBeyonicLinksFailure({ error: e.error }));
          })
        )
      )
    )
  );

  getMoreUserBeyonicLinksEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMoreUserBeyonicLinksStart),
      switchMap(({ payload: { params } }) =>
        this.beyonicLinkService.getMoreUserBeyonicLinks(params).pipe(
          map((response) => getMoreUserBeyonicLinksSuccess({ payload: response })),
          catchError((e) => {
            console.error(e);

            return observableOf(getMoreUserBeyonicLinksFailure({ error: e.error }));
          })
        )
      )
    )
  );

  getBeyonicLinkCollectionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBeyonicLinkCollectionsStart),
      switchMap(({ payload: { id } }) =>
        this.beyonicLinkService.getBeyonicLinkCollections(id).pipe(
          map((response) => getBeyonicLinkCollectionsSuccess({ payload: response })),
          catchError((e) => {
            console.error(e);

            return observableOf(getBeyonicLinkCollectionsFailure({ error: e.error }));
          })
        )
      )
    )
  );

  getMoreBeyonicLinkCollectionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMoreBeyonicLinkCollectionsStart),
      switchMap(({ payload: { id, params } }) =>
        this.beyonicLinkService.getMoreBeyonicLinkCollections(id, params).pipe(
          map((response) => getMoreBeyonicLinkCollectionsSuccess({ payload: response })),
          catchError((e) => {
            console.error(e);

            return observableOf(getMoreBeyonicLinkCollectionsFailure({ error: e.error }));
          })
        )
      )
    )
  );

  processBeyonicLinkStartEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processBeyonicLinkStart),
      switchMap(
        ({ body, link_id, short_name }: { link_id: string; body: ProcessBeyonicLinkRequestBody; short_name: string }) =>
          this.beyonicLinkService.processBeyonicLink(link_id, { ...body }).pipe(
            map((resp) => processBeyonicLinkSuccessful({ body: resp, short_name, link_id })),
            catchError((e) => {
              console.error(e);

              if (e?.error['recaptcha']) {
                this.beyToastService.open(
                  'Error validating Recaptcha please reload the page.',
                  'error',
                  'Reload Page',
                  () => window.location.reload(),
                  null,
                  true
                );
              }

              return observableOf(processBeyonicLinkFailure());
            })
          )
      )
    )
  );

  processBeyonicLinkSuccessfulEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(processBeyonicLinkSuccessful),
        tap(({ body, short_name, link_id }) => {
          this.dialog.closeAll();
          // this is temp
          // We will use a new endpoint to handle the repeat and retry
          this.router.navigate([`/pending/${short_name}`], {
            queryParams: { id: body?.['code'], type: 'bl', link_id },
          });
        })
      ),
    { dispatch: false }
  );

  updateBeyonicLinkSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateBeyonicLinkSuccess),
        tap(({ payload }) => {
          const { data, type } = payload;
          let message = 'Link updated 🎉';

          if (type === 'state') {
            message = `Link ${data['is_active'] === true ? 'restored 🎉' : 'closed'}`;
          } else if (type === 'full') {
            this.router.navigate([`/links/${data.id}`]);
          }

          this.beyToastService.open(message, 'blue');
          this.dialog.closeAll();
        })
      ),
    { dispatch: false }
  );
}
