<div class="w-full flex flex-col">
  <div class="flex justify-between">
    <div>
      <div data-test-id="header" class="font-bold mb-2 text-grey-600">Amount</div>
      <div data-test-id="amount" class="text-lg">
        {{
          unitAmount
            | applyRoundingRules : country : 'collection' : false
            | currency : currency + ' ' : 'symbol' : currencyPipeDigitalInfo
        }}
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <hr class="mb-5 mt-6" />
  <div class="flex justify-between items-center">
    <bey-display-total-amount [totalInfo]="totalInfo"></bey-display-total-amount>
    <div class="mt-6" *ngIf="isLoading"><spinner [diameter]="23"></spinner></div>
  </div>
</div>
<bey-field-error-message [errorMessage]="errorMessage" [displayError]="displayError"></bey-field-error-message>
