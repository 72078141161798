<div class="wrapper relative h-[90px] bg" [ngClass]="{ '!mx-0 !-mt-1': isPreview, 'lg:hidden': !forceMobileView }">
  <div class="text-white pl-8 pt-4" *ngIf="backButton" @fadeIn>
    <bey-back-arrow strokeFillColor="#ffff" (onClick)="onBack.emit()">
      <span class="pl-3">Back</span>
    </bey-back-arrow>
  </div>

  <div
    class="left-2/4 top-[55%] absolute -translate-x-1/2 text-white w-20 h-20 z-50 border-gray-100 border-[3px] bg-gradient-to-l from-blue-600 to-blue-400 rounded-full flex justify-center items-center font-medium text-[38px]">
    {{ name | nameInitials }}
  </div>
</div>

<div
  class="bg-blue-500 relative hidden lg:block h-[240px] wrapper"
  [ngClass]="{
    '!hidden': forceMobileView
  }">
  <div class="flex justify-between px-16 pt-10 items-center">
    <img src="assets/images/logo/full-white.svg" alt="Beyonic logo" class="w-[235px]" *ngIf="showLogo" />
    <div *ngIf="!!!(userDetails$ | async)" class="relative z-50 text-white text-xl py-2 leading-[30px] font-medium">
      Already have an account?
      <button (click)="navigate()" class="pl-1 pr-6 underline">Login</button>
    </div>
  </div>
  <svg
    class="top-40 -left-[10px] absolute x-translate-x-1/2"
    width="103"
    height="95"
    viewBox="0 0 103 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      width="80.1362"
      height="86.5029"
      rx="4"
      transform="matrix(0.236463 -0.97164 0.979529 0.201302 -0.703125 77.8027)"
      fill="#DFE7FC"
      fill-opacity="0.14" />
  </svg>
  <svg
    class="left-full -translate-x-48 top-1/4 absolute"
    width="162"
    height="149"
    viewBox="0 0 162 149"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      width="117.96"
      height="113.086"
      rx="4"
      transform="matrix(0.883372 -0.468673 0.531994 0.846748 -1.3125 54.2832)"
      fill="#A1D1F5"
      fill-opacity="0.1" />
  </svg>
</div>
