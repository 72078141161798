import { Component, OnDestroy, OnInit } from '@angular/core';
import { isMobile, loadFacebookSDKScript } from '../../../../../../../assets/utility';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectDrawerData } from 'src/app/ngrx/misc/misc.selectors';
import { MiscService } from 'src/app/ngrx/misc/misc.service';
import { copyText } from 'src/app/ngrx/misc/misc.actions';
import { CopyTextPayload } from 'src/app/ngrx/misc/misc.interface';
import { PreventDefaultEventDirective } from '../../../../directives/prevent-default-event.directive';
import { BeyCloseIconComponent } from '../../../icons/bey-close-icon/bey-close-icon.component';
import { NgClass, NgIf } from '@angular/common';
import { BeyButtonComponent } from '../../../bey-button/bey-button.component';
import { BeySnippetCodeComponent } from '../../../bey-snippet-code/bey-snippet-code.component';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { CopyBeyonicLinkEmbedButtonDialogContentComponent } from 'src/app/modules/beyonic-link/components/copy-beyonic-link-embed-button-dialog-content/copy-beyonic-link-embed-button-dialog-content.component';

@Component({
  selector: 'bey-share-drawer-content',
  templateUrl: './share-drawer-content.component.html',
  styleUrls: ['./share-drawer-content.component.scss'],
  standalone: true,
  imports: [
    BeyCloseIconComponent,
    PreventDefaultEventDirective,
    NgIf,
    NgClass,
    BeyButtonComponent,
    BeySnippetCodeComponent,
  ],
})
export class ShareDrawerContentComponent implements OnInit, OnDestroy {
  constructor(private store: Store, public miscService: MiscService, private dialog: MatLegacyDialog) {}

  /****
   * Twitter tweet
   */
  twitterContent: string;

  /****
   * Whatsapp content
   */
  whatsappContent: string;

  /****
   * SMS content
   */
  smsContent: string;

  /***
   * Phone number of the recipient
   */
  phone: string;

  /****
   * Message content for facebook, mail, etc
   */
  messageContent: string;

  /****
   * Subject for mails
   */
  subjectContent: string;

  /****
   * This prop accepts the link for the share
   */
  userLink: string;

  /****
   * Snippet attributes for CopySnippet component
   */
  snippet: string;

  /****
   * The USSD message to be copied
   */
  ussd: string;

  /****
   * Indicator to recognize this share drawer is opened for static link share
   */
  isStatic: boolean;

  subs$: Subscription = new Subscription();

  ngOnInit(): void {
    // load FB SDK THEN USE
    loadFacebookSDKScript();

    this.subs$.add(
      this.store.select(selectDrawerData).subscribe((data) => {
        if (data) {
          // todo update this with something more readable and maintainable
          Object.keys(data).forEach((key) => (this[key] = data[key]));
        }
      })
    );
  }

  get whatsAppLink() {
    return `https://api.whatsapp.com/send?text=${this.whatsappContent}`;
  }

  get mailLink() {
    return `mailto://?subject=${this.subjectContent || ''}&body=${this.messageContent}`;
  }

  async copyLink(e) {
    e.preventDefault();

    let payload: CopyTextPayload = {
      toBeCopied: this.userLink,
      successMessage: 'Copied',
      failureMessage: 'Error copy the link. Please refresh the page and try again',
    };
    this.store.dispatch(copyText({ payload }));
  }

  async copyUSSD(e) {
    e.preventDefault();

    let payload: CopyTextPayload = {
      toBeCopied: this.ussd,
      successMessage: 'Copied',
      failureMessage: 'Error copy the USSD. Please refresh the page and try again',
    };
    this.store.dispatch(copyText({ payload }));
  }

  handleFb() {
    if (isMobile()) {
      window.open(`fb-messenger://share?link=${this.userLink}`);
    } else {
      window.FB.ui({
        method: 'send',
        link: this.userLink,
      });
    }
  }

  get sms() {
    return `sms:&body=${this.smsContent}`;
  }

  async twitterHandler() {
    window.open(this.twitterContent);
  }

  onEmbedButtonClick(e: Event): void {
    e.preventDefault();

    this.dialog.open(CopyBeyonicLinkEmbedButtonDialogContentComponent, {
      data: { snippet: this.snippet },
      maxWidth: '600px',
      width: '90vw',
    });
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
