<div class="flex flex-col items-center">
  <span data-test-id="title" class="font-bold text-grey-900 text-base text-center my-3">
    You cannot reset your PIN at this time due to multiple incorrect attempts.
  </span>
  <span data-test-id="content" class="font-normal text-grey-600 text-base text-center mb-9"
    >Please contact support for assistance.
  </span>
  <bey-button
    data-test-id="back-to-login-button"
    class="w-full"
    classList="w-full"
    placeholder="Back to login"
    color="dark-blue"
    (onClick)="onButtonClick()"></bey-button>
</div>
