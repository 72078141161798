import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'bey-button',
  templateUrl: './bey-button.component.html',
  styleUrls: ['./bey-button.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, SpinnerComponent],
})

/***
 *  Beyonic Button used across the application
 */
export class BeyButtonComponent implements OnInit, OnDestroy {
  /***
   *  The content of textual the button
   */
  @Input()
  placeholder: string;

  /***
   *  If the button has type of submit for forms or not
   */
  @Input()
  submit: boolean;

  /***
   *  Button color variants
   */
  @Input()
  color:
    | 'primary'
    | 'secondary'
    | 'orange'
    | 'orange-outlined'
    | 'blue'
    | 'sky-blue'
    | 'light-blue'
    | 'dark-blue'
    | 'dark-blue-outlined'
    | 'red'
    | 'red-outlined'
    | 'grey-outlined'
    | 'grey' = 'blue';

  /***
   *  Disable the button from use
   */
  @Input()
  disabled: boolean = false;

  /***
   *  Button size is small
   */
  @Input()
  small: boolean = false;

  /***
   *  Button content will be replaced with spinner, and it gets disabled when an action is initiated, and it's waiting for response
   */
  @Input()
  isLoading?: boolean;

  /****
   * Additional CSS classes can be overloaded
   */
  @Input()
  classList?: string = '';

  /****
   * CSS ID attribute
   */
  @Input()
  id?: string = '';

  /****
   * Event emitted by the button when clicked
   */
  @Output()
  onClick: EventEmitter<any> = new EventEmitter<Event>();

  /******
   * Button shadow
   */
  @Input()
  shadow: boolean = false;

  public buttonClicked: boolean = false;
  private timeoutId;

  constructor() {}

  ngOnInit(): void {}

  clickHandler(e: Event) {
    if (this.buttonClicked) {
      e.stopPropagation();
      return;
    }

    this.onClick.emit(e);
    this.buttonClicked = true;
    this.timeoutId = setTimeout(() => {
      this.buttonClicked = false;
    }, 900);
  }

  public get getClasses(): string {
    const colorBasedClasses = {
      primary: 'primary',
      secondary: 'secondary',
      'orange-outlined': 'orange-outlined',
      orange: 'bg-orange-600 hover:bg-orange-700 active:bg-orange-900 disabled:bg-orange-200 text-white',
      blue: 'bg-blue-600 text-white hover:bg-blue-700 active:bg-blue-800 disabled:bg-grey-200 ',
      'dark-blue':
        'bg-blue-400 hover:bg-blue-700 active:bg-blue-800 text-white disabled:bg-grey-300 disabled:text-white',
      'dark-blue-outlined': 'bg-white text-blue-400 border border-blue-400',
      grey: 'text-black bg-grey-100 hover:bg-grey-200 active:bg-grey-300 disabled:bg-grey-100',
      'grey-outlined': 'text-grey-400 bg-white border border-solid border-grey-400',
      red: 'bg-bey-red hover:bg-[#B0092A] active:bg-[#880821] disabled:bg-[#F09EAE] ',
      'red-outlined': 'bg-white text-bey-red border border-bey-red ',
      'sky-blue': 'bg-blue-sky text-black disabled:bg-grey-200 disabled:text-white',
      'light-blue': 'bg-blue-light text-white disabled:bg-grey-200',
    };
    let className: string;

    className = this.small ? 'small ' : '';
    className += colorBasedClasses[this.color];

    return `flex items-center min-h-[34px] disabled:opacity-75 flex align-center justify-center outline-none px-5 py-1 rounded cursor-pointer font-normal text-base leading-5 ${this.classList} ${className} `;
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }
}
