import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../../environments/environment';
import { effects, reducers } from './reducers-and-effects';
import { AuthState } from './auth/auth.interfaces';
import { UserState } from './user/user.interfaces';
import { collectionsState } from './collections/collections.interfaces';
import { WalletsState } from './wallets/wallets.interfaces';
import { PaymentState } from './payments/payments.interfaces';
import { ContactState } from './contacts/contacts.interface';
import { MiscState } from './misc/misc.interface';
import { KycStateInterface } from './KYC/kycStateInterface';
import { BeyonicLinkState } from './beyonic-link/beyonic-link.interfaces';
import { BulkPaymentsState } from './bulk-payments/bulk-payments.interfaces';

export interface AppState {
  auth: AuthState;
  user: UserState;
  collections: collectionsState;
  beyonicLink: BeyonicLinkState;
  wallets: WalletsState;
  kyc: KycStateInterface;
  payments: PaymentState;
  contacts: ContactState;
  misc: MiscState;
  bulkPayments: BulkPaymentsState;
}

function localStorageSyncReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  const config = {
    keys: [
      { auth: ['tokens', 'phone', 'lockedAccountTimeStamp'] },
      { user: ['savedUser', 'userDetails', 'userBusinessDetails', 'isSuspended'] },
      { collections: ['userCollections', 'topUpUser', 'collectionsFees'] },
      { payments: ['liquidateUser', 'recentPayments', 'paymentsFees'] },
      { personal: ['personalDetails'] },
      { wallets: ['walletLimitsShownAlert', 'userWallet'] },
    ],
    rehydrate: true,
  };

  // avoid mixing persistent states
  if (environment.is_pay) {
    config['storageKeySerializer'] = (key) => `beyonic_pay_${key}`;
  }

  return localStorageSync(config)(reducer);
}

const metaReducers: Array<MetaReducer<AppState, any>> = [localStorageSyncReducer];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, { metaReducers: metaReducers }),
    EffectsModule.forRoot(effects),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  declarations: [],
})
export class RootStoreModule {}
