import { Component } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { CURRENT_HIGHLIGHTED_FEATURE } from 'src/assets/const';
import { BeyButtonComponent } from '../bey-button/bey-button.component';
import { BeyChevronComponent } from '../icons/bey-chevron/bey-chevron.component';

@Component({
  selector: 'app-highlighted-feature-dialog-content',
  templateUrl: './highlighted-feature-dialog-content.component.html',
  styleUrls: ['./highlighted-feature-dialog-content.component.scss'],
  standalone: true,
  imports: [BeyChevronComponent, BeyButtonComponent],
})
export class HighlightedFeatureDialogContentComponent {
  constructor(private dialog: MatLegacyDialog) {}

  onButtonClick(): void {
    this.dialog.closeAll();
    localStorage.setItem(CURRENT_HIGHLIGHTED_FEATURE.key, 'seen');
  }
}
