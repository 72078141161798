<div class="px-4 py-10 flex justify-center flex-col text-center">
  <div class="text-grey-800 text-2xl font-medium leading-[28px]">Bank payment initiated</div>

  <svg
    width="111"
    height="109"
    viewBox="0 0 111 109"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="mx-auto my-6">
    <circle cx="46.6583" cy="62.3416" r="46.6583" fill="#99DBFF" />
    <g clip-path="url(#clip0_1355_10956)">
      <path
        d="M92.9633 24.0107C92.6262 23.6787 92.124 23.5794 91.6861 23.7582L25.0437 50.9812C24.6143 51.1567 24.3273 51.5668 24.3095 52.0305C24.2917 52.4941 24.5465 52.9251 24.9614 53.133L41.7654 61.5508L46.1805 73.3516C46.3539 73.8151 46.7964 74.1197 47.2876 74.1197C47.3052 74.1197 47.323 74.1194 47.3407 74.1186C47.6186 74.1062 47.873 73.9949 48.071 73.8189L48.0731 73.8213L54.6293 67.9953L71.7644 76.5785C71.9306 76.6618 72.112 76.7037 72.294 76.7037C72.4374 76.7037 72.581 76.6775 72.7177 76.625C73.028 76.506 73.2737 76.2618 73.3948 75.9523L93.2345 25.2838C93.4067 24.8433 93.3002 24.3426 92.9633 24.0107ZM83.6563 29.5929L61.1206 46.0918L34.5054 49.6705L83.6563 29.5929ZM76.3484 37.8739L63.7206 49.5813L62.9975 47.6485L76.3484 37.8739ZM29.4496 52.7365L57.1281 49.0146L42.8652 59.4569L29.4496 52.7365ZM48.5529 63.8853L47.1163 69.1045L44.2295 61.3891L61.0156 49.0994L61.8461 51.3192L48.889 63.332C48.7277 63.4816 48.6111 63.6732 48.5529 63.8853ZM49.4873 69.4004L50.4504 65.9016L52.3525 66.8542L49.4873 69.4004ZM51.7474 63.9066L65.3295 51.3145L70.5581 73.3292L51.7474 63.9066ZM72.5709 71.5707L67.3214 49.4678L89.1494 29.2306L72.5709 71.5707Z"
        fill="#006FAB" />
      <circle cx="89.0024" cy="57.6367" r="2.7446" fill="#31B1F7" />
      <circle cx="38.8149" cy="31.759" r="2.7446" fill="#31B1F7" />
    </g>
    <line
      x1="36.8652"
      y1="74.2651"
      x2="23.2933"
      y2="87.8371"
      stroke="#006FAB"
      stroke-width="3"
      stroke-linecap="round" />
    <line
      x1="25.8867"
      y1="63.2868"
      x2="21.7347"
      y2="67.4389"
      stroke="#006FAB"
      stroke-width="3"
      stroke-linecap="round" />
    <line
      x1="59.8262"
      y1="82.3398"
      x2="57.2425"
      y2="84.9235"
      stroke="#006FAB"
      stroke-width="3"
      stroke-linecap="round" />
    <line
      x1="51.7559"
      y1="89.9487"
      x2="49.1721"
      y2="92.5324"
      stroke="#006FAB"
      stroke-width="3"
      stroke-linecap="round" />
    <line
      x1="18.0352"
      y1="93.0854"
      x2="15.4514"
      y2="95.6691"
      stroke="#006FAB"
      stroke-width="3"
      stroke-linecap="round" />
    <circle cx="106.647" cy="31.3669" r="3.92086" fill="#CAECFF" />
    <circle cx="86.2588" cy="2.35252" r="2.35252" fill="#CAECFF" />
    <defs>
      <clipPath id="clip0_1355_10956">
        <rect width="69.0072" height="69.0072" fill="white" transform="translate(24.3086 15.6833)" />
      </clipPath>
    </defs>
  </svg>

  <div class="text-grey-800 leading-[19px] mb-14">
    We have received the bank payment details and have sent the request to the bank.
  </div>

  <bey-button (onClick)="close()" placeholder="Got it" color="dark-blue" classList="px-10 mx-auto"></bey-button>
</div>
