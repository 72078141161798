<div class="flex flex-col w-full px-7 pt-5 pb-8 lg:p-0">
  <span class="text-grey-800 text-2xl font-bold text-center">Get started now.</span>
  <hr class="my-3" />
  <div class="flex mb-6">
    <bey-numbered-step-circle [stepNumber]="1" class="mr-3"></bey-numbered-step-circle>
    <span class="text-grey-800 text-sm"
      >You will have a daily limit<ng-container *ngIf="isUganda"> of 500,000 UGX</ng-container>.</span
    >
  </div>
  <div class="flex">
    <bey-numbered-step-circle [stepNumber]="2" class="mr-3"></bey-numbered-step-circle>
    <span class="text-grey-800 text-sm"
      >Your Beyonic link will display your personal name instead of your business name.</span
    >
  </div>
  <span class="my-7 text-grey-800 text-sm">Get verified from your profile later to avoid these limits.</span>
  <div class="flex flex-col items-center w-full">
    <bey-button
      color="dark-blue"
      placeholder="Proceed"
      classList="w-full !py-[10px]"
      class="w-full mb-4"
      [isLoading]="businessProfileIsLoading$ | async"
      (onClick)="continueUnverified()"></bey-button>
    <bey-button
      classList="w-full !py-[10px]"
      color="dark-blue-outlined"
      placeholder="Cancel"
      class="w-full"
      [disabled]="businessProfileIsLoading$ | async"
      (onClick)="miscService.closeDrawer()"></bey-button>
  </div>
</div>
