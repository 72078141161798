<!--todo fix the background color on desktop-->
<div class="w-full z-10 bg-grey-100 relative hidden lg:block h-full">
  <div class="sticky top-10 w-10/12 mx-auto mt-8x mb-10" *ngIf="sidebarIsOpen$ | async">
    <div class="overflow-auto md:max-h-[90vh]">
      <div class="w-full flex" [ngClass]="(header$ | async) ? 'justify-between' : 'justify-end'">
        <div class="font-bold text-2xl" *ngIf="header$ | async">{{ header$ | async }}</div>
        <bey-close-icon (onClick)="toggle()" classList="mb-6 cursor-pointer"></bey-close-icon>
      </div>

      <ng-container *ngIf="hasContentProjection$ | async; else other">
        <ng-content></ng-content>
      </ng-container>

      <ng-template #other>
        <bey-transfer-info *ngIf="payload$ | async" [transaction]="payload$ | async"></bey-transfer-info>
      </ng-template>
    </div>
  </div>
</div>
