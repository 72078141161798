import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, EMPTY, forkJoin, map, Observable } from 'rxjs';
import { BEYONIC_COUNTRIES } from 'src/assets/const';
import { CollectionsService } from '../../../ngrx/collections/collections.service';
import { BeyToastService } from '../../shared/services/bey-toast.service';

@Injectable({
  providedIn: 'root',
})
export class UniqueLinkActionsResolver implements Resolve<any> {
  constructor(private collectionsService: CollectionsService, private router: Router, private toast: BeyToastService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const id = route.paramMap.get('id');
    const toResolve = [];

    id && toResolve.push(this.collectionsService.getUniqueLinkInfoForAuthUser(id));

    return forkJoin(toResolve).pipe(
      map((info) => {
        let amount_currency = BEYONIC_COUNTRIES.find(
          (country) => country.name === info[0]?.['business']?.['country']
        ).currency;

        return [{ ...info[0], amount_currency }];
      }),
      catchError((err) => {
        this.toast.open(err?.error?.detail, 'error');
        this.router.navigate(['/error']);

        return EMPTY;
      })
    );
  }
}
