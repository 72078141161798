import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { routeTransitionAnimations } from '../../../../../assets/utility/route-transition-animations';
import { Store } from '@ngrx/store';
import { selectSavedUser, selectUserDetails } from '../../../../ngrx/user/user.selectors';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { getCountryDocuments, getUserBusinessDetailsStart, getUserKybStart } from '../../../../ngrx/user/user.actions';
import { getCollectionsFeesStart } from '../../../../ngrx/collections/collections.actions';
import { getBankPaymentsFeesStart, getPaymentsFeesStart } from '../../../../ngrx/payments/payments.actions';
import { selectPendingKyc, selectUserKycDetails } from 'src/app/ngrx/KYC/kyc.selectors';
import { selectCurrentRoute } from 'src/app/ngrx/auth/auth.selectors';
import {
  selectAnimatedSidebarIsOpen,
  selectAnimatedSidebarIsOver,
  selectSnackbarIsOpen,
  selectTargetedRoutesToShowPendingKycBanner,
} from 'src/app/ngrx/misc/misc.selectors';
import { ProfileInterface, User } from '../../../../ngrx/user/user.interfaces';
import { TemplatePortal, PortalModule } from '@angular/cdk/portal';
import { PortalService } from '../../../shared/services/portal.service';
import { RenderDrawerContentDirective } from '../../../shared/directives/render-drawer-content.directive';
import { BeyDrawerComponent } from '../../../shared/components/bey-drawer/bey-drawer.component';
import { BeySnackbarComponent } from '../../../shared/components/bey-snackbar/bey-snackbar.component';
import { RootFooterComponent } from '../../components/root-footer/root-footer.component';
import { BeyAnimatedSidebarDrawerComponent } from '../../../shared/components/bey-animated-sidebar-drawer/bey-animated-sidebar-drawer.component';
import { PendingKycBannerComponent } from '../../components/pending-kyc-banner/pending-kyc-banner.component';
import { RootSidebarComponent } from '../../components/root sidebar/root-sidebar.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { RootHeaderComponent } from '../../components/root-header/root-header.component';
import { RouterService } from '../../services/router.service';
import { MiscService } from 'src/app/ngrx/misc/misc.service';
import { CURRENT_HIGHLIGHTED_FEATURE } from 'src/assets/const';

@Component({
  selector: 'app-root-core',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  animations: [routeTransitionAnimations],
  standalone: true,
  imports: [
    RootHeaderComponent,
    NgClass,
    RootSidebarComponent,
    PendingKycBannerComponent,
    RouterOutlet,
    BeyAnimatedSidebarDrawerComponent,
    PortalModule,
    RootFooterComponent,
    BeySnackbarComponent,
    BeyDrawerComponent,
    RenderDrawerContentDirective,
    AsyncPipe,
  ],
})
export class RootComponent implements OnInit, OnDestroy {
  pendingKyc$: Observable<boolean>;
  currentRoute$: Observable<string>;
  targetedRoutesToShowPendingKycBanner$: Observable<Array<string>>;
  snackbarIsShown$: Observable<boolean>;
  userKyc$: Observable<ProfileInterface>;
  subs$: Subscription = new Subscription();
  selectedPortal$: Observable<TemplatePortal>;
  sidebarIsOpen$: Observable<boolean>;
  sidebarIsOver$: Observable<boolean>;
  animation$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  currentUser: User;
  footerHidden: boolean = false;

  constructor(
    private store: Store,
    private portalService: PortalService,
    private routerService: RouterService,
    private miscService: MiscService
  ) {
    this.pendingKyc$ = store.select(selectPendingKyc);
    this.currentRoute$ = store.select(selectCurrentRoute);
    this.targetedRoutesToShowPendingKycBanner$ = store.select(selectTargetedRoutesToShowPendingKycBanner);
    this.snackbarIsShown$ = store.select(selectSnackbarIsOpen);
    this.userKyc$ = this.store.select(selectUserKycDetails);

    this.subs$.add(
      store.select(selectUserDetails).subscribe((currentUser) => {
        this.currentUser = currentUser;

        if (currentUser) {
          store.dispatch(getCountryDocuments());
          store.dispatch(getUserKybStart());
          // todo move the fees to somewhere that is refreshed more often instead in order to get fresh values
          store.dispatch(getPaymentsFeesStart());
          store.dispatch(getBankPaymentsFeesStart());
          store.dispatch(getCollectionsFeesStart(null));

          this.subs$.add(
            combineLatest([this.store.select(selectCurrentRoute), this.store.select(selectSavedUser)]).subscribe(
              ([route, savedUser]) => {
                if (
                  // we display it for the new users after login
                  // and for previous users in the specific route for the highlighted feature
                  (CURRENT_HIGHLIGHTED_FEATURE.showOnLoginForNewUsers && !savedUser.samePreviousSavedUser) ||
                  route.includes(CURRENT_HIGHLIGHTED_FEATURE.route)
                ) {
                  this.miscService.displayHighlightedFeature();
                }
              }
            )
          );
        } else {
          store.dispatch(
            getCollectionsFeesStart({
              payload: {
                useOpenEndpoint: true,
              },
            })
          );
        }

        if (currentUser?.business_id) {
          this.store.dispatch(getUserBusinessDetailsStart({ payload: { id: currentUser.business_id } }));
        }
      })
    );

    this.subs$.add(
      this.userKyc$.subscribe((kyc) => {
        // hide footer if user doesn't have KYC info or is not loggedIn (listens to current user)
        this.footerHidden = !this.currentUser || !kyc?.first_name;
      })
    );
  }

  ngOnInit(): void {
    this.selectedPortal$ = this.portalService.portal$;
    this.sidebarIsOpen$ = this.store.select(selectAnimatedSidebarIsOpen);
    this.sidebarIsOver$ = this.store.select(selectAnimatedSidebarIsOver);

    this.subs$.add(
      this.routerService.routeData$.subscribe((value) => {
        this.animation$.next(value['animation']);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs$.unsubscribe();
  }
}
