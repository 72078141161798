<button
  [id]="id"
  (click)="clickHandler($event)"
  [disabled]="disabled || isLoading"
  [ngClass]="getClasses"
  [type]="submit ? 'submit' : 'button'">
  <ng-content select="[iconBefore]"></ng-content>
  <spinner *ngIf="isLoading; else content" [diameter]="28"></spinner>
  <ng-template #content
    ><span class="text-base md:text-lg">{{ placeholder }}</span>
    <ng-content select="[placeholder]"></ng-content>
  </ng-template>
  <ng-content select="[iconAfter]"></ng-content>
</button>
