import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RootComponent } from './modules/core/pages/root/root.component';
import { HomePage } from './modules/home/pages/home/home.page';
import { ErrorPage } from './modules/core/pages/error/error.page';
import { StaticLinkPage } from './modules/core/pages/static-link/static-link.page';
import { ShortNameResolver } from './modules/shared/resolvers/short-name.resolver';
import { UniqueLinkResolverResolver } from './modules/collections-link/resolvers/unique-link-resolver.resolver';
import { UniqueLinkInteractionComponent } from './modules/core/pages/unique-link-interaction/unique-link-interaction.component';
import { UniqueLinkExpiredComponent } from './modules/core/pages/unique-link-exipred/unique-link-expired.component';
import { WelcomeComponent } from './modules/core/pages/welcome/welcome.component';
import { UserAccountStateGuard } from './modules/shared/guards/userAccountState.guard';
import { DemoComponent } from './modules/core/pages/demo/demo.component';
import { UserSuspensionComponent } from './modules/core/pages/user-suspension/user-suspension.component';
import { HasKycGuard } from './modules/home/guards/has-kyc.guard';
import { CanTransactGuard } from './modules/home/guards/can-transact.guard';
import { UserAuthGuard } from './modules/home/guards/user-auth.guard';
import { PendingPage } from './modules/core/pages/pending/pending.page';
import { SuccessfulLinkComponent } from './modules/core/pages/successful-link/successful-link.component';
import { BeyLinkInteractionPage } from './modules/core/pages/bey-link-interaction/bey-link-interaction.page';
import { BeyonicLinkResolver } from './modules/shared/resolvers/beyonic-link.resolver';
import { BeyonicLinkOpenResolver } from './modules/shared/resolvers/beyonic-link-open.resolver';
import { FailedCollectionPage } from './modules/core/pages/failed-collection/failed-collection.page';
import { PromptCollectionInfoResolver } from './modules/shared/resolvers/prompt-collection-info.resolver';
import { PreviewComponent } from './modules/beyonic-link/pages/preview/preview.component';
import { SuccessfulTransactionResolver } from './modules/core/resolvers/successful-transaction.resolver';
import { LinkClosedComponent } from './modules/shared/pages/link-closed/link-closed.component';
import { environment } from '../environments/environment';
import { BeyOnboardComponent } from './modules/core/components/bey-onboarding/bey-onboard.component';

let routes: Routes = [];

// Routes specific for Beyonic pay service (https://pay.beyonic.com) and (https://testpay.beyonic.com)
if (environment.is_pay) {
  routes = [
    {
      path: '',
      component: RootComponent,
      children: [
        {
          path: '',
          component: BeyOnboardComponent,
        },
        // pending Beyonic Links Collection
        {
          path: 'pending/:short_name',
          component: PendingPage,
          data: {
            animation: 'FadeIn',
          },
        },
        {
          // type => collections || payments
          path: 'successful/:type/:short_name',
          component: SuccessfulLinkComponent,
          resolve: {
            transactionData: SuccessfulTransactionResolver,
          },
          data: {
            animation: 'FadeIn',
            background: '#F9F9F9',
          },
        },
        {
          // types => c || p => collections or payments
          path: 's/:type/:code',
          component: SuccessfulLinkComponent,
          resolve: {
            transactionData: SuccessfulTransactionResolver,
          },
          data: {
            animation: 'FadeIn',
            background: '#F9F9F9',
          },
        },
        {
          path: 'failed/:short_name',
          component: FailedCollectionPage,
          data: {
            animation: 'FadeOut',
          },
        },
        {
          path: 'expired/:short_name',
          component: UniqueLinkExpiredComponent,
          resolve: {
            businessInfo: ShortNameResolver,
          },
        },
        {
          path: 'closed',
          component: LinkClosedComponent,
        },
        {
          path: 'closed/:short_name',
          component: LinkClosedComponent,
          resolve: {
            businessInfo: ShortNameResolver,
          },
        },
        {
          path: ':short_name/:link_id',
          component: BeyLinkInteractionPage,
          resolve: {
            businessInfo: ShortNameResolver,
            linkData: BeyonicLinkOpenResolver,
          },
          data: {
            animation: 'FadeIn',
            background: '#F9F9F9',
          },
        },
        {
          path: 'error',
          component: ErrorPage,
        },
        {
          path: ':id',
          component: StaticLinkPage,
          resolve: {
            failedCollectionInfo: PromptCollectionInfoResolver,
            businessInfo: ShortNameResolver,
          },
          data: {
            animation: 'FadeIn',
            background: '#F9F9F9',
          },
        },
        {
          path: 'sl/:short_name/:link_id',
          component: UniqueLinkInteractionComponent,
          resolve: { linkData: UniqueLinkResolverResolver },
          data: {
            animation: 'FadeIn',
          },
        },
        {
          path: '**',
          redirectTo: 'error',
        },
      ],
    },
  ];
} else {
  routes = [
    {
      path: 'registration',
      canLoad: [UserAuthGuard],
      loadChildren: () =>
        import('./modules/registration/registration.module').then((module) => module.RegistrationModule),
    },
    {
      path: '',
      component: RootComponent,
      children: [
        {
          path: '',
          component: HomePage,
          canActivate: [UserAccountStateGuard, HasKycGuard],
          data: {
            background: '#F9F9F9',
          },
        },
        {
          path: 'payments/bulk',
          canLoad: [UserAuthGuard, CanTransactGuard],
          canActivateChild: [HasKycGuard],
          loadChildren: () =>
            import('./modules/bulk-payments/bulk-payments.module').then((module) => module.BulkPaymentsModule),
        },
        {
          path: 'links',
          canLoad: [UserAuthGuard, CanTransactGuard],
          canActivateChild: [HasKycGuard],
          loadChildren: () =>
            import('./modules/beyonic-link/beyonic-link.module').then((module) => module.BeyonicLinkModule),
        },
        {
          path: 'payments',
          canLoad: [UserAuthGuard, CanTransactGuard],
          canActivateChild: [HasKycGuard],
          loadChildren: () => import('./modules/payments/payments.module').then((module) => module.PaymentsModule),
        },
        {
          path: 'business-documents',
          canLoad: [UserAuthGuard],
          canActivateChild: [HasKycGuard],
          loadChildren: () =>
            import('./modules/business-documents/business-documents.module').then(
              (module) => module.BusinessDocumentsModule
            ),
        },
        {
          path: 'collections',
          canLoad: [UserAuthGuard, CanTransactGuard],
          canActivateChild: [HasKycGuard],
          loadChildren: () =>
            import('./modules/collections/collections.module').then((module) => module.CollectionsModule),
        },
        {
          path: 'profile',
          canLoad: [UserAuthGuard],
          canActivateChild: [HasKycGuard],
          loadChildren: () => import('./modules/profile/profile.module').then((module) => module.ProfileModule),
        },
        {
          path: 'billing',
          canLoad: [UserAuthGuard],
          canActivateChild: [HasKycGuard],
          loadChildren: () => import('./modules/billing/billing.module').then((m) => m.BillingModule),
        },
        {
          path: 'collections-link',
          canLoad: [UserAuthGuard, CanTransactGuard],
          canActivateChild: [HasKycGuard],
          loadChildren: () =>
            import('./modules/collections-link/collections-link.module').then((module) => module.CollectionsLinkModule),
        },
        {
          path: 'top-up',
          canLoad: [UserAuthGuard, CanTransactGuard],
          canActivateChild: [HasKycGuard],
          loadChildren: () => import('./modules/top-up/top-up.module').then((m) => m.TopUpModule),
        },

        {
          path: 'suspended',
          component: UserSuspensionComponent,
        },

        {
          path: 'withdraw',
          canLoad: [UserAuthGuard, CanTransactGuard],
          canActivateChild: [HasKycGuard],
          loadChildren: () => import('./modules/liquidation/liquidation.module').then((m) => m.LiquidationModule),
        },
        {
          path: 'bank-payments',
          canLoad: [UserAuthGuard, CanTransactGuard],
          canActivateChild: [HasKycGuard],
          loadChildren: () =>
            import('./modules/bank-payments/bank-payments.module').then((module) => module.BankPaymentsModule),
        },
        {
          path: 'phone-book',
          canLoad: [UserAuthGuard],
          canActivateChild: [HasKycGuard],
          loadChildren: () => import('./modules/phone-book/phone-book.module').then((module) => module.PhoneBookModule),
        },
        {
          path: 'transactions',
          canLoad: [UserAuthGuard],
          canActivateChild: [HasKycGuard],
          loadChildren: () => import('./modules/transactions/transactions.module').then((m) => m.TransactionsModule),
        },
        {
          path: 'payments/bulk',
          canLoad: [UserAuthGuard, CanTransactGuard],
          canActivateChild: [HasKycGuard],
          loadChildren: () =>
            import('./modules/bulk-payments/bulk-payments.module').then((module) => module.BulkPaymentsModule),
        },
      ],
    },
    // Routes that use root layout but doesn't require guard
    {
      path: '',
      component: RootComponent,
      children: [
        // pending Beyonic Links Collection
        {
          path: 'pending/:short_name',
          component: PendingPage,
          data: {
            animation: 'FadeIn',
          },
        },
        {
          // type => collections || payments
          path: 'successful/:type/:short_name',
          component: SuccessfulLinkComponent,
          resolve: {
            transactionData: SuccessfulTransactionResolver,
          },
          data: {
            animation: 'FadeIn',
            background: '#F9F9F9',
          },
        },
        {
          path: 'failed/:short_name',
          component: FailedCollectionPage,
          data: {
            animation: 'FadeOut',
          },
        },
        {
          path: 'sl/demo/Beyonic',
          component: DemoComponent,
        },
        {
          path: 'expired/:short_name',
          component: UniqueLinkExpiredComponent,
          resolve: {
            businessInfo: ShortNameResolver,
          },
        },
        {
          path: 'closed',
          component: LinkClosedComponent,
        },
        {
          path: 'closed/:short_name',
          component: LinkClosedComponent,
          resolve: {
            businessInfo: ShortNameResolver,
          },
        },
        {
          path: 'sl/:id',
          component: StaticLinkPage,
          resolve: {
            failedCollectionInfo: PromptCollectionInfoResolver,
            businessInfo: ShortNameResolver,
          },
          data: {
            animation: 'FadeIn',
            background: '#F9F9F9',
          },
        },
        {
          path: 'sl/:short_name/:link_id',
          component: UniqueLinkInteractionComponent,
          resolve: { linkData: UniqueLinkResolverResolver },
          data: {
            animation: 'FadeIn',
          },
        },
        {
          path: 'bl/:short_name/:link_id',
          component: BeyLinkInteractionPage,
          resolve: {
            businessInfo: ShortNameResolver,
            linkData: BeyonicLinkOpenResolver,
          },
          data: {
            animation: 'FadeIn',
            background: '#F9F9F9',
          },
        },
        {
          path: 'error',
          component: ErrorPage,
        },
      ],
    },
    // Routes that use root layout but doesn't require guard
    {
      path: '',
      loadChildren: () => import('./modules/auth/auth.module').then((module) => module.AuthModule),
    },
    {
      path: '',
      loadChildren: () => import('./modules/print/print.module').then((module) => module.PrintModule),
    },
    {
      path: 'welcome',
      canActivateChild: [UserAccountStateGuard],
      children: [
        {
          path: '',
          component: WelcomeComponent,
          data: {
            animation: 'fadeIn',
          },
        },
      ],
    },
    {
      component: PreviewComponent,
      path: 'bl/preview/:short_name/:link_id',
      resolve: {
        businessInfo: ShortNameResolver,
        linkData: BeyonicLinkResolver,
      },
      data: {
        animation: 'FadeIn',
        isPreview: true,
        footerHidden: true,
      },
    },
    {
      path: '**',
      redirectTo: 'error',
    },
  ];
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
