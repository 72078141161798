<div class="flex flex-col py-4 items-center text-center">
  <svg
    *ngIf="data?.['success']; else failedIcon"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.4508 21.0004C39.4508 31.1901 31.1904 39.4504 21.0008 39.4504C10.8111 39.4504 2.55078 31.1901 2.55078 21.0004C2.55078 10.8108 10.8111 2.55036 21.0008 2.55036C24.1535 2.5421 27.2545 3.35181 30.0008 4.90042M14.4508 19.6504L20.3258 25.5254L39.0508 8.27542"
      stroke="#31B1F7"
      stroke-width="3.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>

  <ng-template #failedIcon>
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="23" cy="23" r="23" fill="#99DBFF" />
      <circle cx="22.9997" cy="23" r="18.8806" fill="#CAECFF" />
      <path
        d="M22.9991 29.2478H13.4621C12.4614 29.2523 11.5345 28.8178 11.0332 28.1091C10.532 27.4005 10.5333 26.5264 11.0366 25.8188L15.8051 19.0688L20.5736 12.3188C21.0756 11.6117 21.9996 11.1765 22.9991 11.1765C23.9986 11.1765 24.9226 11.6117 25.4246 12.3188L30.1931 19.0688L34.9616 25.8188C35.4649 26.5264 35.4662 27.4005 34.965 28.1091C34.4637 28.8178 33.5368 29.2523 32.5361 29.2478H22.9991Z"
        fill="#006FAB" />
      <path
        d="M23.0009 26.8058C23.8848 26.8058 24.6014 26.1342 24.6014 25.3058C24.6014 24.4774 23.8848 23.8058 23.0009 23.8058C22.117 23.8058 21.4004 24.4774 21.4004 25.3058C21.4004 26.1342 22.117 26.8058 23.0009 26.8058Z"
        fill="white" />
      <path d="M22.8848 17.2465V21.6205" stroke="white" stroke-width="2" stroke-linecap="round" />
    </svg>
  </ng-template>

  <span class="my-3 text-lg text-black font-medium">{{ dialogTitle }}</span>

  <span *ngIf="data?.['success']; else failedContent" class="text-grey-500 text-base font-normal my-4">
    You have successfully sent
    <span class="font-semibold">{{ data?.['payload']?.amount | applyRoundingRules: country:'payment':false
      | currency: currency + ' ':'symbol':currencyPipeDigitalInfo }}</span>
    to
    <span class="font-semibold"
      >{{ data?.['payload']?.first_name | titlecase }} {{ data?.['payload']?.last_name | titlecase }}
      {{ data?.['payload']?.phone }}</span
    >
    from your Beyonic wallet.
  </span>

  <ng-template #failedContent>
    <span class="text-grey-700 text-base font-medium my-4">
      We could not send
      <span class="font-semibold">{{ data?.['payload']?.amount | applyRoundingRules: country:'payment':false
        | currency: currency + ' ':'symbol':currencyPipeDigitalInfo }}</span>
      to
      <span class="font-semibold"
        >{{ data?.['payload']?.first_name | titlecase }} {{ data?.['payload']?.last_name | titlecase }}
        {{ data?.['payload']?.phone }}</span
      >
      from your beyonic wallet due to insufficient funds. Please top up and try again
    </span>
  </ng-template>

  <bey-button [mat-dialog-close]="true" placeholder="Done" color="dark-blue" [small]="true"></bey-button>
</div>
