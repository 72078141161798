import { Component, Input, OnInit } from '@angular/core';
import { TransactionsUtilityService } from 'src/app/modules/shared/services/transactions-utility.service';
import { Collection } from 'src/app/ngrx/collections/collections.interfaces';
import { RelativeTimePipe } from '../../../shared/pipes/relative-time.pipe';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { NgIf, TitleCasePipe, CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-collection-item',
  templateUrl: './collection-item.component.html',
  styleUrls: ['./collection-item.component.scss'],
  standalone: true,
  imports: [NgIf, MatLegacyMenuModule, TitleCasePipe, CurrencyPipe, RelativeTimePipe],
})
export class CollectionItemComponent implements OnInit {
  @Input() collection: Collection;

  collectionColorAndStatus: { color: string; status: string };

  constructor(public transactionsUtility: TransactionsUtilityService) {}

  ngOnInit(): void {
    this.collectionColorAndStatus = this.transactionsUtility.getStatusAndColor(this.collection['status']);
  }

  resendCollection() {
    this.transactionsUtility.resendTransaction({ ...this.collection, type: 'collections' });
  }
}
