import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplyRoundingRulesPipeParameters } from 'src/assets/interfaces';
import { TransactionsUtilityService } from '../../services/transactions-utility.service';
import { Transaction } from '../../../../ngrx/wallets/wallets.interfaces';
import { ActivatedRoute } from '@angular/router';
import { parsePhoneNumber } from 'libphonenumber-js/min';
import { fetchReceiptDataStart, fetchReceiptDataSuccess, toggleDrawer } from '../../../../ngrx/misc/misc.actions';
import { Store } from '@ngrx/store';
import { selectGenerateReceiptLoading } from '../../../../ngrx/misc/misc.selectors';
import { Observable, Subscription } from 'rxjs';
import { RENDER_DRAWER_CONTENT_DIRECTIVE_CONTENTS_NAMES_LIST } from '../../../../../assets/const';
import { environment } from '../../../../../environments/environment';
import { beyReceiptSharableContent } from '../../../../../assets/utility';
import { FormatPhonePipe } from '../../pipes/format-phone.pipe';
import { ApplyRoundingRulesPipe } from '../../pipes/apply-rounding-rules.pipe';
import { RemoveMinusSignPipe } from '../../pipes/remove-minus-sign.pipe';
import { BeyRoundedBtnComponent } from '../bey-rounded-btn/bey-rounded-btn.component';
import { BeyButtonComponent } from '../bey-button/bey-button.component';
import { BeyExclamationIconComponent } from '../icons/bey-exclamation-icon/bey-exclamation-icon.component';
import { BeyCountryFlagIconComponent } from '../bey-country-flag-icon/bey-country-flag-icon.component';
import { NgIf, AsyncPipe, CurrencyPipe, DatePipe } from '@angular/common';
import { selectUserWalletCurrency } from 'src/app/ngrx/wallets/wallets.selectors';

@Component({
  selector: 'bey-transfer-info',
  templateUrl: './bey-transfer-info.component.html',
  styleUrls: ['./bey-transfer-info.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    BeyCountryFlagIconComponent,
    BeyExclamationIconComponent,
    BeyButtonComponent,
    BeyRoundedBtnComponent,
    AsyncPipe,
    CurrencyPipe,
    DatePipe,
    RemoveMinusSignPipe,
    ApplyRoundingRulesPipe,
    FormatPhonePipe,
  ],
})
export class BeyTransferInfoComponent implements OnInit, OnDestroy {
  /****
   * Transaction details to render
   * Using setter to trigger change detection
   */
  @Input()
  set transaction(val) {
    this._transaction = val;
    this.initTransaction();
  }

  _transaction: Transaction;
  xbCountryIso: string;
  roundingPipeParams: ApplyRoundingRulesPipeParameters;
  currencyPipeDigitalInfo: string;
  transactionColorAndStatus: { color: string; status: string };
  transactionFee: string;
  _unit_amount: number | string;
  merchantWalletCurrency: string;

  receiptIsLoading$: Observable<boolean>;
  subs$: Subscription = new Subscription();

  constructor(
    private transactionsUtilityService: TransactionsUtilityService,
    private route: ActivatedRoute,
    private store: Store
  ) {}

  ngOnInit(): void {
    const type: 'collections' | 'payments' = this.route.snapshot.paramMap.get('type') as 'collections' | 'payments';
    type && (this._transaction = { ...this._transaction, type });

    this.receiptIsLoading$ = this.store.select(selectGenerateReceiptLoading);

    this.subs$.add(
      this.store.select(selectUserWalletCurrency).subscribe((currency) => (this.merchantWalletCurrency = currency))
    );
  }

  initTransaction(): void {
    this.getXBCountryIso();
    this.transactionColorAndStatus = this.transactionsUtilityService.getStatusAndColor(this._transaction['status']);

    if (Array.isArray(this._transaction.related_transaction.fees)) {
      this.transactionFee = this._transaction?.related_transaction?.fees[0]?.amount;
    } else {
      this.transactionFee = this._transaction?.related_transaction?.fees?.amount;
    }

    if (this._transaction.is_xb_request) {
      this._unit_amount = this._transaction?.related_transaction?.xb_fx_details?.receive_amount;
    } else {
      if (this._transaction.quantity) {
        this._unit_amount = parseFloat(this._transaction?.amount) / this._transaction.quantity;
      } else {
        this._unit_amount = this._transaction?.amount;
      }
    }
  }

  resendTransaction(): void {
    this.transactionsUtilityService.resendTransaction(this._transaction);
  }

  get beyonicLinkTitle(): string {
    if (this._transaction.request_type === 'beyonic_link') {
      return this._transaction.beyonic_link?.name;
    }

    return null;
  }

  get beyonicLinkNotes(): string {
    if (this._transaction.request_type !== 'beyonic_link') {
      return null;
    }

    const { name, description } = this._transaction.beyonic_link;
    if (`${name} - ${description}`.startsWith(this._transaction.description)) {
      return null;
    }

    return this._transaction.description;
  }

  // This country code is for the recipient
  get countryIsoCode(): string {
    try {
      const parsed = parsePhoneNumber(this._transaction.contact.phone);
      return parsed.country.toLowerCase();
    } catch (e) {
      console.error(e);
      return '';
    }
  }

  get transactionDescription(): string {
    if (this._transaction.request_type === 'beyonic_link') {
      return this._transaction.beyonic_link?.description;
    }

    return this._transaction.description;
  }

  getXBCountryIso(): void {
    const xbObject = this._transaction?.related_transaction?.xb_fx_details;
    this.xbCountryIso = xbObject?.request_country
      ? xbObject.request_country?.toLowerCase()
      : xbObject?.country?.toLowerCase();

    this.roundingPipeParams = this.transactionsUtilityService.retrieveApplyRoundingRulesPipeParameters(
      this._transaction
    );
    this.currencyPipeDigitalInfo = this.transactionsUtilityService.retrieveCurrencyPipeDigitalInfo(
      this._transaction.business.country
    );
  }

  download(): void {
    // an action that will send an HTTP request to Beyonic API to fetch data then to POST it to the serverless function
    this.store.dispatch(
      fetchReceiptDataStart({
        payload: {
          id: this._transaction.code || this._transaction.id?.toString(),
          receiptType: 'merchant',
          transactionType: this._transaction.type,
          extras: {
            shortName: this._transaction.business.short_name,
            is_xb_request: this._transaction.is_xb_request,
          },
        },
      })
    );
  }

  share(): void {
    const {
      code,
      business: { short_name },
    } = this._transaction;
    const link: string = `${environment.URL}/successful/${this._transaction.type}/${short_name}?code=${code}`;
    const body = beyReceiptSharableContent('text', link, this._transaction.description);

    this.store.dispatch(
      toggleDrawer({
        component: RENDER_DRAWER_CONTENT_DIRECTIVE_CONTENTS_NAMES_LIST.SHARE,
        data: {
          userLink: link,
          messageContent: body,
          twitterContent: beyReceiptSharableContent('twitter', link, this._transaction.description),
          whatsappContent: body,
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(fetchReceiptDataSuccess({ payload: null }));
    this.subs$.unsubscribe();
  }
}
