<div class="flex flex-col w-full px-7 pt-5 pb-8 lg:p-0">
  <span class="text-grey-800 text-2xl font-bold text-center">Get verified to enjoy the best of Beyonic.</span>
  <hr class="my-3" />
  <div class="flex mb-6">
    <bey-numbered-step-circle [stepNumber]="1" class="mr-3"></bey-numbered-step-circle>
    <span class="text-grey-800 text-sm">No daily limits</span>
  </div>
  <div class="flex">
    <bey-numbered-step-circle [stepNumber]="2" class="mr-3"></bey-numbered-step-circle>
    <span class="text-grey-800 text-sm">Beyonic link will feature your business name</span>
  </div>
  <div class="flex flex-col items-center w-full mt-7">
    <bey-button
      color="dark-blue"
      placeholder="Proceed"
      classList="w-full !py-[10px]"
      class="w-full mb-4"
      (onClick)="proceedForNextStep()"></bey-button>
    <bey-button
      classList="w-full !py-[10px]"
      color="dark-blue-outlined"
      placeholder="Cancel"
      class="w-full"
      (onClick)="miscService.closeDrawer()"></bey-button>
  </div>
</div>
