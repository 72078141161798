export const bulkPaymentsTypes = {
  CREATE_BULK_PAYMENT_START: 'CREATE_BULK_PAYMENT_START',
  CREATE_BULK_PAYMENT_SUCCESS: 'CREATE_BULK_PAYMENT_SUCCESS',
  CREATE_BULK_PAYMENT_FAILURE: 'CREATE_BULK_PAYMENT_FAILURE',
  FETCH_TO_BE_PROCESSED_BULK_PAYMENT_START: 'FETCH_TO_BE_PROCESSED_BULK_PAYMENT_START',
  FETCH_TO_BE_PROCESSED_BULK_PAYMENT_SUCCESS: 'FETCH_TO_BE_PROCESSED_BULK_PAYMENT_SUCCESS',
  FETCH_TO_BE_PROCESSED_BULK_PAYMENT_FAILURE: 'FETCH_TO_BE_PROCESSED_BULK_PAYMENT_FAILURE',
  FETCH_TO_BE_PROCESSED_BULK_PAYMENT_RECIPIENTS_START: 'FETCH_TO_BE_PROCESSED_BULK_PAYMENT_RECIPIENTS_START',
  FETCH_TO_BE_PROCESSED_BULK_PAYMENT_RECIPIENTS_SUCCESS: 'FETCH_TO_BE_PROCESSED_BULK_PAYMENT_RECIPIENTS_SUCCESS',
  FETCH_TO_BE_PROCESSED_BULK_PAYMENT_RECIPIENTS_FAILURE: 'FETCH_TO_BE_PROCESSED_BULK_PAYMENT_RECIPIENTS_FAILURE',
  ADD_RECIPIENTS_TO_BULK_PAYMENT_START: 'ADD_RECIPIENTS_TO_BULK_PAYMENT_START',
  ADD_RECIPIENTS_TO_BULK_PAYMENT_SUCCESS: 'ADD_RECIPIENTS_TO_BULK_PAYMENT_SUCCESS',
  ADD_RECIPIENTS_TO_BULK_PAYMENT_FAILURE: 'ADD_RECIPIENTS_TO_BULK_PAYMENT_FAILURE',
  PROCESS_BULK_PAYMENT_START: 'PROCESS_BULK_PAYMENT_START',
  PROCESS_BULK_PAYMENT_SUCCESS: 'PROCESS_BULK_PAYMENT_SUCCESS',
  PROCESS_BULK_PAYMENT_FAILURE: 'PROCESS_BULK_PAYMENT_FAILURE',
  SET_TO_BE_PROCESSED_BULK_PAYMENT: 'SET_TO_BE_PROCESSED_BULK_PAYMENT',
  GET_USER_BULK_PAYMENTS_START: 'GET_USER_BULK_PAYMENTS_START',
  GET_USER_BULK_PAYMENTS_SUCCESS: 'GET_USER_BULK_PAYMENTS_SUCCESS',
  GET_USER_BULK_PAYMENTS_FAILURE: 'GET_USER_BULK_PAYMENTS_FAILURE',
  GET_MORE_USER_BULK_PAYMENTS_START: 'GET_MORE_USER_BULK_PAYMENTS_START',
  GET_MORE_USER_BULK_PAYMENTS_SUCCESS: 'GET_MORE_USER_BULK_PAYMENTS_SUCCESS',
  GET_MORE_USER_BULK_PAYMENTS_FAILURE: 'GET_MORE_USER_BULK_PAYMENTS_FAILURE',
  GET_PAYMENTS_FOR_BULK_PAYMENT_START: 'GET_PAYMENTS_FOR_BULK_PAYMENT_START',
  GET_PAYMENTS_FOR_BULK_PAYMENT_SUCCESS: 'GET_PAYMENTS_FOR_BULK_PAYMENT_SUCCESS',
  GET_PAYMENTS_FOR_BULK_PAYMENT_FAILURE: 'GET_PAYMENTS_FOR_BULK_PAYMENT_FAILURE',
  GET_MORE_PAYMENTS_FOR_BULK_PAYMENT_START: 'GET_MORE_PAYMENTS_FOR_BULK_PAYMENT_START',
  GET_MORE_PAYMENTS_FOR_BULK_PAYMENT_SUCCESS: 'GET_MORE_PAYMENTS_FOR_BULK_PAYMENT_SUCCESS',
  GET_MORE_PAYMENTS_FOR_BULK_PAYMENT_FAILURE: 'GET_MORE_PAYMENTS_FOR_BULK_PAYMENT_FAILURE',
  SET_SELECTED_BULK_PAYMENT: 'SET_SELECTED_BULK_PAYMENT',
  UPDATE_PAYMENT_FOR_BULK_PAYMENT_START: 'UPDATE_PAYMENT_FOR_BULK_PAYMENT_START',
  UPDATE_PAYMENT_FOR_BULK_PAYMENT_SUCCESS: 'UPDATE_PAYMENT_FOR_BULK_PAYMENT_SUCCESS',
  UPDATE_PAYMENT_FOR_BULK_PAYMENT_FAILURE: 'UPDATE_PAYMENT_FOR_BULK_PAYMENT_FAILURE',
  DELETE_PAYMENT_FOR_BULK_PAYMENT_START: 'DELETE_PAYMENT_FOR_BULK_PAYMENT_START',
  DELETE_PAYMENT_FOR_BULK_PAYMENT_SUCCESS: 'DELETE_PAYMENT_FOR_BULK_PAYMENT_SUCCESS',
  DELETE_PAYMENT_FOR_BULK_PAYMENT_FAILURE: 'DELETE_PAYMENT_FOR_BULK_PAYMENT_FAILURE',
};
