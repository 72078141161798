import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { fadeIn, slideIn, slideOut } from '../../../../../assets/utility/transitions';
import { xAxisSwipeDirection } from '../../../../../assets/utility';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { CONST_FUNCTION_CAROUSEL_1_SEEN_KEY, CONST_FUNCTION_CAROUSEL_2_SEEN_KEY } from '../../../../../assets/const';
import { Router } from '@angular/router';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { NgFor, NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'app-bey-functionality-carousel',
  templateUrl: './bey-functionality-carousel.component.html',
  styleUrls: ['./bey-functionality-carousel.component.scss'],
  animations: [slideIn, slideOut, fadeIn],
  standalone: true,
  imports: [NgFor, NgIf, NgClass, BeyButtonComponent],
})
export class BeyFunctionalityCarouselComponent implements OnInit {
  @ViewChild('videoRef')
  videoElement: ElementRef;

  activeHeader: string;
  pageIndex: number = 0;
  slides: Array<any> = [];
  /*****
   *  FIRST TYPE IS THE HOME PAGE
   */
  slides1: any = [
    {
      header: 'Top up',
      name: 'Top-Up and Subscription Fee v3.mp4',
    },
    {
      header: 'Withdraw',
      name: 'Withdraw v3.mp4',
    },

    {
      header: '',
      name: 'Learn More v3.mp4',
    },
  ];

  /*****
   *  SECOND TYPE IS THE TRANSFERS PAGE
   */
  slides2: any = [
    {
      header: 'How to pay',
      name: 'How to Pay v3.mp4',
    },
    {
      header: 'How to collect (prompt)',
      name: 'How to Collect (Prompt) v3.mp4',
    },
    {
      header: 'How to collect (link)',
      name: 'How to Collect (Link) v3.mp4',
    },
  ];

  constructor(
    private dialogRef: MatDialogRef<BeyFunctionalityCarouselComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {
    switch (this.data.type) {
      case 1:
        this.slides = this.slides1;
        this.activeHeader = this.slides[0].header;
        break;

      case 2:
        this.slides = this.slides2;
        this.activeHeader = this.slides[0].header;
        break;
    }
  }

  ngOnInit(): void {}

  navigate(forward?: boolean) {
    const nextIndex = forward ? this.pageIndex + 1 : this.pageIndex - 1;
    if (nextIndex >= 0 && nextIndex < this.slides.length) {
      this.pageIndex = nextIndex;
      this.activeHeader = this.slides[nextIndex].header;
    }
  }

  onSwipe(e) {
    switch (xAxisSwipeDirection(e)) {
      case 'l':
        this.navigate(true);
        break;
      case 'r':
        this.navigate();
        break;
    }
  }

  handleClick() {
    // set as seen
    const key = this.data.type === 1 ? CONST_FUNCTION_CAROUSEL_1_SEEN_KEY : CONST_FUNCTION_CAROUSEL_2_SEEN_KEY;

    localStorage.setItem(key, JSON.stringify(Date.now()));
    this.dialogRef.close();

    if (this.data.type === 1) {
      this.router.navigate(['/']);
    }
  }
}
