import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map, combineLatest, Subscription, BehaviorSubject } from 'rxjs';
import {
  getMoreUserPaymentsStart,
  getRecentPayments,
  resetNewPayment,
} from '../../../../ngrx/payments/payments.actions';
import {
  selectGetMoreUserPaymentsIsLoading,
  selectLoadingRecentPayments,
  selectPaymentsNext,
  selectRecentPaymentsData,
} from '../../../../ngrx/payments/payments.selector';
import {
  getMoreUserCollectionsStart,
  getUserCollectionsStart,
  resetNewCollection,
} from '../../../../ngrx/collections/collections.actions';
import {
  selectGetMoreUserCollectionsIsLoading,
  selectGetUserCollectionsIsLoading,
  selectUserCollections,
  selectUserNextCollections,
} from '../../../../ngrx/collections/collections.selectors';
import { Router } from '@angular/router';
import { toggleAnimatedSidebar } from '../../../../ngrx/misc/misc.actions';
import { Transaction } from 'src/app/ngrx/wallets/wallets.interfaces';
import { isMobile } from '../../../../../assets/utility';
import { ObserveVisibilityDirective } from '../../directives/observe-visibility.directive';
import { TransactionItemComponent } from './transaction-item/transaction-item.component';
import { BeyRecentTransactionsItemSkeletonComponent } from './bey-transaction-skeleton/bey-recent-transactions-item-skeleton.component';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { GetElementIdDirective } from '../../directives/get-element-id.directive';

@Component({
  selector: 'app-recent-transactions',
  templateUrl: './recent-transactions.component.html',
  styleUrls: ['./recent-transactions.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    BeyRecentTransactionsItemSkeletonComponent,
    TransactionItemComponent,
    NgClass,
    ObserveVisibilityDirective,
    AsyncPipe,
    GetElementIdDirective,
  ],
})
export class RecentTransactionsComponent implements OnInit, OnDestroy {
  getUserPaymentsIsLoading$: Observable<boolean>;
  getMoreUserPaymentsIsLoading$: Observable<boolean>;
  getMoreUserCollectionsIsLoading$: Observable<boolean>;
  getUserCollectionsIsLoading$: Observable<boolean>;
  combined: Observable<Transaction[]>;
  userNextPayments: string;
  userNextCollections: string;
  newTransactions: number = 0;
  combinedArrayLength: number;
  bottomSeen: BehaviorSubject<boolean> = new BehaviorSubject(false);
  subscription = new Subscription();

  /***
   *  Display show all transactions anchor on top right section
   */
  @Input()
  showAllTransactions: boolean = false;

  isAnimating: boolean = false;

  constructor(private store$: Store, public router: Router) {}

  ngOnInit(): void {
    // setting payments related selectors
    this.getUserPaymentsIsLoading$ = this.store$.select(selectLoadingRecentPayments);
    this.getMoreUserPaymentsIsLoading$ = this.store$.select(selectGetMoreUserPaymentsIsLoading);

    this.subscription.add(this.store$.select(selectPaymentsNext).subscribe((next) => (this.userNextPayments = next)));

    // setting collections related selectors
    this.getUserCollectionsIsLoading$ = this.store$.select(selectGetUserCollectionsIsLoading);
    this.getMoreUserCollectionsIsLoading$ = this.store$.select(selectGetMoreUserCollectionsIsLoading);

    this.subscription.add(
      this.store$.select(selectUserNextCollections).subscribe((next) => (this.userNextCollections = next))
    );

    this.getTransactions();
    this.combineTransactions();

    // observing the bottom of the page's visibility to call for more transactions
    this.subscription.add(
      this.bottomSeen.subscribe((value: boolean) => {
        if (value) {
          this.getMoreTransactions();
        }
      })
    );
  }

  getTransactions(): void {
    this.store$.dispatch(getRecentPayments());
    this.store$.dispatch(getUserCollectionsStart());
  }

  getMoreTransactions(): void {
    this.subscription.add(this.combined?.subscribe((transactions) => (this.combinedArrayLength = transactions.length)));

    if (this.showAllTransactions && this.combinedArrayLength < 250) {
      if (this.userNextCollections) {
        this.store$.dispatch(getMoreUserCollectionsStart({ payload: { params: this.userNextCollections } }));
      }

      if (this.userNextPayments) {
        this.store$.dispatch(getMoreUserPaymentsStart({ payload: { params: this.userNextPayments } }));
      }
    }
  }

  combineTransactions(): void {
    this.combined = combineLatest([
      this.store$.select(selectRecentPaymentsData),
      this.store$.select(selectUserCollections),
    ]).pipe(
      map(([payments, collections]) => {
        const paymentTransactions = payments?.results
          .map((payment) => ({ ...payment, type: 'payments' }))
          .filter((foo, index) => (this.showAllTransactions ? true : index < 3));

        const collectionTransactions = collections
          .map((collection) => ({ ...collection, type: 'collections' }))
          .filter((foo, index) => (this.showAllTransactions ? true : index < 3));

        const result = paymentTransactions
          .concat(collectionTransactions)
          .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());

        this.newTransactions = 0;

        result.forEach((transaction) => {
          if (transaction.newTransaction) {
            this.newTransactions++;
          }
        });
        return result;
      })
    );
  }

  transactionItmClick(transaction): void {
    // change the 'new' status to false once the transaction gets clicked on
    if (transaction.type === 'collections') {
      this.store$.dispatch(resetNewCollection({ payload: { collectionId: transaction.id } }));
    } else {
      this.store$.dispatch(resetNewPayment({ payload: { paymentId: transaction.id } }));
    }

    // decrease the new transactions number by one if it's a new transaction that the user clicked
    if (transaction.newTransactions) {
      this.newTransactions--;

      if (this.newTransactions < 0) {
        this.newTransactions = 0;
      }
    }

    if (isMobile()) {
      this.router.navigate([`/transactions/${transaction.type.toLowerCase()}/${transaction.id}`]);
    } else {
      this.store$.dispatch(
        toggleAnimatedSidebar({
          payload: {
            ...transaction,
          },
          contentProjection: false,
          isOpen: true,
          isOver: false,
        })
      );
    }
  }

  onBottomSeen(): void {
    this.bottomSeen.next(true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
