<div class="flex flex-col items-center">
  <a
    class="w-14 h-10 mb-3 rounded-md bg-blue-50 flex items-center justify-center"
    (click)="clickHandler()"
    getElementId
    [getElementIdDescription]="operation.icon"
    ><img [src]="'/assets/images/' + operation.icon + '.png'"
  /></a>
  <span class="text-black text-sm font-normal">{{ operation.title }}</span>
</div>
