import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetBusinessInfoByShortNameResponse } from '../../../../ngrx/collections/collections.interfaces';
import { UserBusinessDetailsResponse } from '../../../../ngrx/user/user.interfaces';
import { BeySignUpCalloutComponent } from '../../components/bey-signup-callout/bey-signup-callout.component';
import { BeyWarningBannerComponent } from '../../components/bey-warning-banner/bey-warning-banner.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-link-closed',
  templateUrl: './link-closed.component.html',
  styleUrls: ['./link-closed.component.scss'],
  standalone: true,
  imports: [NgClass, BeyWarningBannerComponent, NgIf, BeySignUpCalloutComponent],
})
export class LinkClosedComponent implements OnInit {
  public isMobile: boolean;
  public backButton: boolean;
  public message: string;
  public businessInfo: GetBusinessInfoByShortNameResponse;
  /****
   * Business Info   passed as a prop from the link preview
   *  UserBusinessDetailsResponse => if it's rendered from the merchant side
   */
  @Input()
  businessInfoProp: GetBusinessInfoByShortNameResponse | UserBusinessDetailsResponse;

  /*****
   *  Forces render the page in mobile view instead desktop
   *  Used in Beyonic link preview
   */
  @Input()
  forceMobileView: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute) {
    const data = this.router.getCurrentNavigation();

    this.isMobile = data?.extras?.state?.['forceMobileView'];
    this.backButton = data?.extras?.state?.['backButton'];
  }

  ngOnInit(): void {
    const { businessInfo } = this.route.snapshot.data;
    this.businessInfo = businessInfo || this.businessInfoProp;
    const { active_merchant_name = '' } = this.businessInfo || {};

    this.isMobile = this.isMobile || this.forceMobileView;
    this.message = active_merchant_name
      ? `${this.businessInfo?.active_merchant_name}'s link is closed.`
      : 'Link is closed.';
  }

  handleSignUp() {
    this.router.navigate(['/signup'], {
      queryParams: {
        utm_source: this.businessInfo?.active_merchant_name,
        utm_medium: 'closed_collection_link',
        utm_campaign: 'normal',
      },
    });
  }
}
