import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as paymentsInterfaces from './payments.interfaces';
import { CreatePaymentRequestBody, RecentPaymentItem } from './payments.interfaces';
import { TransactionReceiptResponse } from '../wallets/wallets.interfaces';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(private http: HttpClient) {}

  getRecentPayments(orderAsc = true, paramsValues: object = {}) {
    let params = new HttpParams();

    Object.keys(paramsValues).forEach((key) => {
      const val = paramsValues[key];
      val && (params = params.append(key, val));
    });

    if (orderAsc) {
      params = params.append('ordering', '-created');
      params = params.append('limit', 6);
    }

    return this.http.get(`${environment.API_URL}payments/`, { params });
  }

  getPaymentInfo(id: number) {
    return this.http.get<RecentPaymentItem>(`${environment.API_URL}payments/${id}/`);
  }

  getMoreUserPayments(params: string) {
    return this.http.get<paymentsInterfaces.RecentPaymentResponse>(`${environment.API_URL}payments/${params}`);
  }

  createPayment(requestBody: CreatePaymentRequestBody) {
    return this.http.post<any>(environment.API_URL + 'payments/', requestBody);
  }

  getBanks() {
    return this.http.get(`${environment.API_URL}banks/`);
  }

  getBankPaymentsFees() {
    return this.http.get<paymentsInterfaces.BankPaymentsFees>(`${environment.API_URL}payments/fees/banks/`);
  }

  getPaymentsFees() {
    return this.http.get<paymentsInterfaces.PaymentsFees>(`${environment.API_URL}payments/fees/mobile_money/`);
  }

  fetchPaymentInfoByCode(code: string) {
    return this.http.get<TransactionReceiptResponse>(
      `${environment.API_URL}payments/public/payments/${code}/customer-receipt/`
    );
  }
}
