<div class="flex flex-col items-center my-12 px-2">
  <svg class="my-4" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="23" cy="23" r="23" fill="#99DBFF" />
    <circle cx="22.9997" cy="23" r="18.8806" fill="#CAECFF" />
    <path
      d="M22.9991 29.2478H13.4621C12.4614 29.2523 11.5345 28.8178 11.0332 28.1091C10.532 27.4005 10.5333 26.5264 11.0366 25.8188L15.8051 19.0688L20.5736 12.3188C21.0756 11.6117 21.9996 11.1765 22.9991 11.1765C23.9986 11.1765 24.9226 11.6117 25.4246 12.3188L30.1931 19.0688L34.9616 25.8188C35.4649 26.5264 35.4662 27.4005 34.965 28.1091C34.4637 28.8178 33.5368 29.2523 32.5361 29.2478H22.9991Z"
      fill="#006FAB" />
    <path
      d="M23.0009 26.8058C23.8848 26.8058 24.6014 26.1342 24.6014 25.3058C24.6014 24.4774 23.8848 23.8058 23.0009 23.8058C22.117 23.8058 21.4004 24.4774 21.4004 25.3058C21.4004 26.1342 22.117 26.8058 23.0009 26.8058Z"
      fill="white" />
    <path d="M22.8848 17.2465V21.6205" stroke="white" stroke-width="2" stroke-linecap="round" />
  </svg>

  <div class="font-medium text-lg mb-2">Network or service is not available</div>
  <div class="text-justify">
    We could not establish an internet connection to our service at the moment. Please make sure that you are connected
    to your network.
  </div>

  <bey-button placeholder="Refresh" color="dark-blue" classList="mt-6" (click)="refreshPage()"></bey-button>
</div>
