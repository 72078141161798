import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, skipWhile } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectGetUserKycIsLoading, selectUserKycDetails } from '../../../ngrx/KYC/kyc.selectors';
import { getUserKycStart } from '../../../ngrx/KYC/kyc.actions';
import { map, withLatestFrom } from 'rxjs/operators';
import { BeyProfileUnderReviewDialogComponent } from '../../shared/components/bey-profile-under-review-dialog/bey-profile-under-review-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Injectable({
  providedIn: 'root',
})
export class CanTransactGuard implements CanLoad {
  constructor(private store: Store, private router: Router, private dialog: MatDialog) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // check here if the user have their KYC approved and provided partial KYB
    // ASYNC GUARD
    let kycRefreshed = false;

    return this.store.pipe(
      select(selectGetUserKycIsLoading),
      withLatestFrom(this.store.select(selectUserKycDetails)),
      skipWhile(([kycLoading, kycInfo]) => {
        if (!kycRefreshed && (!kycInfo || kycInfo.status !== 'approved' || !kycInfo.business)) {
          this.store.dispatch(getUserKycStart());
          kycRefreshed = true;
          return true;
        }
        return kycLoading;
      }),
      map(([_, kycInfo]) => {
        // IF the user is not approved or doesn't have partial business info
        if (kycInfo.status !== 'approved' || !kycInfo.business) {
          if (segments.length === 1) {
            // Only show the dialog for user who finished their profiles
            if (kycInfo.status === 'received' && kycInfo.business) {
              this.dialog.open(BeyProfileUnderReviewDialogComponent);
            }
            // This only fires if the user entered the page into the URL bar
            if (this.router?.['navigationId'] === 1) {
              this.router.navigate(['/']);
            }
          } else {
            // This fires only if the user was trying to access a child page of the route
            this.router.navigate(['/']);
          }

          return false;
        }
        return true;
      })
    );
  }
}
