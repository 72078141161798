<div class="bg-orange-200 wrapper relative" [ngClass]="{ '!mt-0': forceMobile }">
  <div
    class="relative bg-blue-600 h-[55px] text-white items-center justify-between px-20 py-12 hidden lg:flex"
    [ngClass]="{ '!hidden': forceMobile }">
    <img src="assets/images/logo/full-white.svg" routerLink="/" class="cursor-pointer" width="155" alt="beyonic logo" />
    <span class="flex justify-center items-center text-sm leading-[16px]">
      Already have an account?
      <a routerLink="/login" class="underline ml-2 mr-4" data-test-id="login"> Login </a>
    </span>
  </div>

  <div class="background">
    <div class="pt-5 pl-6" *ngIf="backButton">
      <bey-back-arrow strokeFillColor="#000" (onClick)="onBack()">
        <span class="pl-3">Back</span>
      </bey-back-arrow>
    </div>
    <div class="px-10 pt-10 lg:text-center lg:flex lg:items-center lg:flex-col">
      <bey-warning-icon [width]="46" [height]="46" variant="warning"></bey-warning-icon>
      <div class="mt-4 pb-5 text-orange-900 text-[26px]" data-test-id="blurb">
        <span> {{ message }} </span>
      </div>
    </div>
  </div>
</div>
