<div class="pb-10">
  <bey-with-initials-header [name]="businessInfo?.active_merchant_name"></bey-with-initials-header>
  <div
    class="hidden mt-[-5%] relative left-0 lg:flex text-white bg-gradient-to-l from-blue-600 to-blue-400 w-[140px] h-[140px] border-gray-100 border-[5px] mb-9 rounded-full justify-center items-center font-medium text-[38px] mx-auto text-[50px] leading-[59px]">
    {{ businessInfo?.active_merchant_name | nameInitials }}
  </div>

  <div class="px-3 lg:max-w-xl mx-auto">
    <div class="w-full mt-4 rounded-[10px] bg-white lg:shadow-lg lg:p-3">
      <div class="text-grey-500 leading-[19px] font-medium pt-4 lg:mb-6 lg:text-2xl px-3">
        Send to {{ businessInfo?.active_merchant_name }}
      </div>

      <bey-create-collection-form
        [businessInfoStaticLink]="businessInfo"
        [retriedCollectionInfo]="retriedCollectionInfo"
        collectionType="stable"
        [showFormTitle]="false"></bey-create-collection-form>

      <div class="text-center text-blue-400 text-lg font-semibold pb-4 lg:mb-8" *ngIf="merchantUssdCode">
        <div class="pb-4">or</div>
        <a [href]="'tel:' + merchantUssdCode">Dial {{ merchantUssdCode }} to pay</a>
      </div>
    </div>

    <bey-sign-up-callout
      *ngIf="!userLoggedIn"
      header="Is sending and receiving payments a hassle?"
      body="Beyonic makes it so much easier."
      linkText="Start your free trial"
      classList="mt-10"
      (trialStart)="handleSignUp()"></bey-sign-up-callout>
  </div>
</div>
