import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MiscService } from './misc.service';
import { from, of } from 'rxjs';
import { BeyToastService } from '../../modules/shared/services/bey-toast.service';
import { select, Store } from '@ngrx/store';
import { selectReceiptInfo } from './misc.selectors';
import {
  copyText,
  fetchReceiptDataFailure,
  fetchReceiptDataStart,
  fetchReceiptDataSuccess,
  generateReceiptFail,
  generateReceiptStart,
  generateReceiptSuccess,
  getRatesFailure,
  getRatesStart,
  getRatesSuccess,
  printDocFail,
  printDocStart,
  printDocSuccess,
} from './misc.actions';
import { logOut } from '../auth/auth.actions';
import { TransactionReceiptResponse } from '../wallets/wallets.interfaces';

@Injectable()
export class MiscEffects {
  constructor(
    private actions$: Actions,
    private miscService: MiscService,
    private toaster: BeyToastService,
    private store: Store
  ) {}

  printDocStartEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(printDocStart),
      switchMap(({ docType, body, extras: { fileName, userFriendlyError }, callBack }) =>
        this.miscService.generateMedia(body, docType).pipe(
          map(
            (blob) => {
              callBack();
              return { blob, fileName };
            },
            catchError((error) => {
              return of({ error, userFriendlyError });
            })
          )
        )
      ),
      map(({ blob, fileName = 'Your QR' }) => {
        import('file-saver').then((c) => {
          c.default.saveAs(blob, `${fileName}`);
        });

        return printDocSuccess();
      }),
      catchError(({ error, userFriendlyError }) => of(printDocFail({ error, displayMessage: userFriendlyError })))
    )
  );

  printDocFailEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(printDocFail),
        tap(({ error, displayMessage }) => {
          console.error(error);
          this.toaster.open(displayMessage);
        })
      ),
    { dispatch: false }
  );

  copyTextEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(copyText),
        switchMap(({ payload: { toBeCopied, successMessage, failureMessage } }) =>
          this.miscService.copyText(toBeCopied).pipe(
            map(() => this.toaster.open(successMessage, 'blue')),
            catchError((error) => {
              this.toaster.open(failureMessage, 'error');
              return error;
            })
          )
        )
      ),
    { dispatch: false }
  );

  getRatesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRatesStart),
      switchMap(({ payload }) =>
        this.miscService
          .getRates({
            fromCurrencyIso3: payload.toCountryCurrency,
            toCountryIso2: payload.currentUserCountry.isoCode,
            toCurrencyIso3: payload.currentUserCountry.currency,
            useOpenEndpoint: payload.useOpenEndpoint,
          })
          .pipe(
            map((data: any) => getRatesSuccess({ payload: data })),
            catchError((error) => of(getRatesFailure(error)))
          )
      )
    )
  );

  fetchReceiptInfoEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchReceiptDataStart),
      withLatestFrom(this.store.pipe(select(selectReceiptInfo))),
      switchMap(([action, storeData]) => {
        const { id, transactionType, receiptType, extras } = action.payload;
        // check if we have the receipt info on the store
        if (storeData?.code === id) {
          return of(
            fetchReceiptDataSuccess({ payload: storeData }),
            generateReceiptStart({
              payload: {
                receiptType,
                transactionType,
                data: storeData,
                extras: {
                  ...extras,
                  fileName: `Beyonic_receipt_${storeData.beyonic_reference}.pdf`,
                },
              },
            })
          );
        } else {
          // Continue with fetch logic
          return this.fetchReceiptDataFromService(id, receiptType, transactionType, extras);
        }
      }),
      catchError((e) => {
        console.error(e);
        return of(fetchReceiptDataFailure());
      })
    )
  );

  private fetchReceiptDataFromService(
    id: string,
    receiptType: 'merchant' | 'client',
    transactionType: 'collections' | 'payments',
    extras: { shortName: string; is_xb_request: boolean }
  ) {
    return this.miscService.fetchReceiptInfo(id, receiptType, transactionType).pipe(
      switchMap((data: TransactionReceiptResponse) => {
        return of(
          fetchReceiptDataSuccess({ payload: data }),
          generateReceiptStart({
            payload: {
              transactionType,
              data,
              receiptType,
              extras: {
                ...extras,
                fileName: `Beyonic_receipt_${data.beyonic_reference}.pdf`,
              },
            },
          })
        );
      }),
      catchError((e) => {
        console.error(e);
        return of(fetchReceiptDataFailure());
      })
    );
  }

  generateReceiptStartEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generateReceiptStart),
      switchMap(({ payload }) =>
        this.miscService.generateReceipt(payload).pipe(
          map((blob) => {
            import('file-saver').then((c) => {
              c.default.saveAs(blob, payload.extras.fileName);
            });
            return generateReceiptSuccess();
          }),
          catchError((e) => {
            console.error(e);
            return of(generateReceiptFail(e));
          })
        )
      )
    )
  );

  generateReceiptFailEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(generateReceiptFail),
        tap((e) => {
          console.error(e);
        })
      ),
    { dispatch: false }
  );

  logOutEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logOut),
        tap(() => {
          this.miscService.closeDrawer();
        })
      ),
    { dispatch: false }
  );
}
