import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TransactionsUtilityService } from 'src/app/modules/shared/services/transactions-utility.service';
import { selectUserDetails } from 'src/app/ngrx/user/user.selectors';
import { User } from 'src/stories/User';
import { fetchReceiptDataStart, fetchReceiptDataSuccess } from '../../../../ngrx/misc/misc.actions';
import { selectGenerateReceiptLoading } from '../../../../ngrx/misc/misc.selectors';
import { ContactItem } from '../../../../ngrx/contacts/contacts.interface';
import { environment } from '../../../../../environments/environment';
import { ApplyRoundingRulesPipe } from '../../../shared/pipes/apply-rounding-rules.pipe';
import { DateFormatPipe } from '../../../shared/pipes/date-format.pipe';
import { BeySignUpCalloutComponent } from '../../../shared/components/bey-signup-callout/bey-signup-callout.component';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { NgIf, AsyncPipe, CurrencyPipe } from '@angular/common';
import { BeyHeaderWithContentComponent } from '../../../shared/components/bey-header-with-content/bey-header-with-content.component';
import { retrieveOperatingCountry } from '../../../../../assets/utility/shared';
import { BeyonicLink } from 'src/app/ngrx/beyonic-link/beyonic-link.interfaces';

interface UnifiedTransaction {
  id: number;
  business_id: number;
  amount: string;
  amount_currency: string;
  code: string;
  description: string;
  contact: ContactItem;
  link_id: string;
  payment_request_id: number;
  payment_date: Date | string;
  formatted_payment_date: string;
  created: Date;
  expiry_date: Date;
  is_active: boolean;
  status: 'new' | 'processing' | 'failed' | 'successful';
  is_xb_request: boolean;
  business_name: string;
  beyonic_link?: Pick<BeyonicLink, 'name' | 'description'>;
}

@Component({
  selector: 'successful-link',
  templateUrl: './successful-link.component.html',
  styleUrls: ['./successful-link.component.scss'],
  standalone: true,
  imports: [
    BeyHeaderWithContentComponent,
    NgIf,
    BeyButtonComponent,
    BeySignUpCalloutComponent,
    AsyncPipe,
    CurrencyPipe,
    DateFormatPipe,
    ApplyRoundingRulesPipe,
  ],
})
export class SuccessfulLinkComponent implements OnInit, OnDestroy {
  // todo refactor the types
  transactionInfo: UnifiedTransaction;
  transactionType: string;
  country: string;
  currencyPipeDigitalInfo: string;

  userDetails$: Observable<User>;
  receiptIsLoading$: Observable<boolean>;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private transactionsUtility: TransactionsUtilityService
  ) {
    this.userDetails$ = store.select(selectUserDetails);
    this.receiptIsLoading$ = store.select(selectGenerateReceiptLoading);

    this.transactionType = route.snapshot.paramMap.get('type');
  }

  ngOnInit(): void {
    let { transactionData } = this.route.snapshot.data;

    this.transactionInfo = transactionData;

    if (!this.transactionInfo || !['successful', 'credited'].includes(this.transactionInfo?.status)) {
      this.router.navigate(['/']);
    } else {
      this.country = retrieveOperatingCountry(this.transactionInfo.amount_currency, 'currency')?.name;
      this.currencyPipeDigitalInfo = this.transactionsUtility.retrieveCurrencyPipeDigitalInfo(this.country);
    }
  }

  handleSignUp(): void {
    if (environment.is_pay) {
      window.open(`${environment.URL}/signup`, '_blank');
    } else {
      this.router.navigate(['signup']);
    }
  }

  download(): void {
    let transactionType = this.transactionType;
    if (['sl', 'bl', 'ul', 'c'].includes(this.transactionType)) {
      transactionType = 'collections';
    } else if (this.transactionType === 's') {
      transactionType = 'payments';
    }

    // an action that will send an HTTP request to Beyonic API to fetch data then to POST it to the serverless function
    this.store.dispatch(
      fetchReceiptDataStart({
        payload: {
          id: this.transactionInfo.code || this.transactionInfo.id.toString(),
          receiptType: 'client',
          transactionType: transactionType as 'collections' | 'payments',
          extras: {
            shortName: this.route.snapshot.paramMap.get('short_name'),
            is_xb_request: this.transactionInfo.is_xb_request,
          },
        },
      })
    );
  }

  get beyonicLinkNotes(): string {
    if (!this.transactionInfo?.beyonic_link) {
      return null;
    }

    const { name, description } = this.transactionInfo.beyonic_link;

    if (`${name} - ${description}`.startsWith(this.transactionInfo.description)) {
      return null;
    }

    return this.transactionInfo.description;
  }

  ngOnDestroy(): void {
    this.store.dispatch(fetchReceiptDataSuccess({ payload: null }));
  }
}
