import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';
import { BeyonicLinkState } from './beyonic-link.interfaces';

const selectBeyonicLink = (state: AppState) => {
  return state.beyonicLink;
};

//---------1ST LEVEL SELECTORS---------
export const selectUserBeyonicLinks = createSelector(selectBeyonicLink, (state: BeyonicLinkState) =>
  state.userBeyonicLinks ? state.userBeyonicLinks.results : []
);

export const selectSelectedBeyonicLink = createSelector(
  selectBeyonicLink,
  (state: BeyonicLinkState) => state.selectedBeyonicLink
);

export const selectUserNextBeyonicLinks = createSelector(
  selectBeyonicLink,
  (state: BeyonicLinkState) => state?.userBeyonicLinks?.next?.split('/')?.[4]
);

export const selectSelectedBeyonicLinkCollections = createSelector(selectBeyonicLink, (state: BeyonicLinkState) =>
  state.selectedBeyonicLinkCollections ? state.selectedBeyonicLinkCollections.results : []
);

export const selectSelectedBeyonicLinkNextCollections = createSelector(
  selectBeyonicLink,
  (state: BeyonicLinkState) => state?.selectedBeyonicLinkCollections?.next
);

export const selectUserBeyonicLinksSummary = createSelector(
  selectBeyonicLink,
  (state: BeyonicLinkState) => state?.userBeyonicLinks?.summary
);

export const selectBeyonicLinkIsLoading = createSelector(
  selectBeyonicLink,
  (state: BeyonicLinkState) => state.isLoading
);

export const selectBeyonicLinkErrorMessages = createSelector(
  selectBeyonicLink,
  (state: BeyonicLinkState) => state.errorMessages
);
//---------2ND LEVEL SELECTORS---------
export const selectCreateBeyonicLinkIsLoading = createSelector(
  selectBeyonicLinkIsLoading,
  (isLoading) => isLoading['createBeyonicLink']
);
export const selectCreateBeyonicLinkErrorMessage = createSelector(
  selectBeyonicLinkErrorMessages,
  (errorMessages) => errorMessages['createBeyonicLink']
);

export const selectUpdateBeyonicLinkIsLoading = createSelector(
  selectBeyonicLinkIsLoading,
  (isLoading) => isLoading['updateBeyonicLink']
);
export const selectUpdateBeyonicLinkErrorMessage = createSelector(
  selectBeyonicLinkErrorMessages,
  (errorMessages) => errorMessages['updateBeyonicLink']
);

export const selectGetUserBeyonicLinksIsLoading = createSelector(
  selectBeyonicLinkIsLoading,
  (isLoading) => isLoading['getUserBeyonicLinks']
);
export const selectGetUserBeyonicLinksErrorMessage = createSelector(
  selectBeyonicLinkErrorMessages,
  (errorMessages) => errorMessages['getUserBeyonicLinks']
);

export const selectGetMoreUserBeyonicLinksIsLoading = createSelector(
  selectBeyonicLinkIsLoading,
  (isLoading) => isLoading['getMoreUserBeyonicLinks']
);
export const selectGetMoreUserBeyonicLinksErrorMessages = createSelector(
  selectBeyonicLinkErrorMessages,
  (errorMessages) => errorMessages['getMoreUserBeyonicLinks']
);

export const selectGetBeyonicLinkCollectionsIsLoading = createSelector(
  selectBeyonicLinkIsLoading,
  (isLoading) => isLoading['getBeyonicLinkCollections']
);
export const selectGetBeyonicLinkCollectionsErrorMessages = createSelector(
  selectBeyonicLinkErrorMessages,
  (errorMessages) => errorMessages['getBeyonicLinkCollections']
);

export const selectGetMoreBeyonicLinkCollectionsIsLoading = createSelector(
  selectBeyonicLinkIsLoading,
  (isLoading) => isLoading['getMoreBeyonicLinkCollections']
);
export const selectGetMoreBeyonicLinkCollectionsErrorMessages = createSelector(
  selectBeyonicLinkErrorMessages,
  (errorMessages) => errorMessages['getMoreBeyonicLinkCollections']
);

export const selectProcessBeyonicLinkIsLoading = createSelector(
  selectBeyonicLinkIsLoading,
  (isLoading) => isLoading['processBeyonicLink']
);
