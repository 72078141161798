import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bey-boarding',
  templateUrl: './bey-onboard.component.html',
  styleUrls: ['./bey-onboard.component.scss'],
  standalone: true,
})
export class BeyOnboardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.location.href = 'https://www.beyonic.com';
  }
}
