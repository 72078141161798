<bey-with-initials-header name="Beyonic" class="hidden lg:block"></bey-with-initials-header>

<div class="flex justify-center items-center h-full lg:h-auto lg:mt-20 flex-col text-center">
  <img src="assets/images/icons/Time%20icon.png" alt="pending icon" />
  <div class="text-lg font-bold mt-5 mb-4">Pending transaction</div>
  <div class="max-w-[289px]">There is a pending request, kindly refresh this transaction page after 2 minutes.</div>
  <bey-button
    placeholder="Refresh"
    (click)="refresh()"
    color="dark-blue"
    class="mt-11"
    [isLoading]="((isLoadingPendingCollectionInfo$ | async) || (isLoadingPendingCollectionLinkInfo$ |async))"></bey-button>
</div>
