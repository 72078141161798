<div *ngIf="showFormTitle" class="font-bold text-sm lg:text-xl leading-[21px] text-grey-800 px-3 pt-4">
  Who are you collecting from?
</div>

<form class="w-full flex items-center flex-col py-4 px-3" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="w-full flex items-start justify-between space-x-3">
    <div class="flex flex-col w-6/12">
      <bey-contact-selector
        formControlName="first_name"
        label="First name"
        bindLabel="label"
        [notPhoneNumber]="true"
        [options]="contacts"
        [isLoading]="getUserContactsIsLoading$ | async"
        getElementId
        [control]="form.controls['first_name']"></bey-contact-selector>
    </div>
    <bey-input
      class="w-6/12"
      formControlName="last_name"
      label="Last name"
      getElementId
      [control]="form.controls['last_name']"></bey-input>
  </div>

  <div class="w-full flex items-start justify-between space-x-3" formGroupName="phone">
    <div class="w-6/12">
      <bey-country-selector
        formControlName="code"
        bindLabel="label"
        label="Country"
        getElementId
        [control]="form.get('phone.code')"
        [options]="countries">
        <div class="text-xs text-gray-800 mt-2" #message *ngIf="xbApproved">
          Select a different country for international transactions
        </div>
      </bey-country-selector>
    </div>
    <bey-input
      class="w-6/12"
      label="Phone number"
      type="phone"
      formControlName="phone_number"
      getElementId
      [control]="form.controls['phone']"></bey-input>
  </div>

  <bey-input
    class="w-full mt-3"
    type="textarea"
    label="Description"
    formControlName="description"
    getElementId
    [control]="form.controls['description']"></bey-input>

  <bey-input
    *ngIf="!(isXbCollectionRequest$ | async)"
    class="w-full"
    type="amount"
    label="Amount"
    formControlName="amount"
    getElementId
    [control]="form.controls['amount']"
    [disabled]="form.get('phone').invalid"
    ><div prepend class="flex text-sm justify-center items-center pl-[8px] text-grey-400">
      {{ xbToCountryInfo?.currency }}
    </div></bey-input
  >

  <bey-cross-border-amount
    *ngIf="isXbCollectionRequest$ | async"
    class="w-full"
    formControlName="amount"
    [disabled]="form.get('phone').invalid"
    [renderForMerchant]="collectionType !== 'stable'"
    [fromCountry$]="xbFromCountryInfo$"
    [toCountry]="xbToCountryInfo"
    getElementId
    [control]="form.controls['amount']"></bey-cross-border-amount>

  <bey-button
    [placeholder]="isCollectionLink ? 'Create Link' : collectionType === 'stable' ? 'Send' : 'Send Prompt'"
    color="dark-blue"
    classList="py-[5px] px-[20px] mt-2.5"
    [submit]="true"
    [small]="true"
    [isLoading]="isLoading || (createCollectionLoading$ | async)"
    getElementId
    getElementIdDescription="button"></bey-button>
</form>
