import { AppState } from './root-store.module';
import { ActionReducerMap } from '@ngrx/store';

//-------------AUTH-------------
import { authReducer } from './auth/auth.reducer';
import { AuthEffects } from './auth/auth.effects';

//-------------USER-------------
import { userReducer } from './user/user.reducer';
import { UserEffects } from './user/user.effects';

//-------------COLLECTIONS-------------
import { collectionsReducer } from './collections/collections.reducer';
import { CollectionsEffects } from './collections/collections.effects';

//-------------WALLETS-------------
import { walletsReducer } from './wallets/wallets.reducer';
import { WalletsEffects } from './wallets/wallets.effects';

//-------------PERSONAL PROFILE-------------
import { kycReducer } from './KYC/kyc.reducer';
import { KycEffects } from './KYC/kyc.effects';

//-------------PAYMENTS-------------
import { paymentsReducer } from './payments/payments.reducer';
import { PaymentsEffects } from './payments/payments.effects';

//-------------CONTACTS-------------
import { contactsReducer } from './contacts/contacts.reducer';
import { ContactsEffects } from './contacts/contacts.effects';

//-------------BEYONIC LINK-------------
import { beyonicLinkReducer } from './beyonic-link/beyonic-link.reducer';
import { BeyonicLinkEffects } from './beyonic-link/beyonic-link.effects';

//-------------MISC-------------
import { MiscReducer } from './misc/misc.reducer';
import { MiscEffects } from './misc/misc.effects';

//-------------BULK PAYMENTS-------------
import { bulkPaymentsReducer } from './bulk-payments/bulk-payments.reducer';
import { BulkPaymentsEffects } from './bulk-payments/bulk-payments.effects';

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  user: userReducer,
  beyonicLink: beyonicLinkReducer,
  collections: collectionsReducer,
  wallets: walletsReducer,
  kyc: kycReducer,
  payments: paymentsReducer,
  contacts: contactsReducer,
  misc: MiscReducer,
  bulkPayments: bulkPaymentsReducer,
};

export const effects: any[] = [
  AuthEffects,
  UserEffects,
  CollectionsEffects,
  BeyonicLinkEffects,
  WalletsEffects,
  KycEffects,
  PaymentsEffects,
  ContactsEffects,
  MiscEffects,
  BulkPaymentsEffects,
];
