import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  Collection,
  CollectionInfoByCodeResponse,
  CollectionsFees,
  CreateCollectionLinkResponse,
  CreateCollectionRequestBody,
  CreateCollectionResponseBody,
  GetBusinessInfoByShortNameResponse,
} from './collections.interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CollectionsService {
  constructor(private http: HttpClient) {}

  createCollection(
    requestBody: CreateCollectionRequestBody
  ): Observable<CreateCollectionResponseBody | CreateCollectionLinkResponse> {
    if (requestBody.type === 'collection-link') {
      return this.http.patch<CreateCollectionLinkResponse>(
        `${environment.API_URL}partial-requests/link/${requestBody.link_id}/`,
        requestBody
      );
    }

    return this.http.post<CreateCollectionResponseBody>(`${environment.API_URL}payment-requests/`, requestBody);
  }

  createPartialRequest(body: CreateCollectionRequestBody) {
    return this.http.post(`${environment.API_URL}partial-requests/`, body);
  }

  getUserCollections() {
    return this.http.get<{
      count: number;
      next: string;
      previous: string;
      results: Collection[];
    }>(environment.API_URL + 'payment-requests/?limit=6&ordering=-created');
  }

  getCollectionInfo(id: number) {
    return this.http.get<Collection>(environment.API_URL + `payment-requests/${id}/`);
  }

  getBusinessFromShortName(short_name: string) {
    return this.http.get<GetBusinessInfoByShortNameResponse>(
      `${environment.API_URL}business-shortname/${short_name}/`,
      { headers: { skip: 'true' } }
    );
  }

  getMoreUserCollections(params: string) {
    return this.http.get<{
      count: number;
      next: string;
      previous: string;
      results: Collection[];
    }>(environment.API_URL + 'payment-requests/' + params);
  }

  getCollectionLinkInfo(id: string) {
    return this.http.get<CreateCollectionLinkResponse>(`${environment.API_URL}partial-requests/link/${id}/`);
  }

  getUniqueLinkInfoForAuthUser(id: string) {
    return this.http.get(`${environment.API_URL}partial-requests/${id}/`);
  }

  getBusinessInfoByShortName(shortName) {
    return this.http.get(`${environment.API_URL}business-shortname/${shortName}/`);
  }

  getCollectionsFees(useOpenEndpoint: boolean = false) {
    return this.http.get<CollectionsFees>(`${environment.API_URL}collection_fees${useOpenEndpoint ? '_open' : ''}/`);
  }

  getCollectionInfoByCode(code: string) {
    return this.http.get<CollectionInfoByCodeResponse>(`${environment.API_URL}collections/open-status/${code}/`);
  }

  getCollectionInfoByLinkId(link_id: string) {
    return this.http.get(
      `${environment.API_URL}collections/public/partial-requests/link/${link_id}/latest-payment-request/`
    );
  }
}
