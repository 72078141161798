import { Component, OnInit } from '@angular/core';
import { BeyFunctionalityCarouselComponent } from '../../components/bey-functionality-carousel/bey-functionality-carousel.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  standalone: true,
})
export class WelcomeComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.dialog.open(BeyFunctionalityCarouselComponent, {
      width: '95vw',
      maxWidth: 'unset',
      backdropClass: 'custom-dialog-backdrop',
      disableClose: true,
      data: {
        type: 1,
      },
    });
  }
}
