import { Injectable } from '@angular/core';
import { INTERCOM_DELAY } from '../../../../assets/const';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  private _intercomSettings: object = {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'zoupvpas',
    vertical_padding: 30,
  };
  private _userData$: Subject<object> = new Subject<null>();
  private _intercomAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly currentUser$ = this._userData$.asObservable();
  readonly isAvailable$ = this._intercomAvailable$.asObservable();

  constructor() {
    window.intercomSettings = {
      ...this._intercomSettings,
    };

    this._userData$.subscribe((v) => {
      if (v && window.Intercom) {
        window.Intercom('update', {
          ...v,
        });
      }
    });

    // This is an IIFE that checks if Intercom is available
    // and if so, loads it.
    // It marks _intercomAvailable$ as TRUE if Intercom is booted
    (() => {
      const checkIntercom = () => {
        const id: NodeJS.Timeout = setTimeout(() => {
          if (window.Intercom) {
            this._intercomAvailable$.next(true);
            clearTimeout(id);
          } else {
            checkIntercom();
          }
        }, 500);
      };
      checkIntercom();
    })();
  }

  public setCurrentUser(data: any): void {
    this._userData$.next(data);
  }

  /****
   * Boot Intercom
   * This method will load Intercom only when the page is fully loaded + INTERCOM_DELAY in milliseconds
   * If supported it will use web worker to load Intercom
   * Otherwise it'll use FETCH API to load script
   * @param userInfo
   */
  boot(userInfo?: object): void {
    // load intercom after dom has fully loaded + INTERCOM_DELAY in milliseconds
    window.addEventListener('load', () => {
      const id = setTimeout(() => {
        // if web worker is available then use that to load the script
        if (typeof Worker !== 'undefined') {
          const worker = new Worker(new URL('../../../app.worker', import.meta.url));

          worker.onmessage = ({ data }) => {
            eval(data);

            if (userInfo) {
              this.setCurrentUser(userInfo);
            }
          };
          worker.postMessage('load script');
        } else {
          // Web workers are not supported in this environment.
          fetch('https://widget.intercom.io/widget/zoupvpas')
            .then((r) => r.text())
            .then((s) => {
              eval(s);

              if (userInfo) {
                this.setCurrentUser(userInfo);
              }
            })
            .catch((e) => console.error(e));
        }

        clearTimeout(id);
      }, INTERCOM_DELAY);
    });
  }

  restart(): void {
    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: 'zoupvpas',
      });
    } else {
      console.error('Intercom restart failed');
    }
  }

  shutdown(): void {
    if (window.Intercom) {
      window.Intercom('shutdown');
    } else {
      console.error('Intercom shutdown failed');
    }
  }

  async show(): Promise<void> {
    let id: NodeJS.Timeout;
    const showIntercom = () => {
      return new Promise<void>((resolve) => {
        if (window.Intercom) {
          window.Intercom('show');
          clearTimeout(id);
          resolve();
        } else {
          id = setTimeout(() => {
            resolve(showIntercom());
          }, 500);
        }
      });
    };

    try {
      return await showIntercom();
    } catch (error) {
      console.error('Error showing Intercom:', error);
    }
  }

  /****
   * This method returns the visitor ID of a user
   */
  getVisitorId(): Promise<string> {
    return new Promise((resolve, reject) => {
      const timeOutId = setInterval(() => {
        if (window.Intercom) {
          const userVisitorId = window.Intercom('getVisitorId');

          if (userVisitorId) {
            resolve(userVisitorId);
            clearTimeout(timeOutId);
          }
        }
      }, 500);

      const intervalId = setTimeout(() => {
        clearTimeout(timeOutId);
        clearInterval(intervalId);
        reject(new Error('Error retrieving intercom visitor ID'));
      }, 30000);
    });
  }
}
