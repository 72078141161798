export const walletsTypes = {
  GET_USER_WALLET_START: 'GET_USER_WALLET_START',
  GET_USER_WALLET_SUCCESS: 'GET_USER_WALLET_SUCCESS',
  GET_USER_WALLET_FAILURE: 'GET_USER_WALLET_FAILURE',
  GET_USER_BALANCE_START: 'GET_USER_BALANCE_START',
  GET_USER_BALANCE_SUCCESS: 'GET_USER_BALANCE_SUCCESS',
  GET_USER_BALANCE_FAILURE: 'GET_USER_BALANCE_FAILURE',
  GET_USER_BILLINGS_START: 'GET_USER_BILLINGS_START',
  GET_USER_BILLINGS_SUCCESS: 'GET_USER_BILLINGS_SUCCESS',
  GET_USER_BILLINGS_FAILURE: 'GET_USER_BILLINGS_FAILURE',
  GET_MORE_USER_BILLINGS_START: 'GET_MORE_USER_BILLINGS_START',
  GET_MORE_USER_BILLINGS_SUCCESS: 'GET_MORE_USER_BILLINGS_SUCCESS',
  GET_MORE_USER_BILLINGS_FAILURE: 'GET_MORE_USER_BILLINGS_FAILURE',
  GET_SUBSCRIPTION_PRICE_START: 'GET_SUBSCRIPTION_PRICE_START',
  GET_SUBSCRIPTION_PRICE_SUCCESS: 'GET_SUBSCRIPTION_PRICE_SUCCESS',
  GET_SUBSCRIPTION_PRICE_FAILURE: 'GET_SUBSCRIPTION_PRICE_FAILURE',

  PAY_SUB_NOW_START: 'PAY_SUB_NOW_START',
  PAY_SUB_NOW_SUCCESS: 'PAY_SUB_NOW_SUCCESS',
  PAY_SUB_NOW_FAILURE: 'PAY_SUB_NOW_FAILURE',
  PAY_SUB_NOW_RESET: 'PAY_SUB_NOW_RESET',

  GET_BILLING_ATTEMPT_DETAILS_START: 'GET_BILLING_ATTEMPT_DETAILS_START',
  GET_BILLING_ATTEMPT_DETAILS_SUCCESS: 'GET_BILLING_ATTEMPT_DETAILS_SUCCESS',
  GET_BILLING_ATTEMPT_DETAILS_FAILURE: 'GET_BILLING_ATTEMPT_DETAILS_FAILURE',
  //-----------OTHER-------------------
  SET_WALLET_LIMITS_SHOWN_ALERT: 'SET_WALLET_LIMITS_SHOWN_ALERT',
};
