<div class="w-full h-full overflow-y-scroll flex flex-col">
  <div class="relative top flex flex-col py-6 items-center justify-between h-full">
    <img src="assets/images/colored-shapes.svg" class="w-[80%] h-[80%] absolute top-[50%] translate-y-[-50%]" />
    <div class="flex items-center gap-2 px-4 py-3 rounded-lg bg-white mb-2">
      <img class="w-6 h-6" src="assets/images/blue-stars.svg" />
      <span class="text-black text-base font-semibold">New</span>
    </div>
    <div class="flex flex-col items-center">
      <span class="text-grey-700 text-base mb-2">Introducing</span>
      <span class="text-grey-700 font-extrabold text-[26px] mb-2">Bulk payments</span>
    </div>
    <div class="relative rounded-lg bg-white px-8 py-6 flex justify-between w-full max-w-[560px]">
      <div class="flex flex-col">
        <span class="text-black text-base font-bold mb-2">Worker salaries</span>
        <span class="text-black text-base"
          >Payments to the Kampala Hardware staff for<br />
          the month of March, 2024.</span
        >
        <div class="flex gap-6 text-black font-bold text-sm">
          <span>856,000 UGX</span>
          <span>12 recipients</span>
        </div>
      </div>
      <div class="bg-[#BFD5FF] py-1 px-3 rounded-[40px] h-fit">
        <span class="font-light text-black text-sm">Processing</span>
      </div>
      <div
        class="absolute w-[230px] bottom-4 right-[-60px] shadow-[0_4px_16px_0_rgba(0,0,0,0.06)] bg-gray-100 border border-solid border-gray-100 rounded-md p-2 flex items-center justify-between">
        <div class="bg-bey-green rounded-full flex items-center justify-center w-6 h-6">
          <span class="text-white">5</span>
        </div>
        <span class="text-black text-base">Ready for payout</span>
        <bey-chevron direction="down" [height]="24" [width]="24"></bey-chevron>
      </div>
    </div>
  </div>
  <div class="w-full flex flex-col items-center max-w-96 mx-auto">
    <span class="text-black font-bold text-lg mb-4 mt-10">Pay multiple people at once</span>
    <span class="text-black text-base mb-10 text-center"
      >Pay worker’s salaries or your suppliers directly to their mobile money!</span
    >
    <bey-button
      (onClick)="onButtonClick()"
      placeholder="Got it"
      color="dark-blue"
      class="w-full mb-10"
      classList="w-full py-3"></bey-button>
  </div>
</div>
