<div
  class="user-suspension relative w-full max-w-[640px] mx-auto flex flex-col items-center flex flex-col items-center justify-center">
  <span class="text-2xl font-extrabold mb-8 lg:text-5xl lg:mb-10">You are not authorized! 🔐</span>
  <span class="text-base font-normal mb-5 text-center lg:text-xl lg:mb-8"
    >Your account has been suspended for being in violation of our Beyonic regulations and guidelines</span
  >
  <div class="flex items-center flex-col lg:flex-row">
    <bey-button
      color="dark-blue"
      placeholder="Reach out to Beyonic support"
      class="mb-5 lg:mb-0 lg:mr-12"
      [isLoading]="!(intercomAvailable$ | async)"
      (onClick)="onReachOutToBeyonic()"></bey-button>
    <bey-button color="dark-blue-outlined" placeholder="Log out" (onClick)="onLogoutClick()"></bey-button>
  </div>
</div>
