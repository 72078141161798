<div *ngIf="!hidden" @fadeOut>
  <div
    class="hidden bg-white overflow-hidden relative w-[470px] ml-[25px] flex flex-col pt-2.5 pl-12 pr-5 pb-5 rounded-2xl border border-grey-200 xl:flex">
    <div class="flex justify-between">
      <div class="text-xl font-medium text-grey-900 mb-4 leading-[28px]">{{ header }}</div>
      <button (click)="dismiss()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.6068 3.86027L5.72396 3.96373L11.9404 10.1804L18.1592 3.96373L18.2763 3.86027C18.7642 3.48095 19.4695 3.51565 19.9176 3.96421C20.4031 4.45016 20.4028 5.23782 19.9171 5.7235L13.6996 11.9396L19.9171 18.1557C20.4028 18.6414 20.4031 19.4291 19.9176 19.915C19.4695 20.3636 18.7642 20.3983 18.2763 20.0189L18.1592 19.9155L11.9416 13.6976L5.72396 19.9155L5.6068 20.0189C5.11891 20.3983 4.41361 20.3636 3.96551 19.915C3.48006 19.4291 3.48028 18.6414 3.96599 18.1557L10.1824 11.9396L3.96599 5.7235C3.48028 5.23782 3.48006 4.45016 3.96551 3.96421C4.41361 3.51565 5.11891 3.48095 5.6068 3.86027Z"
            fill="#4B4B4B" />
        </svg>
      </button>
    </div>
    <span class="text-base text-grey-600 leading-[23px] max-w-[376px]">
      {{ body }}
    </span>
    <div class="w-4 top-0 bottom-0 left-0 bg-blue-400 absolute mr-8"></div>
  </div>
</div>
