import { createReducer, on } from '@ngrx/store';
import { UserState } from './user.interfaces';
import * as userActions from './user.actions';
import * as authActions from '../auth/auth.actions';
import { logOut } from '../auth/auth.actions';
import { produce } from 'immer';

const initialState: UserState = {
  userDetails: null,
  isSuspended: false,
  userBusinessDetails: null,
  userBankAccount: null,
  securityQuestions: null,
  savedUser: null,
  industries: null,
  // todo remove unused
  businessProfileFirstPartData: null,
  businessProfileSecondPartData: null,
  // todo remove unused

  createBusinessProfileData: {
    data: null,
  },
  isLoading: {
    getUserDetails: false,
    getUserBusinessDetails: false,
    createBusinessProfile: false,
    getIndustries: false,
    getSecurityQuestions: false,
    getCountryDocs: false,
    getUserBankAccount: false,
    uploadBusinessDocuments: false,
    kybDocs: false,
  },
  errorMessages: {
    getUserDetails: '',
    getUserBusinessDetails: '',
    createBusinessProfile: '',
    getIndustries: '',
    getSecurityQuestions: '',
    getUserBankAccount: '',
    uploadBusinessDocuments: '',
  },

  userKybState: {
    kybApproved: false,
    kybUploaded: false,
    kybFileRejected: false,
    countryDocs: [],
    businessDocs: null,
  },
};

export const userReducer = createReducer(
  initialState,
  //----------START----------
  on(
    userActions.getUserDetailsStart,
    produce((state) => {
      state.isLoading['getUserDetails'] = true;
    })
  ),
  on(
    userActions.getUserBusinessDetailsStart,
    produce((state) => {
      state.isLoading['getUserBusinessDetails'] = true;
    })
  ),
  on(
    userActions.createBusinessProfileStart,
    produce((state) => {
      state.isLoading['createBusinessProfile'] = true;
    })
  ),
  on(
    userActions.getIndustriesStart,
    produce((state) => {
      state.isLoading['getIndustries'] = true;
    })
  ),

  on(
    userActions.getUserBankAccountStart,
    produce((state) => {
      state.isLoading['getUserBankAccount'] = true;
    })
  ),
  on(
    userActions.uploadBusinessDocumentsStart,
    produce((state) => {
      state.isLoading['uploadBusinessDocuments'] = true;
    })
  ),
  //----------SUCCESS----------
  on(
    userActions.getUserDetailsSuccess,
    produce((state, { payload }) => {
      const { first_name, phone } = payload;

      state.userDetails = payload;
      state.savedUser = { first_name, phone, samePreviousSavedUser: state.savedUser?.phone === phone };
      state.isLoading['getUserDetails'] = false;
      state.errorMessages['getUserDetails'] = '';
    })
  ),
  on(
    userActions.getUserBusinessDetailsSuccess,
    produce((state, { payload }) => {
      state.userBusinessDetails = payload;
      state.isLoading['getUserBusinessDetails'] = false;
      state.errorMessages['getUserBusinessDetails'] = '';
    })
  ),
  on(
    userActions.createBusinessProfileSuccess,
    produce((state, { payload }) => {
      state.userBusinessDetails = payload;
      state.isLoading['createBusinessProfile'] = false;
      state.errorMessages['createBusinessProfile'] = '';
    })
  ),
  on(
    userActions.getIndustriesSuccess,
    produce((state, { payload }) => {
      state.industries = payload;
      state.isLoading['getIndustries'] = false;
      state.errorMessages['getIndustries'] = '';
    })
  ),

  on(
    userActions.getUserBankAccountSuccess,
    produce((state, { payload }) => {
      state.userBankAccount = payload;
      state.isLoading['getUserBankAccount'] = false;
      state.errorMessages['getUserBankAccount'] = '';
    })
  ),
  on(
    userActions.uploadBusinessDocumentsSuccess,
    produce((state) => {
      state.isLoading['uploadBusinessDocuments'] = false;
      state.errorMessages['uploadBusinessDocuments'] = '';
    })
  ),
  //----------FAILURE----------
  on(
    userActions.getUserDetailsFailure,
    produce((state, { error }) => {
      state.isLoading['getUserDetails'] = false;
      state.errorMessages['getUserDetails'] = error;
    })
  ),
  on(
    userActions.getUserBusinessDetailsFailure,
    produce((state, { error }) => {
      state.isLoading['getUserBusinessDetails'] = false;
      state.errorMessages['getUserBusinessDetails'] = error;
    })
  ),
  on(
    userActions.createBusinessProfileFailure,
    produce((state, { error }) => {
      state.isLoading['createBusinessProfile'] = false;
      state.errorMessages['createBusinessProfile'] = error;
    })
  ),
  on(
    userActions.getIndustriesFailure,
    produce((state, { error }) => {
      state.isLoading['getIndustries'] = false;
      state.errorMessages['getIndustries'] = error;
    })
  ),

  on(
    userActions.getUserBankAccountFailure,
    produce((state, { error }) => {
      state.isLoading['getUserBankAccount'] = false;
      state.errorMessages['getUserBankAccount'] = error;
    })
  ),
  on(
    userActions.uploadBusinessDocumentsFailure,
    produce((state, { error }) => {
      state.isLoading['uploadBusinessDocuments'] = false;
      state.errorMessages['uploadBusinessDocuments'] = error;
    })
  ),
  //----------OTHERS----------
  on(
    userActions.clearSavedUser,
    produce((state) => {
      state.savedUser = null;
    })
  ),
  on(
    userActions.clearUserKybFileRejected,
    produce((state) => {
      state.userKybState.kybFileRejected = false;
    })
  ),
  on(
    userActions.setBusinessId,
    produce((state, { payload: { id } }) => {
      state.userDetails.business_id = id;
    })
  ),
  on(
    userActions.setBusinessProfileData,
    produce((state, { payload }) => {
      let _businessProfileData = payload; // in case of reset

      if (payload) {
        _businessProfileData = { ...state.createBusinessProfileData.data, ...payload };
      }

      state.createBusinessProfileData.data = _businessProfileData;
    })
  ),

  // todo remove unused actions
  on(
    userActions.setBusinessProfileFirstPartData,
    produce((state, { payload }) => {
      state.businessProfileFirstPartData = payload;
    })
  ),
  on(
    userActions.clearBusinessProfileFirstPartData,
    produce((state) => {
      state.businessProfileFirstPartData = null;
    })
  ),
  on(
    userActions.setBusinessProfileSecondPartData,
    produce((state, { payload }) => {
      state.businessProfileSecondPartData = payload;
    })
  ),
  on(
    userActions.clearBusinessProfileSecondPartData,
    produce((state) => {
      state.businessProfileSecondPartData = null;
    })
  ),
  // todo remove unused actions
  on(
    userActions.setUserBankAccount,
    produce((state, { payload }) => {
      state.userBankAccount = payload;
    })
  ),
  on(
    userActions.clearUserBankAccount,
    produce((state) => {
      state.userBankAccount = null;
    })
  ),
  on(
    userActions.suspendUser,
    produce((state) => {
      state.isSuspended = true;
    })
  ),
  on(
    logOut,
    produce((state) => {
      state.userDetails = null;
      state.isSuspended = false;
      state.userBusinessDetails = null;
      state.securityQuestions = null;
    })
  ),
  //----------AUTH ACTIONS----------
  on(
    authActions.loginSuccess,
    produce((state, { payload }) => {
      const {
        phone,
        currentUser: { first_name },
      } = payload;
      let currentUser = {
        ...payload.currentUser,
      };
      currentUser?.profile && delete currentUser?.profile;

      state.userDetails = currentUser;
      state.savedUser = { phone, first_name, samePreviousSavedUser: state.savedUser?.phone === phone };
    })
  ),
  //----------- Country docs -----------
  on(
    userActions.getCountryDocuments,
    produce((state) => {
      state.isLoading['getCountryDocs'] = true;
    })
  ),
  on(
    userActions.setCountryDocuments,
    produce((state, payload) => {
      const results = payload['results'];

      state.isLoading['getCountryDocs'] = false;
      state.userKybState.countryDocs = results;
    })
  ),
  on(
    userActions.getUserKybStart,
    produce((state) => {
      state.isLoading['kybDocs'] = true;
    })
  ),
  on(
    userActions.getUserKybSuccess,
    produce((state, { payload }) => {
      const results = payload['results'];
      // Check if the user KYB docs has been approved in order to hide the button on home page
      // Filter the user docs using the approved_date flag

      //-----------OBJECTS CREATION-----------
      let docIdsObjectForApproval = {};
      let docIdsObjectForRejection = {};
      let docIdsObjectForUploading = {};
      //--------------------------------------

      //-----------GIVING VALUES FOR APPROVAL AND REJECTION OBJECTS-----------
      // each object will have the business documents IDs as keys, and the value will be the business documents with this key
      // EX:  docIdsObjectForApproval = { 3: [{ id: 3, ... }, ...] }

      results.forEach(
        (doc) =>
          (docIdsObjectForRejection = {
            ...docIdsObjectForRejection,
            [doc['document']]: docIdsObjectForRejection[doc['document']]
              ? [...docIdsObjectForRejection[doc['document']], doc]
              : [doc],
          })
      );
      results.forEach(
        (doc) =>
          (docIdsObjectForApproval = {
            ...docIdsObjectForApproval,
            [doc['document']]: docIdsObjectForApproval[doc['document']]
              ? [...docIdsObjectForApproval[doc['document']], doc]
              : [doc],
          })
      );
      //-----------------------------------------------------------------

      //-----------EXTRACTING ONE VALUE FOR EACH KEY-----------
      // here we get the status of the last document of the array
      // EX:  docIdsObjectForApproval = { 3: 'Rejected' }
      Object.keys(docIdsObjectForRejection).forEach(
        (docId) =>
          (docIdsObjectForRejection[docId] =
            docIdsObjectForRejection[docId][docIdsObjectForRejection[docId].length - 1].status)
      );

      // here we fetch for a document with an approved status
      // EX:  docIdsObjectForApproval = { 3: true }
      Object.keys(docIdsObjectForApproval).forEach(
        (docId) =>
          (docIdsObjectForApproval[docId] = !!docIdsObjectForApproval[docId].find((doc) => doc.status === 'approved'))
      );
      //-------------------------------------------------------

      //-----------EXTRACTING ONE VALUE FOR EACH OBJECT-----------
      // if kybApproved is true then the business is verified
      const kybApproved = Object.keys(docIdsObjectForApproval).length
        ? Object.keys(docIdsObjectForApproval).reduce((acc, doc) => (!docIdsObjectForApproval[doc] ? false : acc), true)
        : false;

      // if kybRejected is true then there's a rejected document out of the business documents
      const kybFileRejected = Object.keys(docIdsObjectForApproval).length
        ? Object.keys(docIdsObjectForRejection).reduce(
            (acc, doc) => (docIdsObjectForRejection[doc] === 'rejected' ? true : acc),
            false
          )
        : false;
      //---------------------------------------------------------

      //-----------EXTRACTING ONE VALUE FOR KYBUPLOADED VARIABLE-----------
      // here we extract one boolean value for kybUploaded variable to make sure that the user doesn't have an rejected or missing document
      results.forEach(
        (doc) =>
          (docIdsObjectForUploading = {
            ...docIdsObjectForUploading,
            [doc['document']]: docIdsObjectForUploading[doc['document']]
              ? [...docIdsObjectForUploading[doc['document']], doc]
              : [doc],
          })
      );
      let kybUploaded: boolean = false;
      if (Object.keys(docIdsObjectForUploading).length) {
        Object.keys(docIdsObjectForUploading).forEach(
          (docId) =>
            (docIdsObjectForUploading[docId] = !!docIdsObjectForUploading[docId].find(
              (doc) => doc.status === 'approved' || doc.status === 'received'
            ))
        );

        kybUploaded =
          Object.keys(docIdsObjectForUploading).length > 1
            ? Object.keys(docIdsObjectForUploading).reduce(
                (acc, doc) => (!docIdsObjectForUploading[doc] ? false : acc),
                true
              )
            : false;
        //----------------------------------------------------
      }

      state.isLoading['kybDocs'] = false;
      state.userKybState.kybApproved = kybApproved;
      state.userKybState.kybUploaded = kybUploaded;
      state.userKybState.kybFileRejected = kybFileRejected;
      state.userKybState.businessDocs = results;
    })
  ),
  on(
    userActions.getUserKybFailure,
    produce((state) => {
      state.isLoading['kybDocs'] = false;
    })
  )
);
