<div class="flex flex-col p-5">
  <div class="flex items-center justify-between w-full">
    <span data-test-id="title" class="text-grey-900 font-bold text-lg">Embed Beyonic button on your website</span>
    <img class="cursor-pointer" src="assets/images/black-cross.png" (click)="onCrossClick()" />
  </div>
  <div class="py-3">
    <div
      data-test-id="snippet"
      class="w-100 flex items-center justify-center my-6 pointer-events-none"
      [innerHTML]="data.snippet | safeHtml">
      &nbsp;
    </div>
    <bey-snippet-code [snippet]="data.snippet">
      <bey-button append (onClick)="onButtonClick()" placeholder="Copy"> </bey-button>
    </bey-snippet-code>
  </div>
</div>
