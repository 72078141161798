import { createReducer, on } from '@ngrx/store';
import {
  createBulkPaymentStart,
  createBulkPaymentSuccess,
  createBulkPaymentFailure,
  fetchToBeProcessedBulkPaymentStart,
  fetchToBeProcessedBulkPaymentSuccess,
  fetchToBeProcessedBulkPaymentFailure,
  addRecipientsToBulkPaymentStart,
  addRecipientsToBulkPaymentSuccess,
  addRecipientsToBulkPaymentFailure,
  processBulkPaymentStart,
  processBulkPaymentSuccess,
  processBulkPaymentFailure,
  setToBeProcessedBulkPayment,
  fetchToBeProcessedBulkPaymentRecipientsStart,
  fetchToBeProcessedBulkPaymentRecipientsSuccess,
  fetchToBeProcessedBulkPaymentRecipientsFailure,
  getUserBulkPaymentsSuccess,
  getUserBulkPaymentsStart,
  getUserBulkPaymentsFailure,
  getPaymentsForBulkPaymentStart,
  getPaymentsForBulkPaymentSuccess,
  getPaymentsForBulkPaymentFailure,
  setSelectedBulkPayment,
  getMoreUserBulkPaymentsStart,
  getMoreUserBulkPaymentsSuccess,
  getMoreUserBulkPaymentsFailure,
  updatePaymentForBulkPaymentStart,
  updatePaymentForBulkPaymentSuccess,
  updatePaymentForBulkPaymentFailure,
  deletePaymentForBulkPaymentStart,
  deletePaymentForBulkPaymentSuccess,
  deletePaymentForBulkPaymentFailure,
} from './bulk-payments.actions';
import { produce } from 'immer';
import { BulkPaymentsState } from './bulk-payments.interfaces';

const initialState: BulkPaymentsState = {
  toBeProcessedBulkPayment: null,
  userBulkPayments: null,
  selectedBulkPayment: null,
  paymentsForSelectedBulkPayment: null,
  isLoading: {
    createBulkPayment: false,
    fetchToBeProcessedBulkPayment: false,
    addRecipientsToBulkPayment: false,
    processBulkPayment: false,
    fetchToBeProcessedBulkPaymentRecipients: false,
    updatePaymentForBulkPayment: false,
  },
  errorMessages: {
    createBulkPayment: '',
    fetchToBeProcessedBulkPayment: '',
    addRecipientsToBulkPayment: '',
    processBulkPayment: '',
    fetchToBeProcessedBulkPaymentRecipients: '',
    updatePaymentForBulkPayment: '',
  },
};

export const bulkPaymentsReducer = createReducer(
  initialState,
  //----------START----------
  on(
    createBulkPaymentStart,
    produce((state) => {
      state.isLoading['createBulkPayment'] = true;
    })
  ),
  on(
    fetchToBeProcessedBulkPaymentStart,
    produce((state) => {
      state.isLoading['fetchToBeProcessedBulkPayment'] = true;
    })
  ),
  on(
    addRecipientsToBulkPaymentStart,
    produce((state) => {
      state.isLoading['addRecipientsToBulkPayment'] = true;
    })
  ),
  on(
    processBulkPaymentStart,
    produce((state) => {
      state.isLoading['processBulkPayment'] = true;
    })
  ),
  on(
    fetchToBeProcessedBulkPaymentRecipientsStart,
    produce((state) => {
      state.isLoading['fetchToBeProcessedBulkPaymentRecipients'] = true;
    })
  ),
  on(
    getUserBulkPaymentsStart,
    produce((state) => {
      state.isLoading['getUserBulkPayments'] = true;
    })
  ),
  on(
    getMoreUserBulkPaymentsStart,
    produce((state) => {
      state.isLoading['getMoreUserBulkPayments'] = true;
    })
  ),
  on(
    getPaymentsForBulkPaymentStart,
    produce((state) => {
      state.isLoading['getPaymentsForBulkPayment'] = true;
    })
  ),
  on(
    updatePaymentForBulkPaymentStart,
    produce((state) => {
      state.isLoading['updatePaymentForBulkPayment'] = true;
    })
  ),
  on(
    deletePaymentForBulkPaymentStart,
    produce((state) => {
      state.isLoading['deletePaymentForBulkPayment'] = true;
    })
  ),
  //----------SUCCESS----------
  on(
    createBulkPaymentSuccess,
    produce((state) => {
      state.isLoading['createBulkPayment'] = false;
      state.errorMessages['createBulkPayment'] = '';
    })
  ),
  on(
    fetchToBeProcessedBulkPaymentSuccess,
    produce((state, { payload }) => {
      state.isLoading['fetchToBeProcessedBulkPayment'] = false;
      state.errorMessages['fetchToBeProcessedBulkPayment'] = '';
      state.toBeProcessedBulkPayment = { payments: state.toBeProcessedBulkPayment.payments, ...payload };
    })
  ),
  on(
    fetchToBeProcessedBulkPaymentRecipientsSuccess,
    produce((state, { payload }) => {
      state.isLoading['fetchToBeProcessedBulkPaymentRecipients'] = false;
      state.errorMessages['fetchToBeProcessedBulkPaymentRecipients'] = '';
      state.toBeProcessedBulkPayment = { ...state.toBeProcessedBulkPayment, payments: payload };
    })
  ),
  on(
    addRecipientsToBulkPaymentSuccess,
    produce((state) => {
      state.isLoading['addRecipientsToBulkPayment'] = false;
      state.errorMessages['addRecipientsToBulkPayment'] = '';
    })
  ),
  on(
    processBulkPaymentSuccess,
    produce((state) => {
      state.isLoading['processBulkPayment'] = false;
      state.errorMessages['processBulkPayment'] = '';
    })
  ),
  on(
    getUserBulkPaymentsSuccess,
    produce((state, { payload }) => {
      state.userBulkPayments = payload;
      state.isLoading['getUserBulkPayments'] = false;
      state.errorMessages['getUserBulkPayments'] = '';
    })
  ),
  on(
    getMoreUserBulkPaymentsSuccess,
    produce((state, { payload }) => {
      state.userBulkPayments = {
        ...payload,
        next: payload['next'],
        results: [...state?.['userBulkPayments']?.['results'], ...payload?.['results']],
      };
      state.isLoading['getMoreUserBulkPayments'] = false;
      state.errorMessages['getMoreUserBulkPayments'] = '';
    })
  ),
  on(
    getPaymentsForBulkPaymentSuccess,
    produce((state, { payload }) => {
      state.paymentsForSelectedBulkPayment = {
        ...payload,
        next: payload['next'],
        results: payload?.['results'],
      };
      state.isLoading['getPaymentsForBulkPayment'] = false;
      state.errorMessages['getPaymentsForBulkPayment'] = '';
    })
  ),
  on(
    updatePaymentForBulkPaymentSuccess,
    produce((state) => {
      state.isLoading['updatePaymentForBulkPayment'] = false;
      state.errorMessages['updatePaymentForBulkPayment'] = '';
    })
  ),
  on(
    deletePaymentForBulkPaymentSuccess,
    produce((state) => {
      state.isLoading['deletePaymentForBulkPayment'] = false;
      state.errorMessages['deletePaymentForBulkPayment'] = '';
    })
  ),
  //----------FAILURE----------
  on(
    createBulkPaymentFailure,
    produce((state, { error }) => {
      state.isLoading['createBulkPayment'] = false;
      state.errorMessages['createBulkPayment'] = error;
    })
  ),
  on(
    fetchToBeProcessedBulkPaymentFailure,
    produce((state, { error }) => {
      state.isLoading['fetchToBeProcessedBulkPayment'] = false;
      state.errorMessages['fetchToBeProcessedBulkPayment'] = error;
    })
  ),
  on(
    addRecipientsToBulkPaymentFailure,
    produce((state, { error }) => {
      state.isLoading['addRecipientsToBulkPayment'] = false;
      state.errorMessages['addRecipientsToBulkPayment'] = error;
    })
  ),
  on(
    processBulkPaymentFailure,
    produce((state, { error }) => {
      state.isLoading['processBulkPayment'] = false;
      state.errorMessages['processBulkPayment'] = error;
    })
  ),
  on(
    fetchToBeProcessedBulkPaymentRecipientsFailure,
    produce((state, { error }) => {
      state.isLoading['fetchToBeProcessedBulkPaymentRecipients'] = false;
      state.errorMessages['fetchToBeProcessedBulkPaymentRecipients'] = error;
    })
  ),
  on(
    getUserBulkPaymentsFailure,
    produce((state, { error }) => {
      state.isLoading['getUserBulkPayments'] = false;
      state.errorMessages['getUserBulkPayments'] = error;
    })
  ),
  on(
    getMoreUserBulkPaymentsFailure,
    produce((state, { error }) => {
      state.isLoading['getMoreUserBulkPayments'] = false;
      state.errorMessages['getMoreUserBulkPayments'] = error;
    })
  ),
  on(
    getPaymentsForBulkPaymentFailure,
    produce((state, { error }) => {
      state.isLoading['getPaymentsForBulkPayment'] = false;
      state.errorMessages['getPaymentsForBulkPayment'] = error;
    })
  ),
  on(
    updatePaymentForBulkPaymentFailure,
    produce((state, { error }) => {
      state.isLoading['updatePaymentForBulkPayment'] = false;
      state.errorMessages['updatePaymentForBulkPayment'] = error;
    })
  ),
  on(
    deletePaymentForBulkPaymentFailure,
    produce((state, { error }) => {
      state.isLoading['deletePaymentForBulkPayment'] = false;
      state.errorMessages['deletePaymentForBulkPayment'] = error;
    })
  ),
  //----------OTHER----------
  on(
    setToBeProcessedBulkPayment,
    produce((state, { payload }) => {
      state.toBeProcessedBulkPayment = payload;
    })
  ),
  on(
    setSelectedBulkPayment,
    produce((state, { payload: { bulkPayment } }) => {
      state.selectedBulkPayment = bulkPayment;
    })
  )
);
