import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  BillingItem,
  BusinessLimitsCheckRequestBody,
  BusinessLimitsCheckResponse,
  SubscriptionPrice,
  Wallet,
  WalletsState,
} from './wallets.interfaces';

@Injectable({
  providedIn: 'root',
})
export class WalletsService {
  constructor(private http: HttpClient) {}

  getUserWallet(business_id: number) {
    return this.http.get<{
      count: number;
      next: string;
      previous: string;
      results: Array<Wallet>;
    }>(
      environment.API_URL +
        `wallets/?business_id=${business_id}&include_daily_summary=true&seven_day_transactions_summary=true`
    );
  }

  getUserBillings(business_id: number) {
    return this.http.get<WalletsState['userBillings']>(
      environment.API_URL + `billing-attempts/?business_id=${business_id}&ordering=-id&limit=4`
    );
  }

  getMoreUserBillings(params: string) {
    return this.http.get<{
      count: number;
      next: string;
      previous: string;
      results: BillingItem[];
    }>(environment.API_URL + 'billing-attempts/' + params);
  }

  getUserBalance(balanceId: number) {
    return this.http.get<any>(environment.API_URL + `wallets/${balanceId}/`);
  }

  getSubscriptionPrice() {
    return this.http.get<SubscriptionPrice>(environment.API_URL + 'subscription/price/1/');
  }

  businessLimitsCheck(payload: BusinessLimitsCheckRequestBody) {
    return this.http.post<BusinessLimitsCheckResponse>(environment.API_URL + 'wallet-limits/check/', {
      ...payload,
    });
  }

  paySubscriptionNow(business_id: number) {
    return this.http.post<BillingItem>(`${environment.API_URL}billing-attempts/pay-now/`, {
      business_id,
    });
  }

  getBillingAttemptInfo(id: number) {
    return this.http.get<BillingItem>(`${environment.API_URL}billing-attempts/${id}/`);
  }
}
