/****
 *  Application UTILS
 */
import { environment } from '../../environments/environment';
import { COUNT_FORMATS, ENV_NAMES } from '../const';
import { BeyCountrySelectorOptionInterface } from '../interfaces/bey-country-selector.interface';
import { event, setVars } from '@fullstory/browser';
import parsePhoneNumber from 'libphonenumber-js/min';
import { BeyonicCountry } from '../interfaces';
import { retrieveOperatingCountry } from './shared';
import { BeyTagAttributes } from 'src/app/modules/shared/components/bey-tag/bey-tag.component';

/*****
 *  Copy text to clipboard
 *  Returns a promise with boolean value
 * @param text
 */
export const copyToClipboard = async (text): Promise<boolean> => {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (err) {
      console.error('Failed to copy: ', err);
      return Promise.reject(false);
    }
  } else {
    // Fallback method which is deprecated actually
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const result = document.execCommand('copy');
      return result ? Promise.resolve(result) : Promise.reject(result);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      return Promise.reject(false);
    } finally {
      document.body.removeChild(textArea);
    }
  }
};

/****
 *  Create collection link (STATIC || UNIQUE || Beyonic link v2) based on the environment
 *  All links can work on both http://pay.beyonic.com and http://m.beyonic.com
 *  But we're moving the traffic to the new domain in order to shorten the link
 * @param short_name
 * @param link_id
 * @param isBeyonicLink
 */
export const buildCollectionLink: (short_name: string, link_id?: string, isBeyonicLink?: boolean) => string = (
  short_name: string,
  link_id: string = '',
  isBeyonicLink: boolean = false
): string => {
  if (isBeyonicLink) {
    return `${environment.PAY_URL}/${short_name}/${link_id}`;
  }

  let base = `${environment.PAY_URL}`;

  if (link_id) {
    base += `/sl/${short_name}/${link_id}/`;
  } else {
    base += `/${short_name}/`;
  }

  return base;
};

/****
 * Determine which direction swept to
 * return l for LEFT or r for RIGHT
 * @param e : WheelEvent
 */
export const xAxisSwipeDirection = (e: WheelEvent): 'l' | 'r' => {
  return Math.abs(e?.deltaX) > 40 ? (e.deltaX > 0 ? 'r' : 'l') : null;
};

/****
 * prepare counties list for country selector
 * Lazy load the JSON file
 */
export const getOptionsForCountrySelector = async () => {
  // @ts-ignore
  const getCountries = () => import('../const/countries.json').then((m) => m.default || m);
  let countries: Array<BeyCountrySelectorOptionInterface> = [...(await getCountries())];

  if (environment.name !== ENV_NAMES.PROD) {
    countries.unshift({
      label: 'Test Code',
      value: '+800',
    });
  }

  return countries;
};

export const getSupportedOptionsForCountrySelector = () => {
  let countries: Array<BeyCountrySelectorOptionInterface> = [
    {
      label: 'Uganda',
      value: '+256',
      isoCode: 'ug',
    },
  ];

  return countries;
};

/******
 * Get the user location based on their IP Address and External service provider
 */
export const getUserLocation = async () => {
  try {
    return await (
      await fetch('https://api.ip.sb/geoip', {
        method: 'GET',
      })
    ).json();
  } catch (e) {
    console.error(e);
  }
};

/******
 * WEB OTP helper function
 * @param callback
 */
export const otpRequest = async (callback) => {
  if ('OTPCredential' in window) {
    const abortController = new AbortController();
    const timer = setTimeout(() => {
      abortController.abort();
      clearTimeout(timer);
    }, 10 * 1000);

    const content: any = await window.navigator['credentials'].get({
      otp: { transport: ['sms'] },
      signal: abortController.signal,
    });

    callback(content.code);
  }
};

/******
 * Detect if the user is on mobile using user agent
 */
export const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

/****
 *  Check if the device is running on iOS
 */
export const checkIsIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

/*****
 *  Format number ex 1000 1k
 */
export const formatNumber = (value) => {
  const format = COUNT_FORMATS.find((format) => value < format.limit);

  value = (1000 * value) / format.limit;
  value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

  return value + format.letter;
};

/******
 * Load the recaptcha script from Google using proper environment key
 */
export const loadRecaptchaScript = (): void => {
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${environment.RECAPTCHA_V3_SITE_KEY}`;
  script.defer = true;
  document.head.appendChild(script);
};

/*****
 * Generate and return Recaptcha token
 */
export const initRecaptcha = (action: 'user_register' | 'user_login' | 'reset_pin_with_id'): Promise<string> => {
  let retries: number = 0;
  let intervalId: NodeJS.Timeout;
  const retryInterval: number = 2000;

  const loader = (resolve: (value: string | PromiseLike<string>) => void, reject: (reason?: any) => void) => {
    if (window.grecaptcha && window.grecaptcha.enterprise) {
      window.grecaptcha.enterprise.ready(() =>
        window.grecaptcha.enterprise.execute(environment.RECAPTCHA_V3_SITE_KEY, { action }).then((token) => {
          if (token) {
            return resolve(token);
          }
          reject('Error generating reCAPTCHA token.');
        })
      );

      intervalId && clearTimeout(intervalId);
    } else {
      if (retries < 4) {
        retries++;
        console.info(`Retrying attempt ${retries} to load reCAPTCHA library.`);

        intervalId = setTimeout(() => loader(resolve, reject), retryInterval);
      } else {
        clearTimeout(intervalId);
        reject('Max retries exceeded. Unable to load reCAPTCHA library.');
      }
    }
  };

  return new Promise((resolve, reject) => loader(resolve, reject));
};

/*****
 *  Extract value from object by path
 */

export const objectExtract = (stringPath: string, baseObject: object): any =>
  stringPath.split('.').reduce((p, q) => (p ? p[q] : null), baseObject || self);

export const getFormattedNumber = (number: number, removeDecimals: boolean = false): string => {
  return new Intl.NumberFormat().format(removeDecimals ? getTruncatedNumber(number) : number);
};

export const getTruncatedNumber = (number: number): number => {
  // removes number's decimals
  return Math.trunc(number);
};

/***
 * Logger function for DEV and test ONLY!
 * @param data
 */
export const logger = (...data) => {
  if (environment.name === ENV_NAMES.STAGING || environment.name === ENV_NAMES.DEV) {
    console.log(...data);
  }
};

/*****
 *  Load Facebook SDK SCRIPT when needed on share drawer
 */
export const loadFacebookSDKScript = (): void => {
  const script = document.createElement('script');
  script.src = `https://connect.facebook.net/en_US/sdk.js`;
  document.head.appendChild(script);

  window.fbAsyncInit = function () {
    window.FB.init({
      appId: '1711731272524786',
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v14.0',
    });
  };
};

/***
 * Prepare link content
 * @param type
 * @param first_name
 * @param link
 * @param currency
 * @param amount
 * @param desc
 * @param active_merchant_name
 * @param beyonicLink
 */
export const beyLinkSharableTextContent = (
  type: 'twitter' | 'text' | 'whatsapp',
  {
    first_name,
    link,
    currency,
    amount,
    desc,
    active_merchant_name,
  }: {
    first_name?: string;
    link: string;
    currency: string;
    amount: number | string;
    desc: string;
    active_merchant_name?: string;
  },
  beyonicLink?: boolean
) => {
  let message: string = '';

  if (type !== 'twitter') {
    if (type === 'whatsapp' && beyonicLink) {
      message += `Click the link below to pay `;

      if (amount) {
        message += `${currency} ${Number(amount)} `;
      }

      message += `for ${desc} From ${active_merchant_name}\n\n${link}\n\nFrom Beyonic by Onafriq`;

      return encodeURI(message);
    } else {
      if (first_name) {
        message += `Hey ${first_name}`;
      } else {
        message += `Hello`;
      }

      message += `,\n\nClick ${link} to pay `;

      if (amount) {
        message += `${currency} ${Number(amount)} `;
      }

      message += `for ${desc || active_merchant_name}.\n\nFrom Beyonic by Onafriq`;

      return encodeURI(message);
    }
  } else {
    message += `https://twitter.com/${
      checkIsIOS() ? 'intent/tweet' : 'messages/compose'
    }?text=Click ${link} to pay ${active_merchant_name}`;

    if (amount) {
      message += ` ${currency} ${Number(amount)}`;
    }

    if (desc) {
      message += ` for ${desc}`;
    }

    message += `.\n\nFrom Beyonic by Onafriq`;

    return encodeURI(message);
  }
};

/****
 * Generate content for Beyonic receipt sharing functionality
 * @param type
 * @param link
 * @param desc
 */
export const beyReceiptSharableContent = (type: 'twitter' | 'text', link: string, desc: string): string => {
  if (type !== 'twitter') {
    return encodeURI(`Hello,\n\nClick ${link} to view the receipt for ${desc}.\n\nFrom Beyonic by Onafriq`);
  } else {
    return encodeURI(
      `https://twitter.com/${
        checkIsIOS() ? 'intent/tweet' : 'messages/compose'
      }?text=Click ${link} to view the receipt for ${desc}.\n\nFrom Beyonic by Onafriq`
    );
  }
};

/*****
 *  retrieve limit and offset values from next link
 */
export const retrieveLimitAndOffsetValues = (next: string): { limit: string; offset: string } => {
  return {
    limit: next?.slice(next.indexOf('limit=') + 6, next.indexOf('&offset')),
    offset: next?.slice(next.indexOf('offset=') + 7, next.indexOf('&ordering')),
  };
};

/******
 *  This method is used to disable auto zoom on input fields for iOS devices
 */
export const addMaximumScaleToMetaViewport = () => {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content');
    let re = /maximum\-scale=[0-9\.]+/g;

    if (re.test(content)) {
      content = content.replace(re, 'maximum-scale=1.0');
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ');
    }

    el.setAttribute('content', content);
  }
};

/*****
 * Monitor and log network information to FullStory
 */
export const logNetworkInfo = () => {
  if (navigator.connection) {
    const connection = navigator.connection;
    let type = connection?.['effectiveType'];

    setVars('page', {
      networkType: type,
      networkSpeed: `${connection?.['downlink']} Mbps`,
    });

    // Report network events to FS
    connection.addEventListener('change', () => {
      type = connection?.['effectiveType'];
      // Emit network change event to FS
      event('NETWORK_TYPE_CHANGE', {
        type,
      });
    });
  } else {
    console.info('Network Information API not supported');
  }
};

/******
 *  Generate QR Code string lazily
 *  It should be used with <img />
 *  More info https://www.npmjs.com/package/qrcode
 * @param str
 * @param opts
 */
export const generateQrCode = (str: string, opts?: object): Promise<string> => {
  return new Promise((resolve: (value: string) => void, reject: (reason: string) => void) => {
    import('qrcode').then(async (m) => {
      try {
        const v: string = await m.toDataURL(str, opts);
        resolve(v);
      } catch (e: any) {
        console.error(e);
        reject('Error generating QR code');
      }
    });
  });
};

/*****
 * Get country info from phone number
 * This method supports test numbers as well
 * @param phone
 */
export const countryFromPhone = (phone: string): BeyonicCountry => {
  // Those are for DEV and TEST only (STAGING)
  const countryLabelMap: Record<string, string> = {
    '+80010': 'UG',
    '+80011': 'KE',
    '+80012': 'TZ',
    '+80013': 'CD',
    '+80014': 'RW',
    '+80015': 'GH',
  };
  const testNumIsoCode: string = countryLabelMap[phone.substring(0, 6)];

  if (testNumIsoCode && environment.name !== ENV_NAMES.PROD) {
    return retrieveOperatingCountry(testNumIsoCode, 'isoCode');
  }

  try {
    const parsedPhone = parsePhoneNumber(phone);
    return retrieveOperatingCountry(`+${parsedPhone.countryCallingCode}`, 'countryCode');
  } catch (e) {
    console.error(e);
    return null;
  }
};

/*****
 * Returns a phone number to be used in transactionAmountValidation validator
 * when test numbers are being used
 * @param phone
 * @param isoCode
 */
export const retrievePhoneNumberFromIsoCode = (phone: string, isoCode: string): string => {
  if (environment.production) {
    return phone;
  } else {
    switch (isoCode) {
      case 'UG':
        return '+256766666666';
      case 'CD':
        return '+243999999999';

      default:
        return '+256766666666';
    }
  }
};

/*****
 * Returns the snippet to be copied for embedded button for Beyonic or static link
 * @param link
 */
export const retrieveLinkEmbeddedButton = (link: string): string => {
  return `<a href="${link}" target="_blank" style="box-shadow: 1.5px 1.5px 3px 0px #AEAEC066; background: #F0F0F3; padding: 9px 60px; color: #006FAB; line-height: 14px; font-size: 14px; font-weight: 600; border-radius: 4px">Pay now</a>`;
};

/*****
 * Returns an object that contains the query params in object
 * @param params
 */
export const retrieveQueryParamsAsObject = (params: URLSearchParams): object => Object.fromEntries(params);

/*****
 * Returns the placeholder and color according to the name check score for bey-tag component
 * @param nameCheckScore
 */
export const retrieveNameCheckAttributes = (
  nameCheckScore: number,
  phone_is_mm_registered: boolean
): BeyTagAttributes => {
  // rare case of null nameCheckScore reassigns it to 0
  const _nameCheckScore = nameCheckScore || 0;

  // phone_is_mm_registered must equal false to be unregistered. If null, consider it registered
  if (phone_is_mm_registered === false) {
    return { placeholder: 'Unregistered', color: 'red' };
  } else if (_nameCheckScore < 40) {
    return { placeholder: `${_nameCheckScore}% match`, color: 'red' };
  } else if (_nameCheckScore < 80) {
    return { placeholder: `${_nameCheckScore}% match`, color: 'orange' };
  } else {
    return { placeholder: `${_nameCheckScore}% match`, color: 'green' };
  }
};

/*****
 *  Get clipboard contents from a paste event
 * @param e
 */
export const clipboardContents = async (e: ClipboardEvent): Promise<string> => {
  let contents: string = '';

  try {
    const clipboardData = e.clipboardData || window?.['clipboardData'];
    const pastedData = clipboardData.getData('text/html');
    const blob = new Blob([pastedData], { type: 'text/html' });

    contents = await blob.text();
  } catch (e) {
    console.error(e);
    throw Error(e);
  }

  return contents;
};
