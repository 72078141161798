<div class="flex flex-col items-center mt-[2px] mb-[20px]">
  <div
    class="w-[46px] h-[46px] mb-[30px] flex items-center justify-center bg-[url('assets/images/icons/blue-circle.svg')]">
    <img height="20" width="20" src="assets/images/icons/message-chat-square.svg" />
  </div>
  <span class="font-bold text-grey-700 mb-[11px]">{{ title }} this link?</span>
  <span class="font-normal text-grey-700 mb-[11px]">You can {{ subTitle }} this link later</span>
  <span *ngIf="beyonicLink['is_active']" class="font-normal text-grey-700 mb-[19px] text-center"
    >Note: If you have shared this link with anyone they will not be able to use it.</span
  >
  <mat-dialog-actions>
    <bey-button
      [disabled]="updateBeyonicLinkIsLoading$ | async"
      class="mr-3"
      placeholder="Cancel"
      color="dark-blue-outlined"
      [small]="true"
      [mat-dialog-close]="true"></bey-button>
    <bey-button
      [disabled]="updateBeyonicLinkIsLoading$ | async"
      [placeholder]="'Yes, ' + actionButton + 'the link'"
      color="dark-blue"
      (onClick)="onClick()"
      [small]="true"></bey-button>
  </mat-dialog-actions>
</div>
