<div class="flex flex-col">
  <span data-test-id="header" class="font-bold text-grey-600 mb-3">Total amount</span>
  <span data-test-id="amount" class="text-lg font-bold">
    {{
      _totalInfo?.amount
        | applyRoundingRules : country : 'collection' : false
        | currency : _totalInfo?.currency + ' ' : 'symbol' : currencyPipeDigitalInfo
    }}
  </span>
</div>
