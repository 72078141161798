import { createAction, props } from '@ngrx/store';
import { User } from '../user/user.interfaces';
import { authTypes } from './auth.types';
import {
  ErrorType,
  LoginRequestBody,
  ResetUserPinRequestBody,
  SendVerificationCodeRequestBody,
  SignupRequestBody,
  SignupResponseBody,
  Tokens,
  VerifyVerificationCodeRequestBody,
} from './auth.interfaces';

//------------SIGNUP------------
export const signupStart = createAction(authTypes.SIGNUP_START, props<{ payload: SignupRequestBody }>());
export const signupSuccess = createAction(authTypes.SIGNUP_SUCCESS, props<{ payload: SignupResponseBody }>());
export const signupFailure = createAction(authTypes.SIGNUP_FAILURE, props<ErrorType>());
//------------LOGIN------------
export const loginStart = createAction(authTypes.LOGIN_START, props<{ payload: LoginRequestBody }>());
export const loginSuccess = createAction(
  authTypes.LOGIN_SUCCESS,
  props<{ payload: { tokens: Tokens; currentUser: User; phone: string; redirectPath?: string } }>()
);
export const loginFailure = createAction(authTypes.LOGIN_FAILURE, props<ErrorType>());
//------------SEND VERIFICATION CODE------------
export const sendVerificationCodeStart = createAction(
  authTypes.SEND_VERIFICATION_CODE_START,
  props<{ payload: SendVerificationCodeRequestBody }>()
);
export const sendVerificationCodeSuccess = createAction(authTypes.SEND_VERIFICATION_CODE_SUCCESS);
export const sendVerificationCodeFailure = createAction(authTypes.SEND_VERIFICATION_CODE_FAILURE, props<ErrorType>());
//------------VERIFY VERIFICATION CODE------------
export const verifyVerificationCodeStart = createAction(
  authTypes.VERIFY_VERIFICATION_CODE_START,
  props<{ payload: VerifyVerificationCodeRequestBody }>()
);
export const verifyVerificationCodeSuccess = createAction(authTypes.VERIFY_VERIFICATION_CODE_SUCCESS);
export const verifyVerificationCodeFailure = createAction(
  authTypes.VERIFY_VERIFICATION_CODE_FAILURE,
  props<ErrorType>()
);
//------------RESET USER PIN------------
export const resetUserPinStart = createAction(
  authTypes.RESET_USER_PIN_START,
  props<{
    payload: {
      requestBody: ResetUserPinRequestBody;
      phone: string;
    };
  }>()
);
export const resetUserPinSuccess = createAction(authTypes.RESET_USER_PIN_SUCCESS);
export const resetUserPinFailure = createAction(authTypes.RESET_USER_PIN_FAILURE, props<ErrorType>());
//------------OTHERS------------
export const empty = createAction(authTypes.EMPTY);

export const logOut = createAction(authTypes.LOG_OUT);

export const updateToken = createAction(authTypes.UPDATE_TOKEN, props<{ payload: string }>());

export const lockAccount = createAction(authTypes.LOCK_ACCOUNT);

export const unlockAccount = createAction(authTypes.UNLOCK_ACCOUNT);

export const setCurrentRoute = createAction(authTypes.SET_CURRENT_ROUTE, props<{ payload: string }>());

export const setLoginIsLoadingToFalse = createAction(authTypes.SET_LOGIN_IS_LOADING_TO_FALSE);
