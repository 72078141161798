import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Operation } from '../../interfaces/operation.interface';
import { Store } from '@ngrx/store';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CONST_FUNCTION_CAROUSEL_2_SEEN_KEY } from 'src/assets/const';
import { BeyFunctionalityCarouselComponent } from 'src/app/modules/core/components/bey-functionality-carousel/bey-functionality-carousel.component';
import { GetElementIdDirective } from '../../../shared/directives/get-element-id.directive';

@Component({
  selector: 'home-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss'],
  standalone: true,
  imports: [GetElementIdDirective],
})
export class OperationComponent implements OnInit {
  @Input() operation: Operation;

  constructor(private router: Router, private store$: Store, private dialog: MatDialog) {}

  ngOnInit(): void {}

  clickHandler(): void {
    this.showOneTimeFeatureCarousel();

    this.router.navigate(
      [this.operation.destination],
      this.operation.state ? { state: { [this.operation.state]: true } } : {}
    );
  }

  showOneTimeFeatureCarousel() {
    //  verify not seen this before
    const seenFeatureCarouselBefore = localStorage.getItem(CONST_FUNCTION_CAROUSEL_2_SEEN_KEY);

    if (!!!seenFeatureCarouselBefore) {
      this.dialog.open(BeyFunctionalityCarouselComponent, {
        width: '95vw',
        maxWidth: 'unset',
        backdropClass: 'custom-dialog-backdrop',
        disableClose: true,
        data: {
          type: 2,
        },
      });
    }
  }
}
