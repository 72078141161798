import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecentCollectionsPage } from './pages/recent-collections/recent-collections.page';
import { CreateCollectionPage } from './pages/create-collection/create-collection.page';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: RecentCollectionsPage,
        data: {
          title: 'Collections',
          background: '#F9F9F9',
        },
      },
      {
        path: 'create-collection',
        component: CreateCollectionPage,
        data: {
          title: 'Collections',
          description: 'Receiving a payment is now easier than ever. Simply create a link and share it.',
          animation: 'FadeIn',
          background: '#F9F9F9',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CollectionsRoutingModule {}
