<div class="px-4 py-10 flex justify-center flex-col text-center">
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" class="mx-auto mb-6">
    <circle cx="23" cy="23" r="23" fill="#99DBFF" />
    <circle cx="22.9997" cy="23.0002" r="18.8806" fill="#CAECFF" />
    <path
      d="M23.0001 29.2481H13.4631C12.4624 29.2525 11.5355 28.818 11.0342 28.1094C10.533 27.4007 10.5342 26.5267 11.0376 25.819L15.8061 19.069L20.5746 12.319C21.0766 11.6119 22.0006 11.1768 23.0001 11.1768C23.9996 11.1768 24.9236 11.6119 25.4256 12.319L30.1941 19.069L34.9626 25.819C35.4659 26.5267 35.4672 27.4007 34.9659 28.1094C34.4647 28.818 33.5378 29.2525 32.5371 29.2481H23.0001Z"
      fill="#006FAB" />
    <path
      d="M22.9999 26.8057C23.8838 26.8057 24.6004 26.1341 24.6004 25.3057C24.6004 24.4772 23.8838 23.8057 22.9999 23.8057C22.116 23.8057 21.3994 24.4772 21.3994 25.3057C21.3994 26.1341 22.116 26.8057 22.9999 26.8057Z"
      fill="white" />
    <path d="M22.8848 17.2466V21.6206" stroke="white" stroke-width="2" stroke-linecap="round" />
  </svg>
  <div class="text-grey-800 text-2xl font-medium leading-[28px]">
    <span> Your profile is being reviewed. </span>
  </div>

  <div class="text-grey-800 leading-[19px] my-6">Thank you for your understanding</div>

  <mat-dialog-actions class="mb-[-15px]" align="center">
    <bey-button [mat-dialog-close]="true" placeholder="Got it" color="dark-blue" classList="px-10 mx-auto"></bey-button>
  </mat-dialog-actions>
</div>
