<footer
  class="root_footer flex justify-around w-full bg-white rounded-tr-md rounded-tl-md fixed bottom-0 z-30 lg:hidden">
  <a
    getElementId
    getElementIdDescription="home"
    routerLink="/"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    class="text-black min-w-[65px]">
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9998 3.75L4.0498 11.25V24.195C4.0821 25.3338 5.01059 26.2424 6.1498 26.25H11.4298V15.645H18.1198V26.25H23.8048C24.3763 26.25 24.9242 26.022 25.3269 25.6165C25.7296 25.2109 25.9538 24.6615 25.9499 24.09V11.19L14.9998 3.75Z"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>

    <span>Home</span>
  </a>
  <a
    getElementId
    getElementIdDescription="transactions"
    class="text-black min-w-[65px]"
    routerLink="/transactions"
    routerLinkActive="active">
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.3 11.2649V26.4449" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.7002 16.6648V26.4448" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15 3.16479V26.4448" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <span>History</span>
  </a>
  <a
    getElementId
    getElementIdDescription="links"
    class="text-black min-w-[65px]"
    routerLink="/links"
    routerLinkActive="active">
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6667 15.167C12.1677 15.8368 12.8069 16.391 13.541 16.792C14.275 17.1931 15.0867 17.4316 15.9211 17.4913C16.7554 17.5511 17.5928 17.4307 18.3766 17.1384C19.1603 16.846 19.872 16.3885 20.4633 15.797L23.9633 12.297C25.0259 11.1968 25.6139 9.72326 25.6006 8.19378C25.5873 6.6643 24.9738 5.20122 23.8923 4.11968C22.8107 3.03813 21.3476 2.42464 19.8182 2.41135C18.2887 2.39806 16.8152 2.98603 15.715 4.04862L13.7083 6.04362M16.3333 12.8336C15.8323 12.1638 15.1931 11.6096 14.459 11.2085C13.725 10.8075 12.9132 10.569 12.0789 10.5092C11.2446 10.4495 10.4071 10.5699 9.62342 10.8622C8.8397 11.1546 8.12802 11.612 7.53666 12.2036L4.03666 15.7036C2.97407 16.8038 2.3861 18.2773 2.39939 19.8068C2.41268 21.3363 3.02617 22.7993 4.10771 23.8809C5.18926 24.9624 6.65233 25.5759 8.18181 25.5892C9.7113 25.6025 11.1848 25.0145 12.285 23.952L14.28 21.957"
        stroke="#4D4D4B"
        stroke-width="2.8"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>

    <span>Links</span>
  </a>
  <a
    getElementId
    getElementIdDescription="profile"
    class="text-black min-w-[65px]"
    routerLink="/profile"
    routerLinkActive="active">
    <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6199 25.128C20.5331 25.128 26.1374 19.9058 26.1374 13.464C26.1374 7.02214 20.5331 1.79999 13.6199 1.79999C6.70671 1.79999 1.10245 7.02214 1.10245 13.464C1.10245 19.9058 6.70671 25.128 13.6199 25.128Z"
        stroke="#2F2F2E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M13.6199 13.144C15.4786 13.144 16.9854 11.74 16.9854 10.008C16.9854 8.27604 15.4786 6.87201 13.6199 6.87201C11.7612 6.87201 10.2545 8.27604 10.2545 10.008C10.2545 11.74 11.7612 13.144 13.6199 13.144Z"
        stroke="#2F2F2E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M6.64859 22.696V20.104C6.79441 18.471 8.32959 17.255 10.0827 17.384H17.1227C18.8728 17.264 20.4021 18.4753 20.5569 20.104V22.696"
        stroke="#2F2F2E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <span>Profile</span>
  </a>
  <div
    class="bar"
    [ngClass]="{
      block: routes.includes(currentRoute$ | async),
      hidden: !routes.includes(currentRoute$ | async)
    }">
    &nbsp;
  </div>
</footer>
