<div
  class="h-[170px] w-full rounded-lg flex flex-col justify-between py-4 pl-5 mb-6 pr-10 bg-gradient-to-r from-blue-400 to-blue-600 relative">
  <div class="flex flex-col">
    <span class="title mb-3">Balance</span>
    <div
      *ngIf="getUserWalletIsLoading$ | async; else notLoading"
      class="bg-gray-400 animate-pulse rounded-lg h-3 w-20"></div>
    <ng-template #notLoading>
      <div class="flex items-end">
        <span id="user_wallet_balance" class="amount text-3xl" [ngClass]="{ 'hidding-block': hidden }">{{
          ((userWallet$ | async)?.balance
            | applyRoundingRules : country : 'collection'
            | currency : ' ' : 'symbol' : '1.0-0') || 0
        }}</span
        ><span data-test-id="balance-currency" class="currency">{{ userWalletCurrency$ | async }}</span>
      </div>
    </ng-template>
  </div>
  <div class="flex items-center justify-between w-full">
    <div class="flex flex-col">
      <span class="title">Incoming</span>
      <div class="flex items-end">
        <span data-test-id="incoming" class="amount text-xl" [ngClass]="{ 'hidding-block': hidden }">{{
          ((userWallet$ | async)?.daily_transactions_summary?.incoming
            | applyRoundingRules : country : 'collection'
            | currency : ' ' : 'symbol' : '1.0-0') || 0
        }}</span
        ><span data-test-id="incoming-currency" class="currency">{{ userWalletCurrency$ | async }}</span>
      </div>
    </div>
    <div class="flex flex-col">
      <span class="title">Outgoing</span>
      <div class="flex items-end">
        <span data-test-id="outgoing" class="amount text-xl" [ngClass]="{ 'hidding-block': hidden }">{{
          ((userWallet$ | async)?.daily_transactions_summary?.outgoing
            | removeMinusSign
            | applyRoundingRules : country : 'payment' : true
            | currency : ' ' : 'symbol' : '1.0-0') || 0
        }}</span
        ><span data-test-id="outgoing-currency" class="currency">{{ userWalletCurrency$ | async }}</span>
      </div>
    </div>
  </div>
  <svg
    *ngIf="!hidden"
    class="absolute top-[75px] right-[20px] cursor-pointer"
    (click)="onEyeClick()"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9811 14.1469C12.6128 15.1006 10.9564 15.5511 9.29355 15.4219C6.40662 15.0917 3.9353 13.2004 2.8623 10.5C3.58996 8.85345 4.8295 7.48568 6.39668 6.59998"
      stroke="white"
      stroke-width="1.4"
      stroke-linecap="round" />
    <path
      d="M9.33105 5.73747C12.6489 5.64424 15.6607 7.66587 16.8311 10.7718C16.5308 11.4532 16.1294 12.0853 15.6404 12.6468"
      stroke="white"
      stroke-width="1.4"
      stroke-linecap="round" />
    <path
      d="M11.6374 11.5875C11.2666 12.0907 10.678 12.3867 10.053 12.3845C9.229 12.3889 8.48849 11.8822 8.19436 11.1124C7.90022 10.3427 8.11409 9.47119 8.73115 8.92505"
      stroke="white"
      stroke-width="1.4"
      stroke-linecap="round" />
    <path d="M3.33105 3.1875L16.0154 15.8719" stroke="white" stroke-width="1.4" stroke-linecap="round" />
  </svg>
  <svg
    *ngIf="hidden"
    class="absolute top-[75px] right-[20px] cursor-pointer"
    (click)="onEyeClick()"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.8404 9.8971C15.5642 12.8721 12.5739 14.7411 9.34043 14.5846C6.42712 14.264 3.93205 12.3539 2.8623 9.62523C3.9671 6.9568 6.45592 5.11724 9.33105 4.84398C12.6571 4.74953 15.6752 6.78041 16.8404 9.8971Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round" />
    <path
      d="M9.85615 11.697C10.9538 11.697 11.8437 10.8072 11.8437 9.70955C11.8437 8.61188 10.9538 7.72205 9.85615 7.72205C8.75849 7.72205 7.86865 8.61188 7.86865 9.70955C7.86865 10.8072 8.75849 11.697 9.85615 11.697Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round" />
  </svg>
</div>
