<div
  class="fixed inset-0 h-full w-full lg:bg-[#00456BB2] transition-all lg:backdrop-blur-[10px] bg-red-200 ease-in-out duration-700 bg"
  [ngClass]="{
    'lg:hidden': hideOnWeb,
    'z-50 opacity-1': (isOpen$ | async),
    '-z-1 opacity-0': !(isOpen$ | async)
  }"
  *ngIf="isOpen$ | async"
  (click)="miscService.closeDrawer()"></div>
<div
  class="fixed left-0 bg-white ease-in-out duration-500 bottom-0 w-full h-fit rounded-t-[16px] lg:rounded-[26px] lg:w-6/12 xl:w-4/12 lg:inset-x-0 lg:top-1/2 lg:mx-auto lg:-translate-y-1/2 lg:pt-[24px] lg:pb-[30px] lg:px-10"
  [ngClass]="{
    'lg:hidden': hideOnWeb,
    'translate-y-0 z-[90] lg:opacity-1': (isOpen$ | async),
    '-z-1 translate-y-full lg:opacity-0': !(isOpen$ | async)
  }">
  <ng-content *ngFor="let content of contents.toArray()"></ng-content>
</div>
