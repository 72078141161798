import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RecentPaymentItem, RecentPaymentResponse } from '../payments/payments.interfaces';
import {
  BulkPayment,
  CreateBulkPaymentRequestBody,
  GetUserBulkPaymentsResponse,
  AddRecipientsToBulkPaymentRequestBody,
  BulkPaymentItem,
  GetRecipientsAssociatedToBulkPaymentResponseType,
  ValidateBulkPaymentRequestBody,
  ValidateBulkPaymentResponseBody,
} from './bulk-payments.interfaces';

@Injectable({
  providedIn: 'root',
})
export class BulkPaymentsService {
  constructor(private http: HttpClient) {}

  processBulkPayment(id: number) {
    return this.http.post(`${environment.API_URL}payments/bulk/${id}/process/`, {});
  }

  createBulkPayment(body: CreateBulkPaymentRequestBody) {
    return this.http.post<BulkPayment>(`${environment.API_URL}payments/bulk/`, body);
  }

  getBulkPayment(id: number) {
    return this.http.get<BulkPayment>(`${environment.API_URL}payments/bulk/${id}/`);
  }

  getRecipientsAssociatedToBulkPayment(id: number) {
    return this.http.get<GetRecipientsAssociatedToBulkPaymentResponseType>(
      `${environment.API_URL}payments/bulk/${id}/payments/`
    );
  }

  addRecipientsToBulkPayment(id: number, payments: AddRecipientsToBulkPaymentRequestBody['payments']) {
    return this.http.patch(`${environment.API_URL}payments/bulk/${id}/`, { payments });
  }

  validate(body: ValidateBulkPaymentRequestBody) {
    const { payments, business_id } = body;
    // Making sure that the phone is a string
    const _payments: Array<BulkPaymentItem> = payments.map((vals: BulkPaymentItem) => {
      let _phone: string | { code: string; phone_number: string } = vals.phone;

      if (typeof vals.phone === 'object') {
        _phone = _phone['code'] + _phone['phone_number'];
      }

      return {
        ...vals,
        phone: _phone as string,
      };
    });

    return this.http.post<ValidateBulkPaymentResponseBody>(`${environment.API_URL}payments/bulk/validate/`, {
      business_id,
      payments: _payments,
    });
  }

  updatePaymentForBulkPayment(
    paymentId: number,
    bulkPaymentId: number,
    payment: AddRecipientsToBulkPaymentRequestBody['payments'][number] & { description: string }
  ) {
    return this.http.patch<RecentPaymentItem>(
      `${environment.API_URL}payments/bulk/${bulkPaymentId}/payments/${paymentId}/update/`,
      {
        ...payment,
      }
    );
  }

  deletePaymentForBulkPayment(paymentId: number, bulkPaymentId: number) {
    return this.http.delete<void>(`${environment.API_URL}payments/bulk/${bulkPaymentId}/payments/${paymentId}/`);
  }

  getUserBulkPaymentsSummary() {
    return this.http.get<GetUserBulkPaymentsResponse['summary']>(`${environment.API_URL}payments/bulk/summary/`);
  }

  getBulkPayments(filters: object) {
    let params = new HttpParams();

    if (filters) {
      Object.keys(filters).forEach((key) => {
        const val = filters[key];
        params = params.append(key, val);
      });
    }

    params = params.append('ordering', '-modified');
    return this.http.get<GetUserBulkPaymentsResponse>(`${environment.API_URL}payments/bulk/`, { params });
  }

  getMoreUserBulkPayments(paramsValues: object) {
    let params = new HttpParams();

    Object.keys(paramsValues).forEach((key) => {
      const val = paramsValues[key];
      if (!!val || val === false) {
        // this way a param with the value of false should pass
        params = params.append(key, val);
      }
    });

    params = params.append('ordering', '-modified');

    return this.http.get<GetUserBulkPaymentsResponse>(`${environment.API_URL}payments/bulk/`, { params });
  }

  getBulkPaymentInfo(bulkPaymentId: number) {
    return this.http.get<BulkPayment>(`${environment.API_URL}payments/bulk/${bulkPaymentId}/`);
  }

  getPaymentsForBulkPayment(bulkPaymentId: number) {
    return this.http.get<RecentPaymentResponse>(`${environment.API_URL}payments/bulk/${bulkPaymentId}/payments`);
  }
}
