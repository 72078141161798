<div class="fixed top-0 w-full z-50">
  <div class="relative bg-blue-600 h-[55px] text-white flex items-center justify-between px-[3px] lg:hidden">
    <svg class="absolute" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="-1" y="9" width="20" height="20" rx="4" transform="rotate(-30 -1 9)" fill="#A1D1F5" fill-opacity="0.1" />
    </svg>
    <img
      src="assets/images/logo/full-white.svg"
      routerLink="/"
      class="cursor-pointer p-1.5 w-[110px]"
      width="155"
      alt="beyonic" />
    <span
      *ngIf="!(currentUser$ | async) && (currentRoute$ | async) !== '/suspended'; else nameInit"
      class="text-sm mr-4 text-right">
      Already have an account?
      <button class="ml-3 h-[24px] underline leading-[16px]" (click)="login()">Login</button>
    </span>
    <ng-template #nameInit>
      <a
        *ngIf="(currentRoute$ | async) !== '/suspended' && (userKyc$ | async)?.first_name"
        routerLink="/profile"
        class="bg-blue-light rounded-full p-0.5 cursor-pointer text-white font-medium text-base min-w-[32px] min-h-[32px] flex items-center justify-center mr-[11px]">
        {{ (userKyc$ | async)?.first_name + ' ' + (userKyc$ | async)?.last_name | nameInitials }}
      </a>
    </ng-template>

    <svg class="absolute" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="-0.580078"
        y="28.6084"
        width="30"
        height="30"
        rx="4"
        transform="rotate(-77.3939 -0.580078 28.6084)"
        fill="#DFE7FC"
        fill-opacity="0.1" />
    </svg>
  </div>
  <pending-kyc-banner class="lg:hidden"></pending-kyc-banner>
</div>
