export const environment = {
  production: true,
  name: 'PROD',
  URL: 'https://m.beyonic.com',
  API_URL: 'https://api.beyonicservices.com/',
  RECAPTCHA_V3_SITE_KEY: '6LeoOQslAAAAAAbCO-svHfU5365AbhZxxyjogNEh',
  GTM_KEY: 'GTM-5W8T2J6',
  is_pay: false,
  PAY_URL: 'https://pay.beyonic.com',
};
