<div class="px-3 pt-4 lg:pl-10">
  <div class="flex justify-between max-w-5xl lg:mt-[55px] lg:ml-10">
    <div class="w-full max-w-lg mx-auto lg:mx-0">
      <app-page-info class="hidden lg:block"></app-page-info>
      <bey-page-header class="lg:hidden"></bey-page-header>

      <div class="w-full my-6 rounded-lg bg-white border border-grey-100">
        <bey-create-collection-form collectionType="prompt"></bey-create-collection-form>

        <div class="xl:hidden px-3.5 pb-6">
          <div class="mb-2 mt-12x text-grey-800 font-medium text-lg">How do prompts work?</div>
          <div class="text-grey-600 text-justify">
            A request will be sent to the mobile number provided above to authorize and complete this transaction. The
            receiver of the request must have sufficient funds in their mobile money to successfully complete this
            transaction.
          </div>
        </div>
      </div>
    </div>
    <bey-feature-info-card
      header="How do prompts work?"
      body="A request will be sent to the mobile number provided  to authorize and complete this transaction. The receiver of the request must have sufficient funds in their mobile money to successfully complete this transaction.">
    </bey-feature-info-card>
  </div>
</div>
