<div class="transaction-item w-full grid grid-cols-9 pb-2 mb-2.5 border-b border-[#E5E5E5] lg:justify-between">
  <div class="flex flex-col col-span-4">
    <div class="flex items-center mb-1">
      <span
        class="text-[#4D4D4B] text-base font-normal pr-1 max-w-[160px] whitespace-nowrap text-ellipsis overflow-hidden lg:max-w-[190px]"
        >{{ collection?.contact?.first_name | titlecase }} {{ collection?.contact?.last_name | titlecase }}</span
      >
    </div>
    <span class="text-[#2F2F2E] text-base font-medium whitespace-nowrap">{{
      collection?.amount | currency : collection.amount_currency + ' '
    }}</span>
  </div>
  <img
    *ngIf="collection?.related_transaction?.xb_fx_details?.is_xb_collection; else empty"
    class="ml-1 h-4 w-4 lg:h-5 lg:w-5"
    src="/assets/images/green-globe.png" />
  <ng-template #empty>
    <div></div>
  </ng-template>
  <span
    [class]="
      'flex items-center text-sm font-normal mr-3 col-span-2 ml-0 lg:text-base text-bey-' +
      collectionColorAndStatus?.color
    "
    >{{ collectionColorAndStatus?.status }}</span
  >
  <div class="flex items-center justify-between col-span-2">
    <span class="text-sm font-normal text-grey-800 whitespace-nowrap mr-1 lg:text-base">{{
      collection.created | relativeTime : true
    }}</span>
    <a [matMenuTriggerFor]="menu" class="cursor-pointer">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 9.5C8 8.67157 8.67157 8 9.5 8C10.3284 8 11 8.67157 11 9.5C11 10.3284 10.3284 11 9.5 11C8.67157 11 8 10.3284 8 9.5ZM8 3.5C8 2.67157 8.67157 2 9.5 2C10.3284 2 11 2.67157 11 3.5C11 4.32843 10.3284 5 9.5 5C8.67157 5 8 4.32843 8 3.5ZM8 15.5C8 14.6716 8.67157 14 9.5 14C10.3284 14 11 14.6716 11 15.5C11 16.3284 10.3284 17 9.5 17C8.67157 17 8 16.3284 8 15.5Z"
          fill="white" />
        <path
          d="M8 9.5C8 8.67157 8.67157 8 9.5 8C10.3284 8 11 8.67157 11 9.5C11 10.3284 10.3284 11 9.5 11C8.67157 11 8 10.3284 8 9.5ZM8 3.5C8 2.67157 8.67157 2 9.5 2C10.3284 2 11 2.67157 11 3.5C11 4.32843 10.3284 5 9.5 5C8.67157 5 8 4.32843 8 3.5ZM8 15.5C8 14.6716 8.67157 14 9.5 14C10.3284 14 11 14.6716 11 15.5C11 16.3284 10.3284 17 9.5 17C8.67157 17 8 16.3284 8 15.5Z"
          fill="#2F2F2E" />
      </svg>
    </a>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <span (click)="resendCollection()" class="text-blue-400 font-medium text-sm">Resend</span>
      </button>
    </mat-menu>
  </div>
</div>
