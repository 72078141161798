import { createSelector } from '@ngrx/store';
import { AppState } from '../root-store.module';
import { collectionsState } from './collections.interfaces';

const selectCollections = (state: AppState) => {
  return state.collections;
};

//---------1ST LEVEL SELECTORS---------
export const selectUserCollections = createSelector(selectCollections, (state: collectionsState) =>
  state.userCollections ? state.userCollections.results : []
);

export const selectUserNextCollections = createSelector(
  selectCollections,
  (state: collectionsState) => state?.userCollections?.next?.split('/')?.[4]
);

export const selectCollectionInfo = createSelector(
  selectCollections,
  (state: collectionsState) => state.collectionInfo
);

export const selectCollectionsFees = createSelector(
  selectCollections,
  (state: collectionsState) => state.collectionsFees
);

export const selectCollectionLinkInfo = createSelector(
  selectCollections,
  (state: collectionsState) => state.collectionLinkInfo
);

export const selectCollectionsIsLoading = createSelector(
  selectCollections,
  (state: collectionsState) => state.isLoading
);

export const selectCollectionsErrorMessages = createSelector(
  selectCollections,
  (state: collectionsState) => state.errorMessages
);
//---------2ND LEVEL SELECTORS---------
export const selectCreateCollectionIsLoading = createSelector(
  selectCollectionsIsLoading,
  (isLoading) => isLoading['createCollection']
);

export const selectGetUserCollectionsIsLoading = createSelector(
  selectCollectionsIsLoading,
  (isLoading) => isLoading['getUserCollections']
);
export const selectGetUserCollectionsErrorMessage = createSelector(
  selectCollectionsErrorMessages,
  (errorMessages) => errorMessages['getUserCollections']
);

export const selectGetMoreUserCollectionsIsLoading = createSelector(
  selectCollectionsIsLoading,
  (isLoading) => isLoading['getMoreUserCollections']
);

export const selectGetCollectionLinkInfoIsLoading = createSelector(
  selectCollectionsIsLoading,
  (isLoading) => isLoading['getCollectionLinkInfo']
);

export const selectPendingCollectionInfo = createSelector(selectCollections, (state) => state.pendingCollectionInfo);
export const selectPendingCollectionIsLoading = createSelector(
  selectCollectionsIsLoading,
  (isLoading) => isLoading['getPendingCollectionInfo']
);
