import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DocGenRequestBody, getRatesRequestInterface, SnackbarDetailsInterface } from './misc.interface';
import { Store } from '@ngrx/store';
import { toggleDrawer } from './misc.actions';
import { from } from 'rxjs';
import { selectDrawerIsOpen } from './misc.selectors';
import { TransactionLimits, TransactionReceiptResponse } from '../wallets/wallets.interfaces';
import { copyToClipboard, isMobile } from 'src/assets/utility';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { CURRENT_HIGHLIGHTED_FEATURE } from 'src/assets/const';
import dayjs from 'dayjs';
import { HighlightedFeatureDialogContentComponent } from 'src/app/modules/shared/components/highlighted-feature-dialog-content/highlighted-feature-dialog-content.component';

@Injectable({
  providedIn: 'root',
})
export class MiscService {
  constructor(private http: HttpClient, private store$: Store, private dialog: MatLegacyDialog) {}

  generateMedia(body: DocGenRequestBody, type: 'png' | 'pdf') {
    return this.http.post(`${environment.API_URL}web-print/generate/${type}`, body, { responseType: 'blob' });
  }

  getRates(payload: getRatesRequestInterface) {
    const { fromCurrencyIso3, toCurrencyIso3, toCountryIso2, useOpenEndpoint = false } = payload;
    const url = `${environment.API_URL}${useOpenEndpoint ? `collection_fx_rate_open` : 'collection_fx_rate'}/`;
    let params = new HttpParams();
    const _p = {
      from_currency_iso3: fromCurrencyIso3,
      to_currency_iso3: toCurrencyIso3,
      to_country_iso2: toCountryIso2,
    };

    Object.keys(_p).forEach((key) => {
      const val = _p[key];
      val && (params = params.append(key, val));
    });

    return this.http.get(url, { params });
  }

  closeDrawer() {
    /// only close the drawer in case it's open
    this.store$
      .select(selectDrawerIsOpen)
      .subscribe((isOpen) => {
        if (isOpen) {
          this.store$.dispatch(toggleDrawer({ data: null, component: null }));
        }
      })
      .unsubscribe();
  }

  checkUserWalletLimitsAlerts(limits: TransactionLimits): SnackbarDetailsInterface {
    const {
      exceeded_daily_transaction_limit,
      exceeded_wallet_limit,
      exceeded_warning_daily_transaction_limit_reached,
      exceeded_warning_wallet_limit_reached,
    } = limits;

    let walletLimitSnackbarContent = { content: 'Withdraw funds to continue receiving payments' };

    let dailyTransactionLimitSnackbarContent = {
      content: 'Daily limits reset at midnight. Get verified to continue receiving collections.',
    };

    if (exceeded_wallet_limit) {
      return { ...walletLimitSnackbarContent, title: 'Limit exceeded.', color: 'error', type: 'exceeded_wallet_limit' };
    } else if (exceeded_daily_transaction_limit) {
      return {
        ...dailyTransactionLimitSnackbarContent,
        title: 'Limit exceeded.',
        color: 'error',
        type: 'exceeded_daily_transaction_limit',
      };
    } else if (exceeded_warning_wallet_limit_reached) {
      return {
        ...walletLimitSnackbarContent,
        title: 'Approaching limit.',
        color: 'warning',
        type: 'exceeded_warning_wallet_limit_reached',
      };
    } else if (exceeded_warning_daily_transaction_limit_reached) {
      return {
        ...dailyTransactionLimitSnackbarContent,
        title: 'Approaching limit.',
        color: 'warning',
        type: 'exceeded_warning_daily_transaction_limit_reached',
      };
    } else {
      return null;
    }
  }

  copyText(text: string) {
    return from(copyToClipboard(text));
  }

  fetchReceiptInfo(id: string, receiptType: 'merchant' | 'client', transactionType: 'collections' | 'payments') {
    let url: string;

    if (receiptType === 'client') {
      url =
        transactionType === 'collections'
          ? `${environment.API_URL}collections/public/payment-requests/${id}/customer-receipt/`
          : `${environment.API_URL}payments/public/payments/${id}/customer-receipt/`;
    } else {
      url =
        transactionType === 'collections'
          ? `${environment.API_URL}payment-requests/${id}/merchant-receipt/`
          : `${environment.API_URL}payments/${id}/merchant-receipt/`;
    }

    return this.http.get<TransactionReceiptResponse>(url);
  }

  generateReceipt(payload) {
    return this.http.post(`/api/receipt/`, payload, { responseType: 'blob' });
  }

  /**
   * Checks if user has seen recent highlighted feature then display if necessary
   */
  displayHighlightedFeature(): void {
    if (
      !(
        localStorage.getItem(CURRENT_HIGHLIGHTED_FEATURE.key) === 'seen' ||
        dayjs(new Date()).isAfter(CURRENT_HIGHLIGHTED_FEATURE.expiry_date) ||
        isMobile()
      )
    ) {
      this.dialog.open(HighlightedFeatureDialogContentComponent, {
        width: '80vw',
        height: '95vh',
        maxWidth: '780px',
        maxHeight: '675px',
        panelClass: 'remove-inner-padding',
        disableClose: true,
      });
    }
  }
}
