import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProfileInterface, User } from 'src/app/ngrx/user/user.interfaces';
import { selectCurrentRoute } from 'src/app/ngrx/auth/auth.selectors';
import { selectUserDetails } from '../../../../ngrx/user/user.selectors';
import { selectUserKycDetails } from '../../../../ngrx/KYC/kyc.selectors';
import { environment } from '../../../../../environments/environment';
import { Router, RouterLink } from '@angular/router';
import { NameInitialsPipe } from '../../../shared/pipes/name-initials.pipe';
import { PendingKycBannerComponent } from '../pending-kyc-banner/pending-kyc-banner.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'header[root-header]',
  templateUrl: './root-header.component.html',
  styleUrls: ['./root-header.component.scss'],
  standalone: true,
  imports: [RouterLink, NgIf, PendingKycBannerComponent, AsyncPipe, NameInitialsPipe],
})
export class RootHeaderComponent implements OnInit {
  currentUser$: Observable<User>;
  currentRoute$: Observable<string>;
  userKyc$: Observable<ProfileInterface>;
  environment = environment;

  constructor(private store$: Store, private router: Router) {}

  ngOnInit(): void {
    this.currentUser$ = this.store$.select(selectUserDetails);
    this.currentRoute$ = this.store$.select(selectCurrentRoute);
    this.userKyc$ = this.store$.select(selectUserKycDetails);
  }

  login(): void {
    if (environment.is_pay) {
      window.open(`${environment.URL}/login`, '_blank');
    } else {
      this.router.navigate(['/login']);
    }
  }
}
