<div (swipe)="onSwipe($event)">
  <div class="mx-auto w-full flex justify-center flex-wrap flex-col items-center">
    <div class="flex w-full mx-auto flex-nowrap">
      <div
        class="w-full flex-grow-0 flex-shrink-0 basis-[98%] transition duration-700"
        [ngClass]="{
          '-translate-x-[99%]': value !== 0 && isMounted
        }">
        <ng-content select="[first]"></ng-content>
      </div>
      <div
        class="w-full flex-grow-0 flex-shrink-0 basis-[98%] transition duration-700"
        [ngClass]="value === 1 ? '-translate-x-[97%] ' : 'translate-x-[3%] '">
        <ng-content select="[second]"></ng-content>
      </div>
    </div>
  </div>

  <div class="mt-8">
    <ul class="flex gap-x-4 justify-center">
      <li
        class="rounded-full h-3 w-3"
        [ngClass]="value === 0 ? 'bg-green-600' : 'bg-[#C4C4C4]'"
        (click)="setActiveTab(0)"></li>
      <li
        class="rounded-full h-3 w-3"
        [ngClass]="value === 1 ? 'bg-green-600' : 'bg-[#C4C4C4]'"
        (click)="setActiveTab(1)"></li>
    </ul>
  </div>
</div>
