import { Component, forwardRef, OnInit } from '@angular/core';
import { xAxisSwipeDirection } from '../../../../../assets/utility';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgClass } from '@angular/common';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BeyMultiTabsComponent),
  multi: true,
};
const noop = () => {};

@Component({
  selector: 'bey-multi-tabs',
  templateUrl: './bey-multi-tabs.component.html',
  styleUrls: ['./bey-multi-tabs.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  standalone: true,
  imports: [NgClass],
})
export class BeyMultiTabsComponent implements OnInit {
  /****
   *  Current tab index
   */
  activeTab: number = 0;
  /****
   * This flag is used to determine if the tab was changed after loading
   */
  isMounted: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  setActiveTab(index) {
    this.activeTab = index;
    this.value = index;
    this.isMounted = true;
  }

  onSwipe(event) {
    const dir = xAxisSwipeDirection(event);
    this.activeTab = dir === 'l' ? 1 : 0;
    this.value = this.activeTab;
    this.isMounted = true;
  }

  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  get value(): any {
    return this.activeTab;
  }

  //set accessor including call the onchange callback
  set value(v: any) {
    this.onChangeCallback(v);
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    this.activeTab = value;
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
