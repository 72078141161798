import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../../app-routing.module';
import { RootComponent } from './pages/root/root.component';
import { RootFooterComponent } from './components/root-footer/root-footer.component';
import { RootSidebarComponent } from './components/root sidebar/root-sidebar.component';
import { RootHeaderComponent } from './components/root-header/root-header.component';
import { ErrorPage } from './pages/error/error.page';
import { BeySessionIdleModalComponent } from './components/bey-session-idle-modal/bey-session-idle-modal.component';
import { StaticLinkPage } from './pages/static-link/static-link.page';
import { UniqueLinkInteractionComponent } from './pages/unique-link-interaction/unique-link-interaction.component';
import { UniqueLinkExpiredComponent } from './pages/unique-link-exipred/unique-link-expired.component';
import { CollectionsLinkModule } from '../collections-link/collections-link.module';
import { BeyFunctionalityCarouselComponent } from './components/bey-functionality-carousel/bey-functionality-carousel.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { DemoComponent } from './pages/demo/demo.component';
import { UserSuspensionComponent } from './pages/user-suspension/user-suspension.component';
import { PendingKycBannerComponent } from './components/pending-kyc-banner/pending-kyc-banner.component';
import { PendingPage } from './pages/pending/pending.page';
import { SuccessfulLinkComponent } from './pages/successful-link/successful-link.component';
import { FailedCollectionPage } from './pages/failed-collection/failed-collection.page';
import { PortalModule } from '@angular/cdk/portal';
import { BeyLinkInteractionPage } from './pages/bey-link-interaction/bey-link-interaction.page';
import { BeyOnboardComponent } from './components/bey-onboarding/bey-onboard.component';
import { BeyNetworkStateComponent } from './components/bey-network-state/bey-network-state.component';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    CollectionsLinkModule,
    PortalModule,
    RootComponent,
    RootFooterComponent,
    RootHeaderComponent,
    RootSidebarComponent,
    ErrorPage,
    BeySessionIdleModalComponent,
    StaticLinkPage,
    UniqueLinkInteractionComponent,
    UniqueLinkExpiredComponent,
    BeyFunctionalityCarouselComponent,
    WelcomeComponent,
    DemoComponent,
    UserSuspensionComponent,
    PendingKycBannerComponent,
    PendingPage,
    SuccessfulLinkComponent,
    BeyLinkInteractionPage,
    FailedCollectionPage,
    BeyOnboardComponent,
    BeyNetworkStateComponent,
  ],
  exports: [AppRoutingModule, BeySessionIdleModalComponent, RootHeaderComponent, RootFooterComponent],
  providers: [],
})
export class CoreModule {}
