import { NgModule } from '@angular/core';
import { CollectionsRoutingModule } from './collections-routing.module';
import { RecentCollectionsPage } from './pages/recent-collections/recent-collections.page';
import { CreateCollectionPage } from './pages/create-collection/create-collection.page';
import { CollectionItemComponent } from './components/collection-item/collection-item.component';
import { CreateCollectionDialogContentComponent } from './components/create-collection-dialog-content/create-collection-dialog-content.component';
import { PostCreateCollectionDialogContentComponent } from './components/post-create-collection-dialog-content/post-create-collection-dialog-content.component';
import { PaymentLoadingDialogContentComponent } from './components/payment-loading-dialog-content/payment-loading-dialog-content.component';

@NgModule({
  imports: [
    CollectionsRoutingModule,
    RecentCollectionsPage,
    CreateCollectionPage,
    CollectionItemComponent,
    CreateCollectionDialogContentComponent,
    PostCreateCollectionDialogContentComponent,
    PaymentLoadingDialogContentComponent,
  ],
  exports: [],
})
export class CollectionsModule {}
