import { Component, OnDestroy, OnInit } from '@angular/core';
import { CollectionDialog, CreateCollectionLinkResponse } from '../../../../ngrx/collections/collections.interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionsService } from '../../../../ngrx/collections/collections.service';
import { BeyToastService } from '../../../shared/services/bey-toast.service';
import { Observable, Subject } from 'rxjs';
import { buildCollectionLink } from '../../../../../assets/utility';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CreateCollectionDialogContentComponent } from '../../../collections/components/create-collection-dialog-content/create-collection-dialog-content.component';
import { selectUserDetails } from '../../../../ngrx/user/user.selectors';
import { Store } from '@ngrx/store';
import { COLLECTION_LINK_STATUSES } from '../../../../../assets/const';
import { parsePhoneNumber } from 'libphonenumber-js/min';
import { CROSS_BORDER_OPERATING_COUNTRIES } from 'src/assets/const/index';
import { selectGetRatesIsLoading } from 'src/app/ngrx/misc/misc.selectors';
import { createCollectionStart } from 'src/app/ngrx/collections/collections.actions';
import { TransactionsUtilityService } from '../../../shared/services/transactions-utility.service';
import { TransactionLimits } from '../../../../ngrx/wallets/wallets.interfaces';
import { selectUserTransactionLimits } from '../../../../ngrx/wallets/wallets.selectors';
import { selectGetCollectionLinkInfoIsLoading } from 'src/app/ngrx/collections/collections.selectors';
import { environment } from '../../../../../environments/environment';
import { GetExchangedAmountPipe } from '../../../shared/pipes/get-exchanged-amount.pipe';
import { NameInitialsPipe } from '../../../shared/pipes/name-initials.pipe';
import { BeySignUpCalloutComponent } from '../../../shared/components/bey-signup-callout/bey-signup-callout.component';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { BeyExclamationIconComponent } from '../../../shared/components/icons/bey-exclamation-icon/bey-exclamation-icon.component';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { NgIf, AsyncPipe, TitleCasePipe, CurrencyPipe } from '@angular/common';
import { BeyHeaderWithInitialsComponent } from '../../../shared/components/bey-header-with-initials/bey-header-with-initials.component';

@Component({
  selector: 'app-unique-link-interaction',
  templateUrl: './unique-link-interaction.component.html',
  styleUrls: ['./unique-link-interaction.component.scss'],
  standalone: true,
  imports: [
    BeyHeaderWithInitialsComponent,
    NgIf,
    SpinnerComponent,
    BeyExclamationIconComponent,
    BeyButtonComponent,
    BeySignUpCalloutComponent,
    AsyncPipe,
    TitleCasePipe,
    CurrencyPipe,
    NameInitialsPipe,
    GetExchangedAmountPipe,
  ],
})
export class UniqueLinkInteractionComponent implements OnInit, OnDestroy {
  collectionInfo: CreateCollectionLinkResponse;
  businessInfo: any;
  short_name: string;
  uniqueLinkId: string;
  userDetails$: Observable<any>;
  isXBCollection: boolean = false;
  XBCollectionDetails: {
    fromCountry: { isoCode: string; currency: string };
    toCountryCurrency: string;
  };
  COLLECTION_LINK_STATUSES = { ...COLLECTION_LINK_STATUSES };
  businessWalletLimits$: Observable<TransactionLimits>;
  getCollectionLinkInfoIsLoading$: Observable<boolean>;
  getRatesIsLoading$: Observable<boolean>;
  stop$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private collectionsService: CollectionsService,
    private toast: BeyToastService,
    private dialog: MatDialog,
    private store: Store,
    private transactionsUtility: TransactionsUtilityService
  ) {
    this.userDetails$ = store.select(selectUserDetails);
  }

  ngOnInit(): void {
    this.getRatesIsLoading$ = this.store.select(selectGetRatesIsLoading);
    this.getCollectionLinkInfoIsLoading$ = this.store.select(selectGetCollectionLinkInfoIsLoading);

    const [collectionInfo, businessInfo, uniqueLinkId, short_name] = this.route.snapshot.data?.['linkData'];
    this.collectionInfo = collectionInfo;
    this.businessInfo = businessInfo;
    this.uniqueLinkId = uniqueLinkId;
    this.short_name = short_name;

    // check if the link is still valid and false if the link is expired or successfully utilized
    if (!collectionInfo.is_active) {
      //   make sure to redirect to expired if the link is used
      this.router.navigate([`expired/${this.businessInfo?.short_name}`]);

      return;
    } else {
      // temporary approach to check if this is a cross border collection or not (assuming that we're currently operating on Uganda only)
      let payerPhoneNumberCountryCode: string = parsePhoneNumber(
        this.collectionInfo?.contact?.phone
      ).countryCallingCode;
      if (payerPhoneNumberCountryCode !== '256') {
        this.isXBCollection = true;

        // (assuming that we're currently operating on Uganda only)
        let fromCountry = CROSS_BORDER_OPERATING_COUNTRIES.find((country) => country.label === 'Uganda');
        let toCountryCurrency = CROSS_BORDER_OPERATING_COUNTRIES.find(
          (country) => country.countryCode === `+${payerPhoneNumberCountryCode}`
        )?.currency;

        this.XBCollectionDetails = {
          fromCountry: { currency: fromCountry.currency, isoCode: fromCountry.isoCode },
          toCountryCurrency,
        };
      }
    }

    this.businessWalletLimits$ = this.store.select(selectUserTransactionLimits);
  }

  pay() {
    let data: CollectionDialog = {
      ...this.collectionInfo,
      ...this.collectionInfo.contact,
      type: 'collection-link',
      success: false,
      ...this.businessInfo,
      currency: this.isXBCollection
        ? this.XBCollectionDetails['toCountryCurrency']
        : this.collectionInfo.amount_currency,
      displayAmount: this.isXBCollection
        ? this.transactionsUtility.calculateFx(parseFloat(this.collectionInfo.amount), false)
        : this.collectionInfo.amount,
    };

    this.store.dispatch(createCollectionStart({ payload: data }));
    this.dialog.open(CreateCollectionDialogContentComponent, {
      data,
    });
  }

  handleAuth(action: 'signup' | 'login') {
    const url = action === 'signup' ? `${environment.URL}/signup` : `${environment.URL}/login`;

    if (environment.is_pay) {
      window.open(url, '_blank');
    } else {
      this.router.navigate([`/${action}`]);
    }
  }

  get uniqueLink() {
    return buildCollectionLink(this.short_name, this.uniqueLinkId);
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
