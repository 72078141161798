import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  selectCollectionLinkInfo,
  selectGetCollectionLinkInfoIsLoading,
  selectPendingCollectionInfo,
  selectPendingCollectionIsLoading,
} from '../../../../ngrx/collections/collections.selectors';
import { Subject, takeUntil, combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { getCollectionInfoStart, getCollectionLinkInfoStart } from '../../../../ngrx/collections/collections.actions';
import { AsyncPipe } from '@angular/common';
import { BeyButtonComponent } from '../../../shared/components/bey-button/bey-button.component';
import { BeyHeaderWithInitialsComponent } from '../../../shared/components/bey-header-with-initials/bey-header-with-initials.component';

@Component({
  selector: 'app-pending',
  templateUrl: './pending.page.html',
  styleUrls: ['./pending.page.scss'],
  standalone: true,
  imports: [BeyHeaderWithInitialsComponent, BeyButtonComponent, AsyncPipe],
})
export class PendingPage implements OnInit, OnDestroy {
  constructor(private store: Store, private route: ActivatedRoute, private router: Router) {}
  stop$ = new Subject<void>();
  isLoadingPendingCollectionInfo$: Observable<boolean>;
  isLoadingPendingCollectionLinkInfo$: Observable<boolean>;

  ngOnInit(): void {
    this.checkForCollectionLinkInfo();

    this.isLoadingPendingCollectionInfo$ = this.store.select(selectPendingCollectionIsLoading);
    this.isLoadingPendingCollectionLinkInfo$ = this.store.select(selectGetCollectionLinkInfoIsLoading);

    combineLatest([this.store.select(selectPendingCollectionInfo), this.isLoadingPendingCollectionInfo$])
      .pipe(takeUntil(this.stop$))
      .subscribe((info) => {
        const [pendingCollectionData, isLoadingPendingCollectionInfo] = info;

        // make sure we don't process the data if the request is still loading to avoid using old data from the store
        if (pendingCollectionData && !isLoadingPendingCollectionInfo) {
          this.processCollection(pendingCollectionData);
        }
      });
  }

  refresh(): void {
    this.checkForCollectionLinkInfo();
  }

  checkForCollectionLinkInfo(): void {
    const id = this.route.snapshot?.queryParamMap.get('id'); // the id will be the link_id if it's unique link, the code otherwise
    const type = this.route.snapshot?.queryParamMap.get('type');

    if (id) {
      this.store.dispatch(getCollectionInfoStart(type === 'ul' ? { link_id: id } : { code: id }));
    }
  }

  processCollection(info) {
    const id = this.route.snapshot.queryParamMap.get('id');
    const type = this.route.snapshot.queryParamMap.get('type');
    const shortName = this.route.snapshot.paramMap.get('short_name');

    switch (info.status) {
      case 'failed':
        //  redirect to failed page
        const extras = {
          id,
          type,
        };

        // this is temporary
        type === 'bl' && (extras['link_id'] = this.route.snapshot.queryParamMap.get('link_id'));

        this.router.navigate([`/failed/${shortName}`], {
          queryParams: {
            ...extras,
          },
        });
        break;

      //  redirect to success page
      case 'successful':
      case 'credited':
        this.router.navigate([`/successful/${type}/${shortName}`], {
          queryParams: type === 'ul' ? { link_id: info.link_id } : { code: info.code },
        });
        break;

      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
