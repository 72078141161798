<div class="h-full relative">
  <bey-with-initials-header [name]="businessInfo?.active_merchant_name" class="lg:hidden"></bey-with-initials-header>

  <div class="hidden lg:block bg-blue-500 h-[385px] fix relative">
    <div class="flex justify-between px-16 pt-10 items-center">
      <div>
        <img src="assets/images/logo/full-white.svg" alt="logo" width="155" height="55" class="w-40" />
      </div>
      <div *ngIf="!!!(userDetails$ | async)" class="relative z-20 text-white text-xl py-2 leading-[30px] font-medium">
        Already have an account?
        <button (click)="handleAuth('login')" class="pl-1 pr-6 underline">Login</button>
      </div>
    </div>

    <svg
      class="absolute left-16 top-16"
      width="35"
      height="32"
      viewBox="0 0 35 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="26.4577"
        height="25.3646"
        rx="4"
        transform="matrix(0.883372 -0.468673 0.531994 0.846748 -1 11.4004)"
        fill="#A1D1F5"
        fill-opacity="0.1" />
    </svg>

    <svg
      class="absolute left-64 top-26"
      width="103"
      height="95"
      viewBox="0 0 103 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="80.1362"
        height="86.5029"
        rx="4"
        transform="matrix(0.236463 -0.97164 0.979529 0.201302 -0.703125 77.8027)"
        fill="#DFE7FC"
        fill-opacity="0.14" />
    </svg>

    <svg
      class="absolute left-2/4 top-2/4"
      width="35"
      height="32"
      viewBox="0 0 35 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="26.4577"
        height="25.3646"
        rx="4"
        transform="matrix(0.883372 -0.468673 0.531994 0.846748 -1 11.4004)"
        fill="#A1D1F5"
        fill-opacity="0.1" />
    </svg>

    <svg
      class="absolute left-3/4 top-3/4"
      width="139"
      height="128"
      viewBox="0 0 139 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="107.842"
        height="116.41"
        rx="4"
        transform="matrix(0.236463 -0.97164 0.979529 0.201302 0 104.783)"
        fill="#DFE7FC"
        fill-opacity="0.14" />
    </svg>

    <svg
      class="absolute right-4 top-1/4"
      width="162"
      height="149"
      viewBox="0 0 162 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="117.96"
        height="113.086"
        rx="4"
        transform="matrix(0.883372 -0.468673 0.531994 0.846748 -1.3125 54.2832)"
        fill="#A1D1F5"
        fill-opacity="0.1" />
    </svg>
  </div>

  <div
    class="mt-[42px] text-center lg:mt-[-190px] lg:relative lg:top-0 lg:mb-20 text-center lg:bg-white lg:rounded-xl lg:shadow-xl lg:max-w-[611px] lg:inset-x-0 top-52 mx-auto lg:pt-[54px] lg:pb-[58px] max-w-[80vw]">
    <div
      class="hidden lg:flex text-white bg-gradient-to-l from-blue-600 to-blue-400 w-[140px] h-[140px] border-gray-100 border-[5px] mb-5 rounded-full justify-center items-center font-medium text-[38px] mx-auto text-[50px] leading-[59px]">
      {{ businessInfo.active_merchant_name | nameInitials }}
    </div>

    <div class="text-grey-500 leading-[19px] font-medium lg:text-2xl lg:leading-[25px]">
      {{ businessInfo.active_merchant_name }}
    </div>
    <div class="text-blue-400 mt-[5px] leading-[19px] font-medium">
      <a href="#" class="max-w-[95%] mx-auto break-words block">{{ uniqueLink }}</a>
    </div>

    <div class="text-grey-500 text-center leading-[19px] max-w-[312px] mx-auto my-5">
      <span *ngIf="!isXBCollection; else XBCollection">
        Hey {{ collectionInfo?.contact.first_name | titlecase }}, you have received a collections link from
        {{ businessInfo.active_merchant_name }} for
        <strong>{{
          collectionInfo?.amount | currency : collectionInfo?.amount_currency + ' ' : 'symbol' : '1.0-0'
        }}</strong>
        for {{ collectionInfo.description }}. Please enter your pin when prompted.
      </span>

      <ng-template #XBCollection>
        Hey {{ collectionInfo?.contact.first_name | titlecase }}, you have received an international payment request of
        <spinner class="inline-block mr-1" *ngIf="getRatesIsLoading$ | async" [diameter]="15"></spinner>
        <strong>{{
          (collectionInfo?.amount
            | getExchangedAmount : XBCollectionDetails['fromCountry'] : XBCollectionDetails['toCountryCurrency']
            | async) || '' | currency : XBCollectionDetails['toCountryCurrency'] + ' ' : 'symbol' : '1.0-0'
        }}</strong>
        from {{ businessInfo.active_merchant_name }}. <br />
        Description: {{ collectionInfo.description }}. <br />
        Please enter your PIN when prompted.
      </ng-template>

      <span
        class="text-bey-orange mx-auto my-5 block"
        *ngIf="collectionInfo.status === COLLECTION_LINK_STATUSES.PROCESSING">
        Your payment request is being processed.
      </span>

      <span class="text-bey-red mx-auto my-5 flex" *ngIf="collectionInfo.status === COLLECTION_LINK_STATUSES.FAILED">
        <bey-exclamation-icon variant="danger"></bey-exclamation-icon>
        <span class="ml-2.5">Your payment request failed. Please try again.</span>
      </span>
    </div>

    <bey-button
      placeholder="Pay"
      color="dark-blue"
      classList="mx-auto px-8"
      (onClick)="pay()"
      [isLoading]="getCollectionLinkInfoIsLoading$ | async"
      [disabled]="
        collectionInfo.is_active && collectionInfo.status === COLLECTION_LINK_STATUSES.PROCESSING
      "></bey-button>
  </div>

  <div class="max-w-[611px] mx-auto">
    <bey-sign-up-callout
      header="Is sending and receiving payments a hassle?"
      body="Beyonic makes it so much easier."
      linkText="Start your free trial"
      classList="mt-14"
      (trialStart)="handleAuth('signup')"></bey-sign-up-callout>
  </div>
</div>
