<div class="px-3 pt-4 lg:p-0">
  <bey-trial-banner></bey-trial-banner>
  <div class="lg:px-12 max-w-screen-xl" [ngClass]="(pendingKyc$ | async) ? 'lg:mt-40' : 'lg:mt-20'">
    <div
      class="flex flex-col w-full mb-3 lg:flex-row lg:items-center lg:justify-between lg:gap-4 min-h-[226px]"
      [ngClass]="{'!block': (sidebarIsOpen$ | async)}">
      <bey-multi-tabs
        [ngClass]="{ 'blur-sm pointer-events-none': (pendingKyc$ | async) }"
        [(ngModel)]="activeTabIndex"
        class="lg:hidden mb-5 lg:mb-0 max-w-[95vw]">
        <ng-container first>
          <div class="text-left text-sm mb-4 leading-4">Today</div>
          <home-balance-card
            class="w-full"
            [ngClass]="(sidebarIsOpen$ | async) ? ' lg:pr-6 max-w-[100%]' : 'max-w-[400px]'"></home-balance-card>
        </ng-container>
        <div class="h-[170px] w-auto bg-grey-200 rounded-lg animate-pulse" *ngIf="isLoadingAnalytics"></div>
        <ng-container #lazyLoadContainer second></ng-container>
      </bey-multi-tabs>

      <div
        [ngClass]="{
           'blur-sm pointer-events-none': (pendingKyc$ | async),
           'max-w-[100%]':(sidebarIsOpen$ | async),
           'max-w-[400px]':!(sidebarIsOpen$ | async)
           }"
        class="min-w-[350px] hidden lg:block ease-in-out transition-all duration-700">
        <div class="text-sm text-grey-500 mb-4 leading-4">Today</div>
        <home-balance-card></home-balance-card>
      </div>

      <div
        [ngClass]="{
          'blur-sm pointer-events-none': (pendingKyc$ | async),
          'chart-show':!(sidebarIsOpen$ | async),
          '!hidden':(sidebarIsOpen$ | async)
        }"
        class="w-[380px] h-[226px] hidden min-[1425px]:block">
        <div class="flex justify-between">
          <div class="text-sm text-grey-500 mb-4 leading-4">{{monthName}}</div>
          <div class="text-sm text-grey-500 mb-4 leading-4">Last 7 days</div>
        </div>
        <div class="h-[170px] w-auto bg-grey-200 rounded-lg animate-pulse" *ngIf="isLoadingAnalytics"></div>
        <ng-container #lazyLoadContainer></ng-container>
      </div>

      <div
        class="lg:h-[226px] lg:min-w-[400px] transition-all ease-in-out duration-500"
        [ngClass]="{'!hidden':(sidebarIsOpen$ | async), 'actions-show':!(sidebarIsOpen$ | async) }">
        <div class="text-sm text-grey-500 mb-4 leading-4 hidden lg:block">Quick actions</div>
        <div class="flex flex-col w-full lg:h-[170px] md:rounded-lg md:bg-white md:p-3 lg:justify-center">
          <span class="text-black font-normal text-sm mb-3 md:text-xl lg:hidden">Quick actions</span>
          <div class="flex items-center mb-8 w-full justify-between md:m-0">
            <home-operation *ngFor="let operation of operations" [operation]="operation"></home-operation>
          </div>
        </div>
      </div>
    </div>

    <app-recent-transactions class="mt-8 lg:max-w-[80vw] block"> </app-recent-transactions>
  </div>
</div>
