import { createReducer, on } from '@ngrx/store';
import * as authActions from './auth.actions';
import { AuthState } from './auth.interfaces';
import { produce } from 'immer';

const initialState: AuthState = {
  tokens: null,
  phone: null,
  currentRoute: '',
  lockedAccountTimeStamp: null,
  isLoading: {
    signup: false,
    login: false,
    sendVerificationCode: false,
    verifyVerificationCode: false,
    getUserSecurityQuestions: false,
    resetUserPin: false,
  },
  errorMessages: {
    signup: '',
    login: '',
    sendVerificationCode: '',
    verifyVerificationCode: '',
    getUserSecurityQuestions: '',
    resetUserPin: '',
  },
};

export const authReducer = createReducer(
  initialState,
  //----------START----------
  on(
    authActions.signupStart,
    produce((state) => {
      state.isLoading['signup'] = true;
    })
  ),
  on(
    authActions.loginStart,
    produce((state) => {
      state.isLoading['login'] = true;
      state.errorMessages['login'] = '';
    })
  ),
  on(
    authActions.sendVerificationCodeStart,
    produce((state) => {
      state.isLoading['sendVerificationCode'] = true;
      state.errorMessages['verifyVerificationCode'] = '';
      state.errorMessages['verifyVerificationCode'] = '';
    })
  ),
  on(
    authActions.verifyVerificationCodeStart,
    produce((state) => {
      state.isLoading['verifyVerificationCode'] = true;
    })
  ),
  on(
    authActions.resetUserPinStart,
    produce((state) => {
      state.isLoading['resetUserPin'] = true;
    })
  ),
  //----------SUCCESS----------
  on(
    authActions.signupSuccess,
    produce((state) => {
      state.isLoading['signup'] = false;
      state.errorMessages['signup'] = '';
    })
  ),
  on(
    authActions.loginSuccess,
    produce((state, { payload: { tokens, phone } }) => {
      state.tokens = tokens;
      state.phone = phone;
      state.isLoading['login'] = false;
      state.errorMessages['login'] = '';
    })
  ),
  on(
    authActions.sendVerificationCodeSuccess,
    produce((state) => {
      state.isLoading['sendVerificationCode'] = false;
      state.errorMessages['sendVerificationCode'] = '';
    })
  ),
  on(
    authActions.verifyVerificationCodeSuccess,
    produce((state) => {
      state.isLoading['verifyVerificationCode'] = false;
      state.errorMessages['verifyVerificationCode'] = '';
    })
  ),
  on(
    authActions.resetUserPinSuccess,
    produce((state) => {
      state.isLoading['resetUserPin'] = false;
      state.errorMessages['resetUserPin'] = '';
    })
  ),
  //----------FAILURE----------
  on(
    authActions.signupFailure,
    produce((state, { error }) => {
      state.isLoading['signup'] = false;
      state.errorMessages['signup'] = error;
    })
  ),
  on(
    authActions.loginFailure,
    produce((state, { error }) => {
      state.isLoading['login'] = false;
      state.errorMessages['login'] = error;
    })
  ),
  on(
    authActions.sendVerificationCodeFailure,
    produce((state, { error }) => {
      state.isLoading['sendVerificationCode'] = false;
      state.errorMessages['sendVerificationCode'] = error;
    })
  ),
  on(
    authActions.verifyVerificationCodeFailure,
    produce((state, { error }) => {
      state.isLoading['verifyVerificationCode'] = false;
      state.errorMessages['verifyVerificationCode'] = error;
    })
  ),
  on(
    authActions.resetUserPinFailure,
    produce((state, { error }) => {
      state.isLoading['resetUserPin'] = false;
      state.errorMessages['resetUserPin'] = error;
    })
  ),
  //----------OTHER----------
  on(
    authActions.logOut,
    produce((state) => {
      state.phone = null;
      state.tokens = null;
    })
  ),
  on(
    authActions.updateToken,
    produce((state, { payload }) => {
      state.tokens.access = payload;
    })
  ),
  on(
    authActions.lockAccount,
    produce((state) => {
      let lockedAccountTimeStamp = state['lockedAccountTimeStamp'];
      // if there's no timestamp already stored in the state then we create a new one
      let date = lockedAccountTimeStamp || new Date();

      state.lockedAccountTimeStamp = date;
      state.isLoading['login'] = false;
    })
  ),
  on(
    authActions.unlockAccount,
    produce((state) => {
      state.lockedAccountTimeStamp = null;
    })
  ),
  on(
    authActions.setCurrentRoute,
    produce((state, { payload }) => {
      state.currentRoute = payload;
    })
  ),
  on(
    authActions.setLoginIsLoadingToFalse,
    produce((state) => {
      state.isLoading['login'] = false;
    })
  )
);
