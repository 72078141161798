<div class="h-full relative lg:mb-20">
  <bey-with-initials-header name="Beyonic"></bey-with-initials-header>
  <div
    class="hidden mt-[-5%] relative left-0 lg:flex text-white bg-gradient-to-l from-blue-600 to-blue-400 w-[140px] h-[140px] border-gray-100 border-[5px] mb-9 rounded-full justify-center items-center font-medium text-[38px] mx-auto text-[50px] leading-[59px]">
    {{ 'Beyonic' | nameInitials }}
  </div>
  <div class="max-w-lg mx-auto">
    <section class="mt-10" bey-tab-group>
      <bey-tab name="Local">
        <div class="mb-4 lg:hidden">
          <div class="text-grey-500 leading-[19px] font-medium">Send to Beyonic</div>
        </div>

        <form
          action="#"
          class="px-2 pt-5 space-y-1 overflow-hidden bg-white rounded lg:shadow lg:px-10 lg:rounded lg:-mt-4"
          [formGroup]="form"
          (ngSubmit)="formSubmit(form)"
          autocomplete="off">
          <div
            class="text-grey-500 leading-[19px] font-medium text-center lg:mt-2 lg:mb-6 hidden lg:block lg:text-2xl hidden">
            Send to Beyonic
          </div>
          <div class="flex justify-between space-x-3">
            <div class="w-6/12">
              <bey-input
                formControlName="first_name"
                label="First name"
                getElementId
                [control]="form.controls['first_name']"></bey-input>
            </div>
            <div class="w-6/12">
              <bey-input
                formControlName="last_name"
                label="Last name"
                getElementId
                [control]="form.controls['last_name']"></bey-input>
            </div>
          </div>

          <div class="flex justify-between space-x-3" formGroupName="phone">
            <div class="w-6/12">
              <bey-country-selector
                formControlName="code"
                label="Country code"
                bindLabel="label"
                bindValue="value"
                [options]="countries"
                getElementId
                [control]="form.get('phone.code')"></bey-country-selector>
            </div>
            <div class="w-6/12">
              <bey-contact-selector
                label="Your number"
                formControlName="phone_number"
                bindLabel="label"
                [options]="contacts"
                [isLoading]="false"
                getElementId
                [control]="form.controls['phone']"></bey-contact-selector>
            </div>
          </div>

          <div>
            <bey-input
              type="amount"
              formControlName="amount"
              label="Amount"
              getElementId
              [control]="form.controls['amount']">
              <div prepend class="flex text-sm justify-center items-center pl-[8px] text-grey-400">
                {{ merchantWalletCurrency }}
              </div>
            </bey-input>
          </div>

          <div>
            <bey-input
              label="Description"
              type="textarea"
              formControlName="description"
              getElementId
              [control]="form.controls['description']"></bey-input>
          </div>

          <bey-button
            [submit]="true"
            [isLoading]="false"
            placeholder="Send"
            color="dark-blue"
            classList="mx-auto px-4 my-6"
            getElementId
            getElementIdDescription="send"></bey-button>
        </form>
      </bey-tab>

      <bey-tab name="International"> </bey-tab>
    </section>
  </div>

  <bey-sign-up-callout
    *ngIf="true"
    header="Is sending and receiving payments a hassle?"
    body="Beyonic makes it so much easier."
    linkText="Start your free trial"
    classList="mt-20"
    (trialStart)="handleSignUp()"></bey-sign-up-callout>
</div>
