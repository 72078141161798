<div class="flex flex-wrap" (swipe)="onSwipe($event)">
  <div class="w-full">
    <div class="text-grey-800 text-xl text-center leading-[23px] font-medium">
      {{ activeHeader }}
    </div>
  </div>
  <div
    class="functionality-slider w-full flex items-center justify-center text-center min-h-[500px] overflow-hidden relative">
    <ng-container *ngFor="let slide of slides; let i = index">
      <div *ngIf="i === pageIndex" @slideIn @slideOut>
        <div class="w-full mx-auto">
          <video
            class="mx-auto w-[250px]"
            [src]="['../../../../../assets/videos/' + slide.name]"
            autoplay
            playsinline
            muted
            #videoRef>
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="w-full min-h-[80px]">
    <div class="px-10 flex items-center flex-col mt-8x bottom-5 w-full justify-around h-full">
      <div class="w-full flex justify-between h-[48px]" *ngIf="!(this.pageIndex === this.slides.length - 1)">
        <button
          class="flex ease-in-out duration-500"
          (click)="navigate()"
          [ngClass]="pageIndex !== 0 ? 'opacity-1' : 'pointer-events-none opacity-0'">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.94507 10.062L10.947 4.67C11.3226 4.2651 11.9553 4.24136 12.3602 4.61696C12.7651 4.99257 12.7888 5.62528 12.4132 6.03018L8.69516 10.0382L12.4014 13.8974C12.7839 14.2958 12.7711 14.9288 12.3728 15.3113C11.9744 15.6939 11.3414 15.6811 10.9589 15.2828L5.94507 10.062Z"
              fill="#80807D" />
          </svg>
          <span class="text-grey-400 font-medium leading-[19px] ml-1">Back</span>
        </button>

        <button class="flex" (click)="navigate(true)">
          <span class="text-blue-400 font-medium leading-[19px] mr-1">Next</span>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.13998 15.353C6.73509 14.9774 6.71134 14.3447 7.08695 13.9398L10.805 9.93176L7.09881 6.07253C6.71626 5.67419 6.72907 5.04116 7.12741 4.65861C7.52575 4.27606 8.15879 4.28886 8.54133 4.6872L13.5551 9.90798L8.5532 15.3C8.17759 15.7049 7.54488 15.7286 7.13998 15.353Z"
              fill="#006FAB" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.13998 15.353C6.73509 14.9774 6.71134 14.3447 7.08695 13.9398L10.805 9.93176L7.09881 6.07253C6.71626 5.67419 6.72907 5.04116 7.12741 4.65861C7.52575 4.27606 8.15879 4.28886 8.54133 4.6872L13.5551 9.90798L8.5532 15.3C8.17759 15.7049 7.54488 15.7286 7.13998 15.353Z"
              fill="#006FAB" />
          </svg>
        </button>
      </div>

      <div *ngIf="this.pageIndex === this.slides.length - 1" @fadeIn>
        <bey-button
          placeholder="Get Started"
          color="dark-blue"
          classList="px-12 py-[12px]"
          (onClick)="handleClick()"></bey-button>
      </div>

      <div class="flex space-x-2 mt-4">
        <div
          *ngFor="let slide of slides; let last = last; let i = index"
          class="rounded-full h-3 w-3 ease-in-out duration-300"
          [ngClass]="pageIndex >= i ? 'bg-bey-green' : 'bg-grey-200'"></div>
      </div>
    </div>
  </div>
</div>
