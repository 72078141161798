<div class="relative bg-white rounded-xl pt-6 pb-10">
  <div
    *ngIf="_transaction?.related_transaction?.xb_fx_details?.is_xb_collection"
    class="absolute flex items-center top-[15px] right-[10px] bg-[#008F5014] rounded-full py-0.5 px-3">
    <img height="14" width="14" class="mr-2" src="assets/images/green-globe.png" alt="international" />
    <span class="text-sm font-normal text-bey-green">International</span>
  </div>

  <div class="w-14 h-14 flex items-center justify-center mb-2 rounded-full bg-blue-50 mx-auto">
    <img
      src="/assets/images/top-up-large.png"
      alt="top-up"
      *ngIf="_transaction?.top_up && _transaction?.type === 'collections'" />
    <img
      src="/assets/images/withdraw-large.png"
      alt="withdraw"
      *ngIf="_transaction?.withdrawal && _transaction?.type === 'payments'" />
    <img
      src="/assets/images/collect-large.png"
      alt="collection"
      *ngIf="!_transaction?.top_up && _transaction?.type === 'collections'" />
    <img
      src="/assets/images/send-money-large.png"
      alt="send money"
      *ngIf="!_transaction?.withdrawal && _transaction?.type === 'payments'" />
  </div>

  <div class="text-center text-blue-400 font-bold mb-1 lg:text-xl">
    <ng-container *ngIf="_transaction?.top_up && _transaction?.type === 'collections'"> Top Up </ng-container>

    <ng-container *ngIf="_transaction?.withdrawal && _transaction?.type === 'payments'"> Withdrawal </ng-container>

    <ng-container *ngIf="!_transaction?.top_up && _transaction?.type === 'collections'"> Collection </ng-container>

    <ng-container *ngIf="!_transaction?.withdrawal && _transaction?.type === 'payments'"> Payments </ng-container>
  </div>

  <div class="text-center mb-9 text-grey-300 leading-[19px]">
    {{ transactionColorAndStatus?.status }}
  </div>

  <header class="text-grey-800 font-bold text-center" *ngIf="beyonicLinkTitle">
    {{ beyonicLinkTitle }}
  </header>

  <p class="text-grey-500 text-center mb-6">
    {{ transactionDescription }}
  </p>

  <section class="px-7">
    <hr class="mb-5 mt-6" />
    <header class="text-grey-800 font-bold text-sm mb-7 text-sm">Transaction details</header>
    <div class="grid grid-cols-2 gap-y-2">
      <ng-container
        *ngIf="
          !(_transaction?.withdrawal && _transaction?.type === 'payments') &&
          !(_transaction?.top_up && _transaction?.type === 'collections')
        ">
        <span class="text-grey-800">Name</span>
        <span class="font-bold text-grey-500 justify-self-end">
          <ng-container *ngIf="_transaction?.contact?.first_name; else anonymous">
            {{ _transaction?.contact?.first_name }} {{ _transaction?.contact?.last_name }}
          </ng-container>
          <ng-template #anonymous>
            {{ _transaction?.contact?.phone | formatPhone }}
          </ng-template>
        </span>
      </ng-container>

      <span class="text-grey-800">
        <ng-container *ngIf="_transaction?.type === 'payments' && _transaction?.payment_type === 'bank'; else notBank">
          Bank account number
        </ng-container>
        <ng-template #notBank> Phone </ng-template>
      </span>
      <span class="font-bold text-grey-500 justify-self-end">
        <ng-container *ngIf="_transaction?.type === 'payments'; else normal">
          <ng-container *ngIf="_transaction?.payment_type === 'mobile_money'; else bank">
            <div class="flex items-center text-end">
              <bey-country-flag-icon [isoCode]="countryIsoCode" class="block mr-2"></bey-country-flag-icon>
              <div>{{ _transaction?.contact?.phone | formatPhone }}</div>
            </div>
          </ng-container>
          <ng-template #bank>
            {{ _transaction?.contact?.bank_account_number }}
          </ng-template>
        </ng-container>
        <ng-template #normal>
          <div class="flex items-center text-end">
            <bey-country-flag-icon [isoCode]="countryIsoCode" class="block mr-2"></bey-country-flag-icon>
            <div>{{ _transaction?.contact?.phone | formatPhone }}</div>
          </div>
        </ng-template>
      </span>

      <span class="text-grey-800">Date </span>
      <div class="font-bold text-grey-500 justify-self-end text-right">
        {{ _transaction?.created | date : 'd MMM y h:mm a' }}
      </div>

      <ng-container *ngIf="_transaction?.withdrawal && _transaction?.type === 'payments'">
        <span class="text-grey-800">Withdraw to </span>
        <span class="font-bold text-grey-500 justify-self-end text-right">
          <ng-container *ngIf="_transaction?.payment_type === 'mobile_money'; else bank"> Mobile money </ng-container>
          <ng-template #bank> My bank account </ng-template>
        </span>
      </ng-container>
    </div>
    <hr class="mb-5 mt-6" />
  </section>

  <section class="px-7">
    <div class="grid grid-cols-2 gap-y-2">
      <span class="text-grey-800">Amount </span>
      <div class="flex flex-col font-bold justify-self-end text-right">
        <span class="text-grey-500">{{
          _unit_amount
            | applyRoundingRules
              : roundingPipeParams?.countryName
              : roundingPipeParams?.transactionType
              : roundingPipeParams?.isXB
            | currency : roundingPipeParams?.countryCurrency + ' ' : 'symbol' : currencyPipeDigitalInfo
        }}</span>
        <div *ngIf="_transaction?.related_transaction?.xb_fx_details?.is_xb_collection" class="flex items-center">
          <bey-country-flag-icon [isoCode]="xbCountryIso"></bey-country-flag-icon>
          <span class="text-grey-400 ml-3">{{
            _transaction?.related_transaction?.xb_fx_details?.request_amount
              | applyRoundingRules
                : roundingPipeParams?.countryName
                : roundingPipeParams?.transactionType
                : roundingPipeParams?.isXB
              | currency : _transaction?.related_transaction?.xb_fx_details?.request_currency + ' '
          }}</span>
        </div>
      </div>

      <ng-container *ngIf="_transaction?.request_type === 'beyonic_link'">
        <span class="text-grey-800">Quantity</span>
        <span class="font-bold text-grey-500 justify-self-end">
          {{ _transaction?.quantity }}
        </span>
      </ng-container>
    </div>
    <hr class="mb-5 mt-6" />
  </section>

  <section class="px-7" *ngIf="_transaction?.status !== 'failed' && _transaction?.status !== 'reversal_failed'">
    <div class="grid grid-cols-2 gap-y-2">
      <span class="text-grey-800">Total received </span>
      <div class="flex flex-col font-bold justify-self-end">
        <span class="text-grey-500">
          {{
            _transaction?.related_transaction?.xb_fx_details?.receive_amount || _transaction?.amount
              | applyRoundingRules
                : roundingPipeParams?.countryName
                : roundingPipeParams?.transactionType
                : roundingPipeParams?.isXB
              | currency : roundingPipeParams?.countryCurrency + ' ' : 'symbol' : currencyPipeDigitalInfo
          }}
        </span>
        <div *ngIf="_transaction?.related_transaction?.xb_fx_details?.is_xb_collection" class="flex items-center">
          <span class="text-grey-500 mr-3">{{
            _transaction?.related_transaction?.xb_fx_details?.request_amount
              | applyRoundingRules
                : roundingPipeParams?.countryName
                : roundingPipeParams?.transactionType
                : roundingPipeParams?.isXB
              | currency : _transaction?.related_transaction?.xb_fx_details?.request_currency + ' '
          }}</span>
          <bey-country-flag-icon [isoCode]="xbCountryIso"></bey-country-flag-icon>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="beyonicLinkNotes" class="px-7">
    <header class="text-grey-800 font-bold text-sm mt-6">Additional notes</header>
    <p class="text-gray-600">{{ beyonicLinkNotes }}</p>
  </section>

  <div class="flex items-center justify-center mt-5" *ngIf="_transaction?.related_transaction?.fees">
    <div class="text-grey-800 flex items-center">
      <bey-exclamation-icon variant="currentColor"></bey-exclamation-icon>
      <span class="ml-2 mr-5">Transaction fee: </span>
    </div>
    <span class="text-grey-800 font-bold">
      {{
        transactionFee
          | removeMinusSign
          | applyRoundingRules : roundingPipeParams?.countryName : 'fee' : roundingPipeParams?.isXB
          | currency
            : (_transaction?.related_transaction?.xb_fx_details?.is_xb_collection
                ? _transaction?.related_transaction?.xb_fx_details?.receive_currency + ' '
                : merchantWalletCurrency + ' ')
            : 'symbol'
            : currencyPipeDigitalInfo
      }}
    </span>
  </div>

  <div *ngIf="transactionColorAndStatus?.status === 'Failed'" class="flex items-center justify-center mt-[33px]">
    <span class="mr-5 text-grey-800">Failure reason:</span>
    <span class="text-grey-800">{{ _transaction.failure_reason || 'N/A' }}</span>
  </div>

  <ng-container *ngIf="_transaction?.status === 'failed' || _transaction?.status === 'reversal_failed'; else other">
    <bey-button
      *ngIf="_transaction?.request_type !== 'beyonic_link' && _transaction?.request_type !== 'static'"
      [small]="true"
      placeholder="Retry this transaction"
      color="dark-blue"
      class="block mt-10"
      classList="mx-auto"
      (onClick)="resendTransaction()"></bey-button>
  </ng-container>

  <ng-template #other>
    <bey-button
      *ngIf="_transaction?.request_type !== 'beyonic_link' && _transaction?.request_type !== 'static'"
      [small]="true"
      placeholder="Repeat this transaction"
      color="dark-blue"
      class="block mt-10"
      classList="mx-auto"
      (onClick)="resendTransaction()"></bey-button>
  </ng-template>

  <div
    class="flex justify-around px-7 mt-10"
    *ngIf="_transaction?.status === 'delivered' || _transaction?.status === 'credited'">
    <bey-rounded-btn
      iconSrc="/assets/images/icons/receipt-icon.png"
      iconAlt="receipt icon"
      (onClick)="download()"
      [isLoading]="receiptIsLoading$ | async">
      <span class="pt-4 block"
        >Download <br />
        receipt</span
      >
    </bey-rounded-btn>

    <bey-rounded-btn iconSrc="/assets/images/icons/link-black.png" iconAlt="receipt icon" (onClick)="share()">
      <span class="pt-4 block">Share</span>
    </bey-rounded-btn>
  </div>
</div>
