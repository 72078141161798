import { Component, OnInit, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from './ngrx/user/user.interfaces';
import { selectSavedUser, selectUserDetails } from './ngrx/user/user.selectors';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { routeTransitionAnimations } from '../assets/utility/route-transition-animations';
import { AutoResume, DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { logOut, setCurrentRoute, unlockAccount } from './ngrx/auth/auth.actions';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BeySessionIdleModalComponent } from './modules/core/components/bey-session-idle-modal/bey-session-idle-modal.component';
import { filter, tap, timer } from 'rxjs';
import dayjs from 'dayjs';
import {
  addMaximumScaleToMetaViewport,
  checkIsIOS,
  loadRecaptchaScript,
  logger,
  logNetworkInfo,
} from '../assets/utility';
import { identify, init, setUserVars } from '@fullstory/browser';
import { environment } from '../environments/environment';
import { toggleAnimatedSidebar, toggleHeadlessStepperStep } from './ngrx/misc/misc.actions';
import { selectUserKycDetails } from './ngrx/KYC/kyc.selectors';
import { selectLockedAccountTimeStamp } from './ngrx/auth/auth.selectors';
import { ServiceWorkerUtilService } from './modules/core/services/service-worker-util.service';
import { IntercomService } from './modules/core/services/intercom.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf } from '@angular/common';
import { RouterService } from './modules/core/services/router.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimations],
  standalone: true,
  imports: [NgIf, MatProgressBarModule, RouterOutlet],
})
export class AppComponent implements OnInit {
  countdown: number = null;
  fired: boolean = false;
  intercomVisitorId: string = null;
  intercomData: object;
  isLoading: boolean = false;

  constructor(
    private store$: Store,
    private idle: Idle,
    private router: Router,
    private dialog: MatDialog,
    private serviceWorkerUtil: ServiceWorkerUtilService,
    private intercomUtil: IntercomService,
    private routerService: RouterService,
    @Optional()
    public dialogRef: MatDialogRef<BeySessionIdleModalComponent>
  ) {
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        ),
        tap((event) => {
          if (event instanceof NavigationStart) {
            // Close drawer if open
            store$.dispatch(
              toggleAnimatedSidebar({
                contentProjection: false,
                isOpen: false,
                isOver: false,
              })
            );
            this.isLoading = true;
          } else {
            this.isLoading = false;
          }

          if (event instanceof NavigationEnd) {
            this.store$.dispatch(setCurrentRoute({ payload: event.url }));
          }
        })
      )
      .subscribe();

    // Initiate FullStory
    init({ orgId: '190ZMK', devMode: !environment.production });

    this.store$.select(selectUserDetails).subscribe((user: User) => {
      if (user && environment.name !== 'DEV') {
        // 4 min idle and 1 min warning to take action
        this.idle.setIdle(240); // how long can they be inactive before considered idle, in seconds
        this.idle.setTimeout(59); // how long can they be idle before considered timed out, in seconds
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active
        this.idle.setAutoResume(AutoResume.notIdle);
        this.reset();

        this.idle.onIdleStart.subscribe(() => {
          this.openSessionModal();
        });

        //  log the user out from the app
        this.idle.onTimeout.subscribe(() => {
          this.dialogRef.close(true);
        });

        // do something as the timeout countdown does its thing
        this.idle.onTimeoutWarning.subscribe((seconds) => {
          this.dialogRef.componentInstance.data = { timeLeft: seconds };
        });
      } else {
        this.idle.stop();
      }
    });

    // Load support scripts
    loadRecaptchaScript();
    logNetworkInfo();
  }

  ngOnInit(): void {
    this.serviceWorkerUtil.handleNetworkChange();

    this.routerService.routeData$.subscribe((d) => {
      document.body.style.backgroundColor = d?.['background'] || '#FFF';
    });

    if (checkIsIOS()) {
      addMaximumScaleToMetaViewport();
    }

    // Doing tasks that require scripts to run
    // Check if the user is a visitor or a registered user
    this.store$.select(selectSavedUser).subscribe((savedUser) => {
      logger(`User is saved:- ${savedUser ? 'yes' : 'no'}`);

      // if the user is a visitor then retrieve their intercom visitor id
      if (!savedUser) {
        logger("Retrieving guest's Intercom visitor ID");
        this.intercomUtil
          .getVisitorId()
          .then((visitorId) => {
            logger('Intercom visitor ID: ', visitorId);
            this.intercomVisitorId = visitorId;
            // then save it to intercom as another variable and also send it to full story
            logger('Identifying user on FullStory via intercom visitor ID');
            //Send intercom's visitor ID to full story
            identify(visitorId);
          })
          .catch((e) => {
            console.error(`${e.name}: ${e.message}`);
          });
      }
    });

    this.store$.select(selectUserDetails).subscribe((user: User) => {
      if (user) {
        logger('User logged in to the app');

        this.intercomData = {
          app_id: 'zoupvpas',
          user_hash: user.intercom_id,
          fullstory_id: user?.fullstory_id,
          email: user.email ? user.email : 'NO@EMAIL.COM',
          name: `${user.first_name} ${user.last_name}`,
          // Send the Beyonic ID to intercom to update the customer profile
          user_id: user.id, // this is the user ID and not profile or business
          created_at: user.created,
          email_status: user.email_status,
        };

        // When a guest becomes a customer and gets a unique Beyonic ID, send the Beyonic ID as variable
        const fsData = {
          fullName: `${user.first_name} ${user.last_name}`,
          beyonic_id: `${user.id}`,
        };

        // Since we've moved the display name out of the user service this have a high chance of being null on the new accounts, and we use this as a legacy implementation
        if (user.first_name) {
          fsData['displayName'] = `${user.first_name} ${user.last_name}`;
        }

        identify(user.fullstory_id, { ...fsData });

        this.bootIntercom();
      } else {
        this.intercomVisitorId = null;
        this.intercomData = {
          app_id: 'zoupvpas',
        };

        this.bootIntercom();
      }
    });

    // Set the display name and full name from the KYC as soon the data is available
    this.store$.select(selectUserKycDetails).subscribe((kycInfo) => {
      if (kycInfo) {
        setUserVars({
          fullName: `${kycInfo.user.first_name} ${kycInfo.user.last_name}`,
          displayName: `${kycInfo.user.first_name} ${kycInfo.user.last_name}`,
        });
      }
    });

    // check locked user's timestamp
    this.store$.select(selectLockedAccountTimeStamp).subscribe((lockedAccountTimeStamp) => {
      if (lockedAccountTimeStamp) {
        let timeDiff: number = dayjs(new Date()).diff(lockedAccountTimeStamp, 'minutes', true);

        // check if time difference more than 15 minutes then we unlock the user
        if (timeDiff >= 15) {
          this.store$.dispatch(unlockAccount());
        }
        // otherwise we create a timer to subscribe to it and unlock the user once timer finishes
        else {
          // we get the time left for user to get unlocked and we convert it to ms
          timer((15 - timeDiff) * 60 * 1000).subscribe(() => {
            this.store$.dispatch(unlockAccount());
          });
        }
      }
    });
  }

  openSessionModal() {
    if (!this.fired) {
      this.dialogRef = this.dialog.open(BeySessionIdleModalComponent, {
        data: {
          timeLeft: this.countdown,
        },
      });

      const subscription = this.dialogRef.afterClosed().subscribe((result) => {
        this.fired = false;
        if (result) {
          return this.endSession();
        }

        this.reset();
        subscription.unsubscribe();
      });
      this.fired = true;
    }
  }

  endSession() {
    this.idle.stop();
    this.store$.dispatch(logOut());
    // reset the KYC KYB stepper
    this.store$.dispatch(toggleHeadlessStepperStep({ currentStep: 0 }));
    this.router.navigate(['/login']);
    // clear intercom cookies and prepare for the next user
    this.intercomUtil.shutdown();
    this.bootIntercom();
  }

  reset() {
    this.idle.watch();
    this.countdown = null;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet?.activatedRouteData?.['animation'];
  }

  bootIntercom() {
    const data = {
      ...this.intercomData,
    };

    if (location.hostname === 'm.beyonic.com') {
      data['user_type'] = 'LIVE_USER';
    } else {
      data['user_type'] = 'TEST_USER';
    }

    logger('Booting Intercom.....');
    logger(`With the following data ${JSON.stringify(data)}`);

    this.intercomUtil.boot(data);
  }
}
